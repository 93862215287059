import React from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";

export default function RoadMap() {
  return (
    <>
      <Box className="roadmap_sec_main">
        <Box className="roadmap_sec_bg" />
        <Container className="container aaa">
          {/* <Box component="img" src="/static/img/roadmap_bottom_dot_img.png" alt="" className='roadmap_bottom_dot_img' /> */}
          <Grid container spacing={0} className="justify-content-center">
            <Grid item xs={12}>
              <Typography component="h2" className="roadmph2_ash">
                ROADMAP
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box className="roadmap_inn_box">
                {/* <video muted autoPlay loop className="roadmap_vdo" >
                                <source src="yellow_light_rays_1.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> */}
                <Grid container spacing={0} className="justify-content-center">
                  <Grid item xs={12} md={12}>
                    <Box className="rodmap_cntnt_prnt_left">
                      <Typography component="h3">Q1-Q2/2023</Typography>
                      <Box className="z_index_01">
                        <Box className="span_p_prnt">
                          <span>1</span>{" "}
                          <Typography>
                            Extend PIPX vaults, open up new vaults
                            with new partners.... to top 10 BSC projects (ongoing).
                          </Typography>
                        </Box>
                        <Box className="span_p_prnt">
                          <span className="text_cercle_bg02">2</span>{" "}
                          <Typography>
                            Launch Pi Metaverse, beta Testing! (ongoing).
                          </Typography>
                        </Box>
                        <Box className="span_p_prnt">
                          <span className="text_cercle_bg03">3</span>{" "}
                          <Typography>
                            PIPX Launch ETH / Avalanche / POLYGON (in development).
                          </Typography>
                        </Box>
                        <Box className="span_p_prnt">
                          <span className="text_cercle_bg04">4</span>{" "}
                          <Typography>
                            Token Launch on Avalanche. (in development).
                          </Typography>
                        </Box>
                        <Box className="span_p_prnt">
                          <span className="text_cercle_bg05">5</span>{" "}
                          <Typography>
                            Monsterfarm release (in development).
                          </Typography>
                        </Box>
                        <Box className="span_p_prnt">
                          <span>6</span>{" "}
                          <Typography>
                            PiCrush release (in development).
                          </Typography>
                        </Box>
                        <Box className="span_p_prnt">
                          <span className="text_cercle_bg07">7</span>{" "}
                          <Typography>
                          Metaverse Portal (in development).
                          </Typography>
                        </Box>
                        <Box className="span_p_prnt">
                          <span className="text_cercle_bg07">8</span>{" "}
                          <Typography>
                          Referal Program (done)
                          </Typography>
                        </Box>
                        <Box className="span_p_prnt">
                          <span className="text_cercle_bg07">9</span>{" "}
                          <Typography>
                          CEX Listing! (in preparation)
                          </Typography>
                        </Box>
                        <Box className="span_p_prnt">
                          <span className="text_cercle_bg07">10</span>{" "}
                          <Typography>
                          Multichain Bridge (in preparation).
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        component="img"
                        src="/images/roadmap_top.png"
                        alt=""
                        className="roadmap_top"
                      />
                      <Box className="roadmap_center_bg" />
                      <Box
                        component="img"
                        src="/images/roadmap_bottom.png"
                        alt=""
                        className="roadmap_bottom"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {/* <Box className="rodmap_cntnt_prnt">
                                        <Box component="img" src="/static/img/roadmap_pi_img.png" alt="" />
                                    </Box> */}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box className="roadmap_bottm_box">
          <Box
            component="img"
            src="/images/roadmap_divder.svg"
            alt=""
            className="roadmap_divder"
          />
          <Typography component="h5">
            <b>2023</b> Long term goal of becoming biggest DeFi NFT and Treasure Hunt Plattform.
          </Typography>
        </Box>
      </Box>
    </>
  );
}
