import React, { useState, useEffect } from "react";
import moment from "moment";
import BigNumber from "bignumber.js";
import { usePriceOf, useSupply } from "../redux/hooks";
import axios from "axios";

let maticPriceFeed = null;
let lastTimeSynced = new Date();

export const fetchMaticPrice_ETH = async () => {
  const nextSyncedTime = lastTimeSynced.getTime() + 3600000;
  if (maticPriceFeed === null || new Date().getTime() > nextSyncedTime) {
    console.log(
      "next fetch price feed time:",
      moment(nextSyncedTime).format("llll")
    );
    const response = await fetch(
      "https://min-api.cryptocompare.com/data/pricemultifull?fsyms=ETH&tsyms=USD"
    );
    maticPriceFeed = await response.json();
  }
  return maticPriceFeed;
};

export const fetchPIPrice = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-API-Key":
        "22b9NAwX2WvnowRxF5OmnQwQaDJpPSxxkTQh1Lvqvg8v6PqA8jIIt4v17WSzxH6Z",
    },
  };

  let res = await axios.get(
    //chain
    `https://deep-index.moralis.io/api/v2/erc20/0x37613d64258c0fe09d5e53eecb091da5b8fa8707/price?chain=eth`,
    config
  );
  const piPriceFeed = await res;
  return piPriceFeed;
};

export default function Statisticeth(props) {
  const [maticprice, setmaticprice] = useState(0);
  const [piprice, setPiPrice] = useState(0);
  const tokenaddress = "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9";
  const pcs2 = "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9";
  const price = usePriceOf(tokenaddress, pcs2);
  const supply = useSupply(tokenaddress, pcs2);

  useEffect(() => {
    const getMaticInfo = async () => {
      const {
        RAW: {
          ETH: {
            USD: { PRICE },
          },
        },
      } = await fetchMaticPrice_ETH();
      const data = {
        MaticPrice: PRICE,
      };
      const dmagicMilPrice = PRICE;
      data.dmagicMilPrice = dmagicMilPrice;
      setmaticprice(data.dmagicMilPrice);
    };
    getMaticInfo();
  }, [maticprice]);

  useEffect(() => {
    const getPiInfo = async () => {
      const {
        data: { usdPrice },
      } = await fetchPIPrice();
      const data = {
        MaticPrice: usdPrice,
      };
      const dmagicMilPrice = usdPrice;
      data.dmagicMilPrice = dmagicMilPrice;
      setPiPrice(data.dmagicMilPrice);
    };
    getPiInfo();
  }, [piprice]);

  const thi2s = (
    ((price[1] * maticprice + price[0] * piprice) / supply / piprice) *
    2
  ).toFixed(2);

  return (
    <>
      <span>ETH $ </span>
      {BigNumber(maticprice).toFixed(2)} {" - "}
      <span>PIP $ </span>
      {BigNumber(piprice).toFixed(2)}
      {" - "}
      <span>sCircle $ </span>
      {(thi2s * piprice).toFixed(2)}
      {" -  "}
      <span>eCircle $ </span>
      {(thi2s * piprice * 5).toFixed(2)}
    </>
  );
}
