const secondStyle = {
  opacity: "1",
  fontFamily: "Helvetica",
  fontSize: "14px",
  color: "#FFFFFF",
  letterSpacing: "0",
  lineHeight: "14px",
};

const zapCommandStyle = (theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  zapContainer: {
    width: "588px",
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: "24px",
    padding: "24px",
  },
  mainTitle: {
    fontFamily: "Helvetica",
    fontSize: "32px",
    letterSpacing: "0",
    lineHeight: "32px",
    fontWeight: "550",
  },
  secondTitle: {
    ...secondStyle,
    fontWeight: "550",
  },
  secondContent: {
    ...secondStyle,
  },
  boxContainer: {
    padding: "15px",
    borderRadius: "16px",
    marginBottom: "20px",
  },
  boxHeader: {
    display: "flex",
    justifyContent: "space-between",
    ...secondStyle,
  },
  boxHeaderMain: {
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: "550",
  },
  boxHeaderSub: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
    marginBottom: "6px",
    width: "100%",
    textAlign: "center",
  },
  boxContainerInside: {
    background: "#acee0900",
    borderRadius: "24px",
  },
  endAdornment: {
    display: "flex",
  },

  endAdornment2: {
    display: "flex",
  },
  memuStyle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "20px",
    padding: "0px 0px 0px 0px",
  },
  subMemuStyle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  papperNav: {
    justifyContent: "space-between !important",
    width: "50%",
    alignItems: "center",
  },
  marginRight: {
    marginRight: "14px",
  },
  receiveStyle: {
    justifyContent: "space-between !important",
    width: "50%",
    height: "56px",
    background: "rgba(0, 0, 0, 0.4)",
    border: "1px solid #acee09",
    borderRadius: "12px",
    fontWeight: "600",
    fontSize: "18px",
    color: "#FFFFFF",
    lineHeight: "24px",
    textAlign: "right",
    padding: "18.5px 18px",
    "&:hover,&:focus": {
      background: "rgba(216, 216, 216, 0.3)",
    },
    marginTop: theme.spacing(2),
  },
  navLink: {
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  avatar: {
    width: "50px",
    height: "50px",
    lineHeight: "48px",
  },
  avatarFont: {
    ...secondStyle,
    opacity: "1",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "16px",
  },
  maxButtonBox: {
    display: "flex",
    alignItems: "center",
  },
  maxButton: {
    ...secondStyle,
    opacity: "1",
    width: "48px",
    height: "24px",
    backgroundColor: "#acee0900",
    borderRadius: "24px",
    fontSize: "12px",
    lineHeight: "12px",
    padding: "12px 24px",
    marginRight: "8px",
    "&:hover,&:focus": {
      backgroundColor: "#acee09",
    },
  },
});

export default zapCommandStyle;
