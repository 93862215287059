/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { inputLimitPass, inputFinalVal, isEmpty } from "features/helpers/utils";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import { FormControl, IconButton } from "@material-ui/core";
import {
  useAllowance,
  useApprove,
  useDeposit,
  useWithdraw,
  useFetchGetReward,
  useTime1,
  useBalanceOf,
  useApprove1,
  useBoost1,
} from "../redux/hooks";
import Slider from "@material-ui/core/Slider";
import depositimg from "../../../assets/img/deposit.svg";
import stakepiplogo from "../../../assets/img/stakepiplogo.svg";
import exitsign from "../../../assets/img/exitsign.svg";
import truesign from "../../../assets/img/truesign.svg";
import Countdown from "react-countdown";

import Moment from "react-moment";
import "moment-timezone";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useConnectWallet } from "../../home/redux/hooks";
import { nft1 } from "features/configure/abi";

Moment.globalLocal = true;

const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(10000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(10000))
    .toNumber();
};

export default function PoolContent({ index, pool, toggle }) {
  const { web3, address } = useConnectWallet();
  const [Owner, setOwner] = useState("");
  const tokenBalance = useBalanceOf(pool.tokenAddress);
  const tokenBalance2 = useBalanceOf(pool.tokenAddress2);
  let balanceSingle = byDecimals(tokenBalance, pool.tokenDecimals);
  const depositedBalance = useBalanceOf(pool.earnContractAddress);
  let singleDepositedBalance = byDecimals(
    depositedBalance,
    pool.itokenDecimals
  );
  const allowance = useAllowance(pool.tokenAddress, pool.earnContractAddress);

  const { isPending: isApprovePending1, onApprove1 } = useApprove1(
    pool.tokenAddress2,
    pool.earnContractAddress
  );

  const { isPending: isApprovePending, onApprove } = useApprove(
    pool.tokenAddress,
    pool.earnContractAddress
  );
  const { onGetReward, isPending: isGetRewardPending } = useFetchGetReward(
    pool.earnContractAddress
  );
  const { onDeposit, isPending: isDepositPending } = useDeposit(
    pool.earnContractAddress,
    pool.tokenAddress
  );
  const { onWithdraw, isPending: isWithdarwPending } = useWithdraw(
    pool.earnContractAddress,
    pool.tokenAddress
  );
  const time1 = useTime1(pool.earnContractAddress);

  const { onBoost1, isPending: isBoost1Pending } = useBoost1(
    pool.earnContractAddress,
    pool.tokenAddress2
  );

  const [balanceToDeposit, setBalanceToDeposit] = useState("0");
  const [balanceToDepositSlider, setBalanceToDepositSlider] = useState(0);
  const [balanceToWithdraw, setBalanceToWithdraw] = useState("0");
  const [balanceToWithdrawSlider, setBalanceToWithdrawSlider] = useState(0);

  const fetchOwner = useCallback(async () => {
    const contract = new web3.eth.Contract(nft1, pool.tokenAddress2);
    const owner = await contract.methods
      .tokenOfOwnerByIndex(address, 0)
      .call({ from: address });
    setOwner(owner);
  }, [address, setOwner, pool.tokenAddress2, web3]);

  useEffect(() => {
    if (web3 && address && tokenBalance2 > 0) {
      fetchOwner();
      let refreshInterval = setInterval(fetchOwner, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchOwner, tokenBalance2]);

  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    let sliderNum = 0;
    let inputVal = Number(value.replace(",", ""));
    if (value) {
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }
    switch (type) {
      case "depositedBalance":
        setBalanceToDeposit(inputFinalVal(value, total, tokenDecimals));
        setBalanceToDepositSlider(sliderNum);
        break;
      case "withdrawAmount":
        setBalanceToWithdraw(inputFinalVal(value, total, tokenDecimals));
        setBalanceToWithdrawSlider(sliderNum);
      default:
        break;
    }
  };

  const handleDepositedBalance = (
    index,
    total,
    tokenDecimals,
    event,
    sliderNum
  ) => {
    const val =
      sliderNum == 0
        ? "0"
        : new BigNumber(total).times(sliderNum).div(100).toFixed(0);
    setBalanceToDeposit(byDecimals(val, tokenDecimals));
    setBalanceToDepositSlider(sliderNum);
  };
  const handleWithdrawAmount = (
    index,
    total,
    tokenDecimals,
    event,
    sliderNum
  ) => {
    const val =
      sliderNum == 0
        ? "0"
        : new BigNumber(total).times(sliderNum).div(100).toFixed(0);
    setBalanceToWithdraw(byDecimals(val, tokenDecimals));
    setBalanceToWithdrawSlider(sliderNum);
  };

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function valuetext(value) {
    return `${value}%`;
  }
  ///withdraw slider
  const withdraw = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function withdrawvaluetext(value) {
    return `${value}%`;
  }
  ///Aprove Modal
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleTest = () => {
    alert("jijij");
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className="head_prnt"
        spacing={5}
      >
        <Grid item xs={12} lg={6} className="fr_brdr_right">
          <Grid item xs={12} lg={12} className="cstmaccrdn2 cstmaccrdn2_ash_01">
            <div className="d-flex input_bx">
              <FormControl>
                <input
                  className="input_fld"
                  value={balanceToDeposit}
                  onChange={changeDetailInputValue.bind(
                    this,
                    "depositedBalance",
                    index,
                    balanceSingle,
                    pool.tokenDecimals
                  )}
                />
              </FormControl>
            </div>

            <div className="progress_bx">
              <Slider
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={marks}
                value={balanceToDepositSlider}
                onChange={handleDepositedBalance.bind(
                  this,
                  index,
                  tokenBalance,
                  pool.tokenDecimals
                )}
              />
            </div>
          </Grid>

          <div className="progress_action accordan_inn_btn_prnt text-right mt-3 accordan_inn_btn_prnt_flx">
            {allowance === "0" ? (
              <Box className="btn_outrborder_sdw">
                <button
                  type="button"
                  className="btn btn_def_v3"
                  onClick={() => {
                    handleClose();
                    onApprove();
                  }}
                  disabled={isApprovePending[index]}
                >
                  <span>
                    <img
                      className="mr-2 fr_def"
                      width="25"
                      height="auto"
                      background-color="#000000"
                      src={stakepiplogo}
                      alt="logo"
                    />
                    {isApprovePending ? `${"Approve..."}` : `${"Approve"}`}
                  </span>
                </button>
              </Box>
            ) : (
              <Box className="btn_outrborder_sdw">
                <button
                  type="button"
                  className="btn btn_def_v3"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={isDepositPending}
                  onClick={(event) => {
                    handleClickOpen();
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {"Deposit"}
                </button>
              </Box>
            )}{" "}
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid item xs={12} sm={12} className="cstmaccrdn2 cstmaccrdn2_ash_01">
            <div className="d-flex input_bx">
              <FormControl fullWidth variant="outlined">
                <input
                  className="input_fld"
                  type="text"
                  value={balanceToWithdraw}
                  onChange={changeDetailInputValue.bind(
                    this,
                    "withdrawAmount",
                    index,
                    singleDepositedBalance,
                    pool.tokenDecimals
                  )}
                />
              </FormControl>
            </div>
            <div className="progress_bx progress_bx_rd">
              <Slider
                value={balanceToWithdrawSlider}
                onChange={handleWithdrawAmount.bind(
                  this,
                  index,
                  depositedBalance,
                  pool.tokenDecimals
                )}
                getAriaValueText={withdrawvaluetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={withdraw}
              />
            </div>
          </Grid>
          <div className="progress_action accordan_inn_btn_prnt text-right mt-3 accordan_inn_btn_prnt_flx">
            <Box className="btn_outrborder_sdw">
              <button
                type="button"
                className="btn btn_def_v3 btn_def_v3_v2"
                disabled={isWithdarwPending}
                onClick={() =>
                  onWithdraw(
                    balanceToWithdrawSlider >= 100
                      ? depositedBalance
                      : new BigNumber(balanceToWithdraw)
                          .multipliedBy(
                            new BigNumber(10).exponentiatedBy(
                              pool.tokenDecimals
                            )
                          )
                          .toString(10)
                  )
                }
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={exitsign}
                  alt="logo"
                />
                <span>
                  {isWithdarwPending ? `${"Withdrawing"}` : `${"UNSTAKE PIP"}`}
                </span>
              </button>
            </Box>{" "}
            <Box className="btn_outrborder_sdw">
              <button
                type="button"
                className="btn btn_def_v3 btn_def_v3_v2"
                onClick={() => onGetReward()}
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={truesign}
                  alt="logo"
                />
                <span>
                  {isGetRewardPending ? `${"Claiming"}` : `${"Claim"}`}
                </span>
              </button>
            </Box>
          </div>
        </Grid>

        {pool.boost === "1" ? (
          <>
            <Grid
              item
              lg={6}
              className="cstm_txt_lft oth_sc pb-0 fr_brdr_right"
            >
              <Box className="bst_pl_cntnt_vdo_prnt">
                <Box className="bst_pl_cntnt_inn">
                  <div className="bst_pl_cntnt">
                    {/*                     <button className="boostguid_btn" onClick={toggle}>
                      <Box
                        component="img"
                        src="/static/img/boost_icon.svg"
                        alt=""
                      />
                      boost guide
                    </button> */}
                    <Typography component="h5">
                      Boost your Pool with bitCircle NFT:
                    </Typography>
                    {/* <p>The boosted NFT will be spent into the PI Ecosystem!</p> */}
                    <Typography component="p">
                      The boosted NFT will be permanently spent back into the Pi
                      Ecosystem!
                    </Typography>
                  </div>
                  <Box className="accordan_inn_btn_prnt btn_min_w_ash">
                    <Box className="btn_outrborder_sdw">
                      <button
                        type="button"
                        className="btn btn_def_v3 mb_15 btn_def_v3_v2"
                        onClick={() => {
                          onApprove1(Owner);
                        }}
                      >
                        <span>Approve bitCircle</span>
                      </button>
                    </Box>
                    <Box className="btn_outrborder_sdw">
                      {" "}
                      <button
                        type="button"
                        className="btn btn_def_v3 mb_15 btn_def_v3_v2"
                        onClick={() => {
                          onBoost1(Owner);
                        }}
                      >
                        {" "}
                        <span>Boost 30%</span>
                      </button>
                    </Box>
                  </Box>
                </Box>
                <Box className="vdo_bst_crcle_prnt">
                  <video autoPlay={true} loop muted playsinline>
                    <source
                      src="/static/img/boost_page_bitCircle_sm_1.mp4"
                      type="video/mp4"
                    />
                  </video>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              lg={6}
              className="cstm_txt_lft oth_sc accordan_inn_btn_prnt text-center pb-0"
            ></Grid>
          </>
        ) : (
          <> </>
        )}
      </Grid>
      <div className="othrinfo_as othrinfo_as_v3">
        <Typography component="p">
          Duration:{" "}
          <span>
            {pool.startdate} - {pool.duration}
          </span>
        </Typography>
        <Typography component="p">
          Timelock Remaining:{" "}
          <span>{time1 && <Countdown date={time1 * 1000} />}</span>{" "}
        </Typography>

        <Typography component="p">
          Penalty fee:{" "}
          <span>
            {pool.penalty} within {pool.timelock} days
          </span>
        </Typography>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="aprvmdl"
      >
        <DialogTitle id="alert-dialog-title">{"Note"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <li> Depositing will reset the Timelock Countdown. </li>
            <li>
              Withdrawing before Timelock expiration incurs a {pool.penalty}{" "}
              fee.
            </li>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="progress_action d-flex w-100 mt-3 justify-content-center">
            {allowance !== "0" && (
              <div className="progress_action text-right mt-3">
                <button
                  className="btn btn_def"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={isDepositPending}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClose();
                    if (!isEmpty(pool.depostAlert)) {
                      setWaitDialogConfirmJson({
                        content: pool.depostAlert,
                        func: () => {
                          onDeposit(pool, index, false, balanceSingle, event);
                        },
                      });
                      setOpenDialog(true);
                    } else {
                      onDeposit(
                        balanceToDepositSlider >= 100
                          ? tokenBalance
                          : new BigNumber(balanceToDeposit)
                              .multipliedBy(
                                new BigNumber(10).exponentiatedBy(
                                  pool.tokenDecimals
                                )
                              )
                              .toString(10)
                      );
                    }
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {"Deposit"}
                </button>
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog>
      {/* /// */}
    </>
  );
}
