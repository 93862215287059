import React from "react";
import { Grid, Box } from "@material-ui/core";
import PoolsTitle from "./sections/PoolsTitle";
import SectionPoolsV2 from "./sections/SectionPoolsV2";
import { useConnectWallet } from "../home/redux/hooks";
import PoolsTopDetails2 from "./sections/PoolsTopDetails2";
import PoolsTopDetails2OG from "./sections/PoolsTopDetails2OG";

export default function VaultPage() {
  const { networkId, address } = useConnectWallet();

  const wallets = [
    "0xa69f71f6b5895a30a86c4560a5116e2b5f5e4cec",
    "0x37fbd606db9e2d9572c64b8788f613e41a6a51f1",
    "0x7a317097cdbc332389ba0e0b54005a3491c59770",
    "0xdc618aca8c79852aa5c044e52f6ccac7d5754b4a",
    "0x0d068b3e054569a74ef486ec4e49b26a1ed8826b",
    "0xfb1dc00c9edb778829990bbd0aa149976dad4eb2",
    "0x7debf419f9bc943fedae6e4382d21cddd4f1f79c",
    "0x835d0db177efc93faaa1fb4fc7c718c47510b0bd",
    "0xc24ad065b5df2fc19918b1f84ad8e00970ef3d24",
    "0xfb4ab28c5108d5a0d379ff2aa96699037eb66916",
    "0x9d93dead8b04bc945731436013ff5054c3108c34",
    "0xd95624f5030ca4d7e14d4ebac92ddedd04f5ff53",
    "0xa9e069c660672dbc812555c8ec6187f625360753",
    "0x9039138329c2bcf1cb27eff621cb04d9b3ff6ca3",
    "0x71389c60d655240f130dbf2ca4f9bf565ccd162b",
    "0x5d0398b59bd272a7555b047cffbdb35afc570f1a",
    "0x110e16570dfd3300770debc4632214e3d333be8b",
    "0xc9aaac8af688bdae43c172f87d9852e8492fe073",
    "0x423f7e311867ffa7faa03a1d8f8801642f11a57d",
    "0x49fe8069d58e2e8f31ba384e3a5766686f746f39",
    "0xd9870a9677778db6da1da141d9faf3cc8c1dbe15",
    "0x6f069335b368e59be52c312727b7d961aee6372d",
    "0x2a7b83b408a5927bc48ad849ff4f9bb2aadfa209",
    "0x97a88d526232d228f15621b3bacce9c56137d789",
    "0x14891cd5d3aa2d858b1daeb1edeeab605eebcfcb",
  ];

  return (
    <>
      <Box className="nft_prnt footer_spaceing stkng_main_bx">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <PoolsTitle />
          </Grid>
          {networkId === 1 ? (
            <Grid item xs={12}>
              <PoolsTopDetails2 />
            </Grid>
          ) : (
            <></>
          )}

          {networkId === 1 && wallets.includes(address.toLowerCase()) ? (
            <Grid item xs={12}>
              <PoolsTopDetails2OG />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12} className="mb-4">
            <Box className="cstmcntnr" key={1}>
              <SectionPoolsV2 key={2} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
