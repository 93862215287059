import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {Box, Grid} from '@material-ui/core';
import sectionPoolsStyle from '../jss/sections/sectionPoolsStyle';
import PoolHeader from './PoolHeader';
import PoolContent from './PoolContent';
import { getVaults } from "../redux/hooks";


const useStyles = makeStyles(sectionPoolsStyle);

export default function SectionPoolsV2() {

  const [openedCardList, setOpenCardList] = useState([]);
  const classes = useStyles();
  const [vaults, setVaults] = useState([]);

  const openCard = (id) => {
    return setOpenCardList((openedCardList) => {
      if (openedCardList.includes(id)) {
        return openedCardList.filter((item) => item !== id);
      } else {
        return [...openedCardList, id];
      }
    });
  };

  const fetchVaults = async () => {
    let res = await getVaults();
    setVaults(res.data);
  };

  useEffect(() => {
    fetchVaults();
  }, []);


  return (
    <Grid item xs={12} className='mt-3 col-12'>
      {vaults
        //.filter((pool) => pool.status === 'aktive' && pool.chainId === networkId)
        .map((pool, index) => (
          <Box className="mn_a_box">
              <img src="/images/tab_top.png" alt= "" className='tab_tp_mi' />
                <Accordion
                  className='cstmaccrdn cstmaccrdn_ash_a'
                  expanded={Boolean(openedCardList.includes(index))}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    onClick={(event) => {
                      event.stopPropagation();
                      openCard(index);
                    }}
                  >
                    
                    <PoolHeader
                      index={index}
                      pool={pool}
                      classes={classes}
                      openedCardList={openedCardList}
                      openCard={openCard}
                    />
                  </AccordionSummary>

                  <AccordionDetails>
                    <PoolContent index={index} pool={pool} classes={classes} />
                  </AccordionDetails>
                </Accordion>
                <Box className='tbmddl_sc' />
              <img src="/images/tab_bottom.png" alt="" className='tab_btm_mi' />
          </Box>
          
        ))}

    </Grid>
  );
}
