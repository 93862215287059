import React, { useState, useEffect, useCallback, useRef } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
// import BigNumber from "bignumber.js";
import {
  useApprove,
  useBalanceOf,
  useDeposit,
  useWithdraw,
  useFetchGetReward,
  useDeposit2
} from "../redux/hooks";
import truesign from "../../../assets/img/truesign.svg";
import { fetchTokens } from "../redux/hooks";

import Moment from "react-moment";
import { useConnectWallet } from "../../home/redux/hooks";
import { nft1 } from "features/configure/abi";
import axios from "axios";

Moment.globalLocal = true;

export default function PoolContent({ index, pool }) {
  const { web3, address } = useConnectWallet();
  const [Owner, setOwner] = useState([]);
  const tokenBalance = useBalanceOf(pool.tokenAddress);
  // eslint-disable-next-line no-unused-vars
  const [fetching, setFetching] = useState(false);
  const [fetchingNFT, setFetchingNFT] = useState(true);
  const [fetchedIDs, setFetchedIDs] = useState(true);


  const fetchOwner = useCallback(async () => {
    const contract = new web3.eth.Contract(nft1, pool.tokenAddress);
    const owner = await contract.methods
      .tokenOfOwnerByIndex(address, 0)
      .call({ from: address });
    setOwner(+owner);
  }, [address, setOwner, pool.tokenAddress, web3]);

  const { isPending: isApprovePending, onApprove } = useApprove(
    pool.tokenAddress,
    pool.earnContractAddress
  );

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    pool.earnContractAddress,
    pool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    pool.earnContractAddress,
    pool.tokenAddress
  );


  const { onWithdraw, isPending: isWithdarwPending } = useWithdraw(
    pool.earnContractAddress,
    pool.tokenAddress
  );
  const { onGetReward, isPending: isGetRewardPending } = useFetchGetReward(
    pool.earnContractAddress
  );

  useEffect(() => {
    if (web3 && address && tokenBalance > 0) {
      fetchOwner();
      let refreshInterval = setInterval(fetchOwner, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchOwner, tokenBalance]);

  const fetchNFTs = async (address) => {
    const cancelTokenSource = axios.CancelToken.source();
    setFetching(true);
    try {
      const { data } = await fetchTokens(
        0,
        5000,
        "single",
        [pool.tokenAddress],
        null,
        "id",
        [],
        address,
        cancelTokenSource.token
      );

      tokens.current = [...tokens.current, ...data.tokens];

      setFetching(false);
    } catch {
      setFetching(false);
    }
  };

  const tokens = useRef([]);

  const filtered = tokens.current.filter(
    (t) => t.owner === address.toLowerCase()
  );

  const filtered3 = [...filtered.map(({ tokenID }) => tokenID)];

  useEffect(() => {
    if (web3 && address && fetchingNFT === true) {
      fetchNFTs(address);
      setFetchingNFT(false);
    }
    if (web3 && address && filtered3 > [] && fetchedIDs === true) {
      fetchOwner();
      setFetchedIDs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, address, fetchingNFT, filtered3, fetchedIDs]);

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className="head_prnt"
        spacing={5}
      >
        <Grid item lg={12}>
          <div className="progress_action text-right accordan_inn_btn_prnt">
            <>
              <Box className="btn_outrborder_sdw">
                <button
                  className="btn btn_def_v3 btn_def_v3_v2"
                  onClick={() => {
                    onApprove(Owner);
                  }}
                  disabled={isApprovePending[index]}
                >
                  <span>
                    {isApprovePending
                      ? `${"Approving..."}`
                      : `1. Approve  All${pool.tokenDescription}`}
                  </span>
                </button>
              </Box>{" "}
            </>
            { pool.id !== "10" ? (
            <Box className="btn_outrborder_sdw">
              <button
                className="btn btn_def_v3 btn_def_v3_v2"
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending}
                onClick={(event) => {
                  onDeposit(Owner);
                }}
              >
                {" "}
                <span>{"Stake"}</span>
              </button>
            </Box>
            ) : (
              <>
              <Box className="btn_outrborder_sdw">
              <button
                className="btn btn_def_v3 btn_def_v3_v2"
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending}
                onClick={(event) => {
                  onDeposit(Owner);
                }}
              >
                {" "}
                <span>{"Stake one"}</span>
              </button>
            </Box>
              <Box className="btn_outrborder_sdw">
              <button
                className="btn btn_def_v3 btn_def_v3_v2"
                onFocus={(event) => event.stopPropagation()}
                disabled={
                    isDepositPending2 || filtered3.length === 0
                  }
                onClick={(event) => {
                  onDeposit2(filtered3);
                }}
              >
                {" "}
                <span>{"Stake All"}</span>
              </button>
            </Box>
            </>
             ) }
             { pool.id === "10" && (
              <>
             <br></br>
             <br></br>
             </>
             )}
            <Box className="btn_outrborder_sdw">
              <button
                className="btn btn_def_v3 btn_def_v3_v2"
                disabled={isWithdarwPending}
                onClick={() => onWithdraw()}
              >
                {" "}
                <span>
                  {" "}
                  {isWithdarwPending ? `${"UNSTAKING"}` : `${"UNSTAKE"}`}
                </span>
              </button>
            </Box>
            
            <Box className="btn_outrborder_sdw">
              <button
                className="btn btn_def_v3 btn_def_v3_v2"
                onClick={() => onGetReward()}
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={truesign}
                  alt="logo"
                />
                <span>
                  {isGetRewardPending ? `${"Claiming"}` : `${"Claim"}`}
                </span>
              </button>
            </Box>{" "}
          </div>
        </Grid>
        <div className="othrinfo_as othrinfo_as_v3">
          <Typography component="p">
            Duration: <span>{pool.duration}</span>
          </Typography>
          <Typography component="p">
            Startdate: <span>{pool.startdate}</span>{" "}
          </Typography>
          <Typography component="p">
            Pool fee: <span>{pool.penalty}</span>
          </Typography>
          <Typography component="p">
            Timelock: <span>NONE!</span>
          </Typography>
        </div>
      </Grid>
    </>
  );
}
