import React from "react";
import { Container, Box, Typography } from "@material-ui/core";
import logo from "assets/img/logo.png";

function Footer() {
  return (
    <Box component="footer" className="footer footer_v3 d_none_landing">
      <Box
        component="img"
        src="/images/foooter_dvider.png"
        className="img-fluid foooter_dvider"
      />
      <Container className="container_v3_footer">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            minHeight: "152px",
          }}
          className="ftrprnt"
        >
          <Box className="ftrlg">
            <img src={logo} alt="" />
          </Box>
          <Box className="ftrlink_prnt_v3">
            <a
              href="https://www.pi-protocol.io/gfx/Pi_Deck.pdf"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Pi Pitch DECK
            </a>
            <a
              href="https://www.pi-protocol.io/gfx/PI_AUDIT.pdf"
              target="_blank"
              rel="nofollow noopener noreferrer"
              className="center_link"
            >
              PROTOCOL AUDIT
            </a>
            <a
              href="https://bscscan.com/token/0x25c30340e6f9f6e521827cf03282943da00c0ece"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              PIP TOKEN CONTRACT
            </a>
          </Box>
          <Box className="pi_prtcl_text_img_prnt">
            <Box
              component="img"
              src="/images/pi_prtcl_text_img.png"
              className="pi_prtcl_text_img"
            />
            <Box className="gradiant_line" />
          </Box>
          <Typography className="ftr_copyright_text">
            Pi Protocol. All Rights Reserved 2023 (c)
          </Typography>
          {/* <Box
            style={{
              display: "flex",
              marginLeft: "auto",
              alignItems: "center",
            }}
            className="ftr_box"
          >
            <div className="scl_lnks">
              <Box className="grdnt_bx">
                <a
                  className="nav-link2"
                  tag="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://twitter.com/PiProtocol"
                >
                  <img
                    width="30"
                    height="auto"
                    background-color="#000000"
                    alt="..."
                    component="img"
                    src={require("assets/icons/twitter_svg.svg")}
                  />
                </a>
              </Box>
            </div>

            <div className="scl_lnks">
              <Box className="grdnt_bx">
                <a
                  className="nav-link2"
                  tag="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://discord.gg/jSP329kDaj"
                >
                  <img
                    width="30"
                    height="auto"
                    background-color="#000000"
                    alt="..."
                    component="img"
                    src={require("assets/icons/discord_svg.svg")}
                  />
                </a>
              </Box>
            </div>

            <div className="scl_lnks">
              <Box className="grdnt_bx">
                <a
                  className="nav-link2"
                  tag="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://t.me/PIProtocol"
                >
                  <img
                    width="30"
                    height="auto"
                    background-color="#000000"
                    alt="..."
                    component="img"
                    src={require("assets/icons/telegram_svg.svg")}
                  />
                </a>
              </Box>
            </div>

            <div className="scl_lnks">
              <Box className="grdnt_bx">
                <a
                  className="nav-link2"
                  tag="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://pi-protocol.medium.com/"
                >
                  <img
                    width="30"
                    height="auto"
                    background-color="#000000"
                    alt="..."
                    component="img"
                    src={require("assets/icons/medium_svg.svg")}
                  />
                </a>
              </Box>
            </div>

            <div className="scl_lnks">
              <Box className="grdnt_bx">
                <a
                  className="nav-link2"
                  tag="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://github.com/orgs/Pi-Protocol"
                >
                  <img
                    width="30"
                    height="auto"
                    background-color="#000000"
                    alt="..."
                    component="img"
                    src={require("assets/icons/github_svg.svg")}
                  />
                </a>
              </Box>
            </div>
          </Box> */}
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
