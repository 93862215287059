import React from 'react';
import {
  Box,
  Text,
  Image,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from './RadialSeparators';
import Countdown from 'react-countdown';
import CountdownTitle from './CountdownTitle';
export default function ThirdSec() {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <SimpleGrid columns={12} columnGap={2} rowGap={2}>
        <GridItem colSpan={[6, 6, 3, 3]} className="resv_cnd_bx767">
          <Box className='porgress_heght_wdth_bx'>
            <CircularProgressbarWithChildren
              value={days}
              text={days}
              maxValue={365}
              strokeWidth={5}
              styles={buildStyles({
                strokeLinecap: 'butt',
              })}
            >
              <RadialSeparators
                count={60}
                style={{
                  background: '#120916',
                  width: '2px',
                  // This needs to be equal to props.strokeWidth
                  height: `${5}%`,
                }}
              />
              <Text>Days</Text>
            </CircularProgressbarWithChildren>
          </Box>
        </GridItem>
        <GridItem colSpan={[6, 6, 3, 3]} className="resv_cnd_bx767">
          <Box className='porgress_heght_wdth_bx'>
            <CircularProgressbarWithChildren
              value={hours}
              text={hours}
              maxValue={24}
              strokeWidth={5}
              styles={buildStyles({
                strokeLinecap: 'butt',
              })}
            >
              <RadialSeparators
                count={24}
                style={{
                  background: '#120916',
                  width: '2px',
                  // This needs to be equal to props.strokeWidth
                  height: `${5}%`,
                }}
              />
              <Text>HOURS</Text>
            </CircularProgressbarWithChildren>
          </Box>
        </GridItem>
        <GridItem colSpan={[6, 6, 3, 3]} className="resv_cnd_bx767">
          <Box className='porgress_heght_wdth_bx'>
            <CircularProgressbarWithChildren
              value={minutes}
              text={minutes}
              maxValue={60}
              strokeWidth={5}
              styles={buildStyles({
                strokeLinecap: 'butt',
              })}
            >
              <RadialSeparators
                count={60}
                style={{
                  background: '#120916',
                  width: '2px',
                  // This needs to be equal to props.strokeWidth
                  height: `${5}%`,
                }}
              />
              <Text>MINUTES</Text>
            </CircularProgressbarWithChildren>
          </Box>
        </GridItem>
        <GridItem colSpan={[6, 6, 3, 3]} className="resv_cnd_bx767">
          <Box className='porgress_heght_wdth_bx'>
            <CircularProgressbarWithChildren
              value={seconds}
              maxValue={60}
              text={seconds}
              strokeWidth={5}
              styles={buildStyles({
                strokeLinecap: 'butt',
              })}
            >
              <RadialSeparators
                count={60}
                style={{
                  background: '#120916',
                  width: '2px',
                  // This needs to be equal to props.strokeWidth
                  height: `${5}%`,
                }}
              />
              <Text>SECONDS</Text>
            </CircularProgressbarWithChildren>
          </Box>
        </GridItem>
      </SimpleGrid>
    );
  };
  return (
    <>
    <Box className='countdown_main'>
      <Image src='/images/countdown_num_bg.png' className='countdown_num_bg' />
      <CountdownTitle />
      <Box className="cstmcntnr_ash_v3">
        <Box className='prrgess_brdr_box'>
          <Image src='/images/countdown_top.png' className='countdown_top' />
          <Box className='cntdown_center_bg' />
          <Image src='/images/countdown_btm.png' className='countdown_btm' />
          <Box className='progress_row'>
            <Countdown date={1657299600000} renderer={renderer} />
          </Box>
        </Box>
      </Box >
    </Box>
    </>
  );
}
