import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, IconButton } from "@material-ui/core";
import PoolsTitle from "./sections/PoolsTitle";
import PoolsTopDetails from "./sections/PoolsTopDetails";
import SectionPoolsV2 from "./sections/SectionPoolsV2";
import DialogContent from "@material-ui/core/DialogContent";
import { useConnectWallet } from "../home/redux/hooks";
//import Cs from "./sections/Cs";

export default function VaultPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  ////
  const [isActive, setActive] = useState(false);

  const toggleClassTwo = () => {
    console.log("hhh");
    setActive(!isActive);
  };

  const { networkId } = useConnectWallet();

  return (
    <>
      <PoolsTopDetails />
      <Box className="nft_prnt footer_spaceing ppstkng_main_bx">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <PoolsTitle />
          </Grid>

          <Grid item xs={12}>
            <Box className="cstmcntnr">
              {networkId === 56 ? <SectionPoolsV2 /> : <></>}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="cstmcntnr">{networkId === 1 ? <SectionPoolsV2 /> : <></>}</Box>
          </Grid>
        </Grid>
        <Box
          className={
            isActive
              ? "user_modal user_modal200 boosting_modal show"
              : "user_modal user_modal200 boosting_modal"
          }
        >
          <Box
            component="img"
            src="/img/top_guid.png"
            className="guid_top_img"
          />
          <DialogContent>
            <Box className="ur_mdl_ttl">
              <Box className="ur_mdl_ttl_inn">
                <Typography component="h4">
                  boosting with bitcircle nft
                </Typography>
              </Box>

              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={toggleClassTwo}
              >
                <Box component="img" src="/img/back_icon.svg" />
              </IconButton>
            </Box>
            <Box className="user_m_cntent">
              <Typography>
                1) Connect your wallet
              </Typography>
              <Typography>2) Choose a pool for PIP staking.</Typography>
              <Typography>
                3) Approve boost with the pool (press{" "}
                <span className="clr_yellow">Approve</span> button).
              </Typography>
              <Typography>
                4) Confirm approval in wallet (wait for confirmation).
              </Typography>
              <Typography>
                5) Boost staking rewards by 30% (press{" "}
                <span className="clr_yellow">Boost</span> button).
              </Typography>
              <Typography>
                6) Confirm boost in wallet (wait for confirmation).
              </Typography>
              <Typography>
                7) Earn boosted <span className="clr_green">rewards</span>.{" "}
              </Typography>
              <Box className="modal_x_info">
                <Typography>
                  <span className="clr_red">please note:</span> Boosting with
                  bitCircle NFT will permenantly spend your bitCircle NFT back
                  into the Pi Ecosystem. It will be recycled and made available
                  for sale in the Pi Marketplace.
                </Typography>
                <Typography>
                  <span className="clr_yellow">
                    You are trading your bitCircle NFT for 30% Pool Boosting
                  </span>
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <Box className="pop_mddl_bg" />
          <Box
            component="img"
            src="/img/popup_botm_img.svg"
            className="w-100 pb-1 ppbtm_img"
          />
          <Box
            component="img"
            src="/img/bottom_guid.png"
            className="guid_bottom_img"
          />
        </Box>
        {/* <CoverImgStyle alt='cover' src={'/static/img/pools_bg.jpg'} /> */}
      </Box>
    </>
  );
}
