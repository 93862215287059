import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { inputLimitPass, inputFinalVal } from "features/helpers/utils";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import FormControl from "@material-ui/core/FormControl";

import {
  useApprove,
  useDeposit,
  useWithdraw,
  useBalanceOf,
  useBalanceOfVault,
  useMaxNFT,
  useFetchNFTs,
  useApprove2,
  useAllowance,
} from "../redux/hooks";
import Slider from "@material-ui/core/Slider";
import exitsign from "../../../assets/img/exitsign.svg";

import Moment from "react-moment";
import "moment-timezone";
import depositimg from "../../../assets/img/truesign.svg";
import { Alert } from "@material-ui/lab";
Moment.globalLocal = true;

export default function PoolContent({ index, pool }) {
  const IStaking = "0x23620179Cf01e40478E8576210c72aF8b639d5D3";
  //const UStaking = "0xaD71679486f2Ec9F012B7B2F131FE5300302b3AA";

  const tokenBalance = useBalanceOf(pool.rewardToken);
  const depositedBalance = useBalanceOfVault(pool.vaultId);
  const [quant, setQuant] = useState(0);

  const NFTBalance = useBalanceOf(pool.erc721Address);
  const xTokenBalance = useBalanceOf(pool.rewardToken);

  const { isPending: isApprovePending, onApprove } = useApprove(
    pool.rewardToken
  );

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  const allowance = useAllowance(pool.rewardToken, IStaking);

    const { isPending: isApprovePending2, onApprove2 } = useApprove2(
    pool.xToken
  );

  const maxnft = useMaxNFT(pool.vaultId, pool.lpaddress);
  const { onGetNFTs, isPending: isGetNFTsPending } = useFetchNFTs(pool.vaultId);

  const { onDeposit, isPending: isDepositPending } = useDeposit(pool.vaultId);
  const { onWithdraw, isPending: isWithdarwPending } = useWithdraw(
    pool.vaultId
  );
  const [balanceToDeposit, setBalanceToDeposit] = useState("0");
  const [balanceToDepositSlider, setBalanceToDepositSlider] = useState(0);
  const [balanceToWithdraw, setBalanceToWithdraw] = useState("0");
  const [balanceToWithdrawSlider, setBalanceToWithdrawSlider] = useState(0);
  const [claiming, setClaiming] = useState(false);
  const [submit, setSubmit] = useState("100000000000000");
  const [finaldept, setFinalDept] = useState();

  function toggle(value) {
    return !value;
  }

  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    let sliderNum = 0;
    let inputVal = Number(value.replace(",", ""));
    if (value) {
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }
    switch (type) {
      case "depositedBalance":
        setBalanceToDeposit(inputFinalVal(value, total, tokenDecimals));
        setBalanceToDepositSlider(sliderNum);
        break;
      case "withdrawAmount":
        setBalanceToWithdraw(inputFinalVal(value, total, tokenDecimals));
        setBalanceToWithdrawSlider(sliderNum);
        break;
      default:
        break;
    }
  };

  const handleDepositedBalance = (
    index,
    total,
    tokenDecimals,
    event,
    sliderNum
  ) => {
    const val =
      sliderNum === 0
        ? "0"
        : new BigNumber(total).times(sliderNum).div(100).toFixed(0);
    setBalanceToDeposit(byDecimals(val, tokenDecimals));
    setBalanceToDepositSlider(sliderNum);
  };
  const handleWithdrawAmount = (
    index,
    total,
    tokenDecimals,
    event,
    sliderNum
  ) => {
    const val =
      sliderNum === 0
        ? "0"
        : new BigNumber(total).times(sliderNum).div(100).toFixed(0);
    setBalanceToWithdraw(byDecimals(val, tokenDecimals));
    setBalanceToWithdrawSlider(sliderNum);
  };

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function valuetext(value) {
    return `${value}%`;
  }
  ///withdraw slider
  const withdraw = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function withdrawvaluetext(value) {
    return `${value}%`;
  }

  useEffect(() => {
    const finaldeptx =
      depositedBalance -
      new BigNumber(quant)
        .multipliedBy(new BigNumber(10).exponentiatedBy(18))
        .toString(10);

    setFinalDept(finaldeptx);

    if (claiming === true) {
      setSubmit("1000000000000000000");
    } else {
      setSubmit("0");
    }
  }, [claiming, depositedBalance, finaldept, quant]);


  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className="head_prnt"
        spacing={5}
      >
        <Grid item xs={12} lg={6} className="fr_brdr_right">
          <Grid item xs={12} className="cstmaccrdn2 cstmaccrdn2_ash_01">
            <div className="d-flex input_bx">
              <FormControl>
                <input
                  className="input_fld"
                  value={balanceToDeposit}
                  onChange={changeDetailInputValue.bind(
                    this,
                    "depositedBalance",
                    index,
                    tokenBalance,
                    pool.tokenDecimals
                  )}
                />
              </FormControl>
            </div>

            <div className="progress_bx">
              <Slider
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={marks}
                value={balanceToDepositSlider}
                onChange={handleDepositedBalance.bind(
                  this,
                  index,
                  tokenBalance,
                  pool.tokenDecimals
                )}
              />
            </div>
          </Grid>

          <div className="progress_action accordan_inn_btn_prnt text-right mt-3 accordan_inn_btn_prnt_flx">
          {allowance === "0" ? (
              <Box className="btn_outrborder_sdw">
                <button
                  type="button"
                  className="btn btn_def_v3"
                  onClick={() => {
                    onApprove();
                  }}
                  disabled={isApprovePending}
                >
                  <span>
                    {isApprovePending ? `${"Approve..."}` : `${"Approve"}`}
                  </span>
                </button>
              </Box>
            ) : (
              <Box className="btn_outrborder_sdw">
                <button
                  type="button"
                  className="btn btn_def_v3"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={isDepositPending}
                  onClick={(event) => {
                    onDeposit(
                      balanceToDepositSlider >= 100
                        ? tokenBalance
                        : new BigNumber(balanceToDeposit)
                            .multipliedBy(new BigNumber(10).exponentiatedBy(18))
                            .toString(10)
                    );
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="20"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {"Stake"}
                </button>
              </Box>
            )}


          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid item xs={12} className="cstmaccrdn2 cstmaccrdn2_ash_01">
            <div className="d-flex input_bx">
              <FormControl fullWidth variant="outlined">
                <input
                  className="input_fld"
                  type="text"
                  value={balanceToWithdraw}
                  onChange={changeDetailInputValue.bind(
                    this,
                    "withdrawAmount",
                    index,
                    depositedBalance,
                    pool.tokenDecimals
                  )}
                />
              </FormControl>
            </div>
            <div className="progress_bx progress_bx_rd">
              <Slider
                value={balanceToWithdrawSlider}
                onChange={handleWithdrawAmount.bind(
                  this,
                  index,
                  depositedBalance,
                  pool.tokenDecimals
                )}
                getAriaValueText={withdrawvaluetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={withdraw}
              />
            </div>
          </Grid>
          <div className="progress_action accordan_inn_btn_prnt text-right mt-3 accordan_inn_btn_prnt_flx">
            <Box className="btn_outrborder_sdw">
              <button
                type="button"
                className="btn btn_def_v3 btn_def_v3_v2"
                disabled={isWithdarwPending}
                onClick={() =>
                  onWithdraw(
                    balanceToWithdrawSlider >= 100
                      ? depositedBalance
                      : new BigNumber(balanceToWithdraw)
                          .multipliedBy(new BigNumber(10).exponentiatedBy(18))
                          .toString(10)
                  )
                }
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={exitsign}
                  alt="logo"
                />
                <span>
                  {isWithdarwPending
                    ? `${"Withdrawing"}`
                    : `${"Unstake & claim xToken"}`}
                </span>
              </button>{" "}
            </Box>{" "}
          </div>

          <Typography component="h5">
            <br></br>
            <Alert severity="warning">You can either withdraw your staked Position above and you will receive x{pool.symbol} Token, OR you can claim {pool.symbol} NFTs instead below.
            </Alert>
            <br></br>
            <form className="saleForm">
              <label htmlFor="id" className="labelform">
                {" "}
                Withdraw as NFT: ( max: {maxnft[0]} )
              </label>
              <input
                type="text"
                value={quant}
                onChange={(e) => setQuant(e.target.value)}
                required
              />

              <Box className="checkbox_row">
                <Box className="checkbox_prnt">
                  <input
                    type="checkbox"
                    checked={claiming}
                    onChange={() => setClaiming(toggle)}
                  />
                  <label> + withdraw remaining Token! </label>
                </Box>
              </Box>
            </form>
          </Typography>

          <br></br>
          <div className="progress_action accordan_inn_btn_prnt text-right mt-3 accordan_inn_btn_prnt_flx">
            <Box className="btn_outrborder_sdw">
              <button
                type="button"
                className="btn btn_def_v3"
                onClick={() => {
                  onApprove2();
                }}
                disabled={isApprovePending2}
              >
                <span>
                  {isApprovePending2 ? `${"Approve..."}` : `${"Approve"}`}
                </span>
              </button>

              <button
                type="button"
                className="btn btn_def_v3 btn_def_v3_v2"
                disabled={isGetNFTsPending}
                onClick={() => onGetNFTs(quant, submit)}
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={exitsign}
                  alt="logo"
                />
                <span>
                  {isGetNFTsPending ? `${"Withdrawing"}` : `${"Unstake NFTs"}`}
                </span>
              </button>
            </Box>
          </div>
        </Grid>
      </Grid>
      <div className="othrinfo_as othrinfo_as_v3">
        <Typography component="p">
          Available NFT to Withdraw: <span>{maxnft[0]}</span>
        </Typography>
        <Typography component="p">
          NFTs: <span>{NFTBalance}</span>{" "}
        </Typography>
        <Typography component="p">
          xToken: <span>{forMat(byDecimals(xTokenBalance))}</span>
        </Typography>
        <Typography component="p">
          Timelock: <span>7 Days!</span>
        </Typography>
      </div>
    </>
  );
}
