import React from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";
//import { Link } from "react-router-dom";

function PoolsTitle() {
  return (
    <>
      <Box className="nft_ttl_prnt">
        <Box className=""></Box>
        <Container max-width="lg">
          <Grid container spacing={0} className="justify-content-center">
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography component="h2" className="def_ttl_v3 mb_100">
                    <span>
                      P<b>!</b>
                    </span>{" "}
                    NFT Staking
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  );
}

export default PoolsTitle;
