import { VaultPage } from '.';


export default {
  path: "demigods",
  childRoutes: [
    { path: "demigods", component: VaultPage, isIndex: true },

  ],
};
