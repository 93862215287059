import React from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";
//import { Link } from "react-router-dom";

function PoolsTitle() {
  return (
    <>
      <Box className="nft_ttl_prnt">
        <Box className=""></Box>
        <Container max-width="lg">
          <Grid container spacing={0} className="justify-content-center">
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography component="h2" className="def_ttl_v3 mb_100">
                    <span>
                      P<b>!</b>P
                    </span>{" "}
                    X SLP Vaults
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
{/*       <Box className="pd-20">
        <Link to="/launchpad" className="vttl_v3">
          <img src="/img/ttl_top.png" className="vttl_v3_tp_mi" alt="" />
          <Box className="invlt_ttl_v3">
            Click to claim your <span>free community NFT!</span>
          </Box>
          <Box className="vttl_v3_mddl_mi" />
          <img src="/img/ttl_bottom.png" className="vttl_v3_btm_mi" alt="" />
        </Link>
      </Box> */}
    </>
  );
}

export default PoolsTitle;
