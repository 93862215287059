export const lootboxpools = [
  {
    chainId: 56,
    name: "sCircle",
    desc: "PHOENIX",
    id: "1",
    tokenAddress: "0x796263Bb5706fE09bCb44514E1525602FcEbEA31", //
    earnContractAddress: "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1",
    tokenId: "1",
    lootId: "1",
    price: "0.25 BNB",
    price2: "sCircle",
    amount: "250000000000000000",
    amount2: "1"
  },
  {
    chainId: 1,
    name: "PIP",
    desc: "PANTHER",
    id: "2",
    tokenAddress: "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707", //
    earnContractAddress: "0xE651657fa2355261Bbf4037cF6af43825Af5Da22",
    tokenId: "1",
    lootId: "1",
    price: "0.05 ETH",
    price2: "PIP",
    amount: "50000000000000000",
    amount2: "60"
  },
];
