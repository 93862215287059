import { Metaverse } from '.';


export default {
  path: "metaverse",
  childRoutes: [
    { path: "metaverse", component: Metaverse, isIndex: true },

  ],
};
