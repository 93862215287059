export const stakingpools_721 = [
  {
    chainId: 137,
    id: "11",
    name: "MINTOPOLIST",
    token: "MINTOPOLIST",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0x2e7975fb8b9344fac82e19db239ad9e668b3eeb7", // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: "WMATIC",
    claimedTokenAddress: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270", // c
    earnedTokenAddress: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270", // c
    earnContractAddress: "0x94116E374B919f140004D9cff2122112bacfff32", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "90 Days",
    startdate: "2023-09-01",
    status: "aktive",
    typ: "nft",
    multi: 300,
    APYC: 300,
    ended: "no",
  },
  {
    chainId: 56,
    id: "11",
    name: "DUCKLINGS",
    token: "DUCKLINGS",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0x9A8f78f11C01cBef116DCC382aA81720EF550954", // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnContractAddress: "0x9942619f9CbCD374995358496382a4b671c0db74", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "360 Days",
    startdate: "2023-03-07",
    status: "aktive",
    typ: "nft",
    multi: 5.7,
    APYC: 9,
    ended: "no",
  },
  {
    chainId: 56,
    id: "10",
    name: "PENGUINS",
    token: "PENGUINS",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0x2875d883176901df63bca31646e7247b38ed4522", // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnContractAddress: "0x65FfEf20D8189163079Ff51d66c5179d05b0D267", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 Days",
    startdate: "2023-01-19",
    status: "aktive",
    typ: "nft",
    multi: 2.5,
    APYC: 2.5,
    ended: "no",
  },
  {
    chainId: 56,
    id: "8",
    name: "TH",
    token: "TH",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0x6a363d90d7c29036aa937b186aa27a301bb8e0ac", // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnContractAddress: "0x0C0db8D29fb6A39A62Bc16A39bFB95EEF8726C40", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 days",
    startdate: "2022-12-10",
    status: "aktive",
    typ: "nft",
    multi: 60,
    APYC: 50,
    ended: "no",
  },
  {
    chainId: 56,
    id: "5",
    name: "DHD",
    token: "DHD",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0xb9f9158369385ebb76cd3007d9465ba97afeb594", // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnContractAddress: "0x395183af35A079c5d6050a70648b5EfCa19C22d6", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "90 days",
    startdate: "2023-01-16",
    status: "aktive",
    typ: "nft",
    multi: 60,
    APYC: 69,
    ended: "no",
    BLL: "0x57C6aBC6Db2a726616805bC87539082f4678bC01",
  },
  {
    chainId: 56,
    id: "7",
    name: "TFK",
    token: "TFK",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0xf8116dcf8123df50ba5af00a41a14573f59f6ebb", // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnContractAddress: "0xAF94B0A9110212373C347C4077093ea650897bbD", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "90 days",
    startdate: "2023-01-16",
    status: "aktive",
    typ: "nft",
    multi: 60,
    APYC: 69,
    ended: "no",
    BLL: "0x57C6aBC6Db2a726616805bC87539082f4678bC01",
  },
  {
    chainId: 56,
    id: "6",
    name: "PICOINS",
    token: "PICOINS",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0xc3e859851bd19c952e924a24f872eb00abc20bbb", // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnContractAddress: "0xC842F03A22f49E05F91A64E0AFCA21E8323007aC", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "60 days",
    startdate: "2022-08-12",
    status: "aktive",
    typ: "nft",
    multi: 60,
    APYC: 69,
    ended: "no",
    BLL: "0x57C6aBC6Db2a726616805bC87539082f4678bC01",
  },

  /*   { 
    chainId: 1,
    id: '7',
    name: 'PANTHER',
    token: 'PHOENIX',
    tokenDescription: '',
    tokenDescription2: 'All NFTs, Class 1-8 (2-48)',
    tokenAddress: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',  // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: 'PIP',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    earnContractAddress: '0xC842F03A22f49E05F91A64E0AFCA21E8323007aC',  // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "60 days",
    startdate: "2022-08-12",
    status: 'aktive',
    typ: 'nft',
    multi: 60,
    APYC: 69,
    ended: "no",
    BLL: "0x57C6aBC6Db2a726616805bC87539082f4678bC01"
  }, */

  /*  { 
    chainId: 56,
    id: '1',
    name: 'MIAMI',
    token: 'MIAMI',
    tokenDescription: '',
    tokenDescription2: '',
    tokenAddress: '0xf98411AAa15FBa2Fa37dDE4243DA377C1543410c',  // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: 'PIP',
    claimedTokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE', // c
    earnedTokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
    earnContractAddress: '0x36A9FBe38782d3933324b1eD49B8EF6ea2a8C204',  // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "60 days",
    startdate: "2022-05-27",
    status: 'aktive',
    typ: 'nft',
    multi: 60,
    APYC: 69,
    ended: "yes",
    BLL: "0x57C6aBC6Db2a726616805bC87539082f4678bC01"

  },

  { 
    chainId: 56,
    id: '2',
    name: 'CHILD',
    token: 'CHILD',
    tokenDescription: '',
    tokenDescription2: '',
    tokenAddress: '0xcf44a553da623a900977884e0e8ef36987306567',  // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: 'PIP',
    claimedTokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE', // c
    earnedTokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
    earnContractAddress: '0xf0CBAC6Ba96b4c297fFdD397cBEF465856ec383B',  // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "60 days",
    startdate: "2022-05-27",
    status: 'aktive',
    typ: 'nft',
    multi: 279,
    APYC: 190,
    ended: "yes",
    BLL: "0x57C6aBC6Db2a726616805bC87539082f4678bC01"

  }, */
];
