import { FACABI, NFTUPGABI } from "features/configure";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { useConnectWallet } from "../../home/redux/hooks";
import axios from "axios";

export { useFetchPoolBalances } from "./fetchPoolBalances";

/// new
export const fetchTokens = async (
  from,
  count,
  type = "single",
  collections,
  category = null,
  sortBy = "id",
  filterBy,
  address,
  cancelToken
) => {
  const data = { from, count, type };
  if (collections.length > 0) {
    data.collectionAddresses = collections;
  }
  if (category !== null) {
    data.category = category;
  }
  if (address) {
    data.address = address;
  }
  if (filterBy.length) {
    data.filterby = filterBy;
  }
  data.sortby = sortBy;

  let apiUrl;
  if (collections[0] === "0x9873a0beb16ee9e2ccf6d11a8da9aeeae0d1c0a8") {
    apiUrl = "https://api.pi-marketplace.io";
  } else if (collections[0] === "0x9873a0beb16ee9e2ccf6d11a8da9aeeae0d1c0a8") {
    apiUrl = "https://api.pinftmarket.io";
  }

  const res = await axios({
    method: "post",
    url: `${apiUrl}/nftitems/fetchTokens`,
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken,
  });
  return res.data;
};

export function useRange1(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [supply, setSupply] = useState("0");

  const fetchSupply = useCallback(async () => {
    let minNftType;
    const contract = new web3.eth.Contract(FACABI, tokenAddress);

    minNftType = await contract.methods.minNftType().call();
    setSupply(minNftType);
  }, [setSupply, web3, tokenAddress]);

  useEffect(() => {
    if (web3 && address) {
      fetchSupply();

      let refreshInterval = setInterval(fetchSupply, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchSupply]);

  return supply;
}

export function useRange2(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [supply, setSupply] = useState("0");

  const fetchSupply = useCallback(async () => {
    let minNftType;
    const contract = new web3.eth.Contract(FACABI, tokenAddress);

    minNftType = await contract.methods.maxNftType().call();
    setSupply(minNftType);
  }, [setSupply, web3, tokenAddress]);

  useEffect(() => {
    if (web3 && address) {
      fetchSupply();

      let refreshInterval = setInterval(fetchSupply, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchSupply]);

  return supply;
}

export function useTime(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [supply, setSupply] = useState("0");

  const fetchSupply = useCallback(async () => {
    let minNftType;
    const contract = new web3.eth.Contract(FACABI, tokenAddress);

    minNftType = await contract.methods.rewardAvailableTimestamp().call();
    setSupply(minNftType);
  }, [setSupply, web3, tokenAddress]);

  useEffect(() => {
    if (web3 && address) {
      fetchSupply();

      let refreshInterval = setInterval(fetchSupply, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchSupply]);

  return supply;
}

export function useInfo(tokenAddress, pcs2) {
  const { web3, address } = useConnectWallet();
  const [reserve, setReserve] = useState("0");

  const fetchReserve = useCallback(async () => {
    let reserve;
    if (tokenAddress === "") {
      reserve = await web3.eth.getReserve(address);
    } else {
      const contract = new web3.eth.Contract(FACABI, tokenAddress);

      reserve = await contract.methods.getFactoryInfos(pcs2).call();
    }
    setReserve(reserve);
  }, [address, web3, tokenAddress, setReserve, pcs2]);

  useEffect(() => {
    if (web3 && address) {
      fetchReserve();

      let refreshInterval = setInterval(fetchReserve, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchReserve]);

  return reserve;
}

export function useApproveForAll(tokenAddress, tokenAddress1) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(
    async (owner) => {
      setIsPending(true);
      try {
        await new Promise((resolve, reject) => {
          const contract = new web3.eth.Contract(NFTUPGABI, tokenAddress1);

          contract.methods
            .setApprovalForAll(tokenAddress, true)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, tokenAddress1, tokenAddress]
  );

  return { isPending, onApprove: handleApprove };
}

export function useDeposit(tokenAddress1) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (tokenId, shard) => {
      console.log(tokenId, shard);
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(FACABI, tokenAddress1);
          contract.methods
            .startOrBoostFactory(tokenId, shard)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [web3, tokenAddress1, address, dispatch]
  );

  return { isPending, onDeposit: handleDeposit };
}

export function useWithdraw(tokenAddress1) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleWithdraw = useCallback(
    async (amount) => {
      console.log(amount);
      setIsPending(true);
      console.log([amount]);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(FACABI, tokenAddress1);

          contract.methods
            .claim([amount])
            .send({ from: address })

            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, tokenAddress1]
  );

  return { isPending, onWithdraw: handleWithdraw };
}

export function useBoost(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [supply, setSupply] = useState("0");

  const fetchSupply = useCallback(async () => {
    let minNftType;
    const contract = new web3.eth.Contract(FACABI, tokenAddress);

    minNftType = await contract.methods.boostPercentPerPowerPoint().call();
    setSupply(minNftType);
  }, [setSupply, web3, tokenAddress]);

  useEffect(() => {
    if (web3 && address) {
      fetchSupply();

      let refreshInterval = setInterval(fetchSupply, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchSupply]);

  return supply;
}

export function useIsApproved(tokenAddress, tokenAddress1) {
  const { web3, address } = useConnectWallet();
  const [reserve, setReserve] = useState("0");

  const fetchReserve = useCallback(async () => {
    let reserve;
    if (tokenAddress === "") {
      reserve = await web3.eth.getReserve(address);
    } else {
      const contract = new web3.eth.Contract(NFTUPGABI, tokenAddress);

      reserve = await contract.methods
        .isApprovedForAll(address, tokenAddress1)
        .call();
    }
    setReserve(reserve);
  }, [tokenAddress, web3, address, tokenAddress1]);

  useEffect(() => {
    if (web3 && address) {
      fetchReserve();

      let refreshInterval = setInterval(fetchReserve, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchReserve]);

  return reserve;
}