import React, { useState, useRef } from "react";
import BigNumber from "bignumber.js";
import { Button } from "@material-ui/core";
import { byDecimals } from "features/helpers/bignumber";
import { useConnectWallet } from "../../home/redux/hooks";

import {
  useAllowance3,
  useApprove,
  useApprove3,
  useDeposit,
  useDeposit3,
  useBalanceOf,
  useDeposit2,
  fetchTokens,
  useDeposit4
} from "../redux/hooks";

import { Grid, Box, Typography } from "@material-ui/core";
import MetaverseTitle from "./MetaverseTitle";

import { CardTitle, CardBody } from "reactstrap";

export default function PoolContent({ npool }) {
  const { address, networkId } = useConnectWallet();

  const tokens = useRef([]);
  // eslint-disable-next-line no-unused-vars
  const [fetching, setFetching] = useState(false);
  const [fetched, setFetched] = useState([]);

  const allowance3 = useAllowance3(
    npool.tokenAddress,
    npool.earnContractAddress
  );

  const { isPending: isApprovePending, onApprove } = useApprove(
    npool.tokenAddress,
    npool.earnContractAddress
  );

  const { isPending: isApprovePending3, onApprove3 } = useApprove3(
    npool.tokenAddress,
    npool.earnContractAddress
  );

  const { onDeposit3, isPending: isDepositPending3 } = useDeposit3(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit4, isPending: isDepositPending4 } = useDeposit4(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const tokenBalance = useBalanceOf(npool.tokenAddress, npool.tokenId);
  let singleBalance = byDecimals(tokenBalance, 18);

  const [quant, setQuant] = useState(0);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  let tokenAddress1;
  if (networkId === 56) {
    tokenAddress1 = "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1";
  } else if (networkId === 1) {
    tokenAddress1 = "0xe651657fa2355261bbf4037cf6af43825af5da22";
  }

  const fetchNFTs = async (address) => {
    setFetching(true);
    setFetched([]);
    try {
      const { data } = await fetchTokens(
        0,
        5000,
        "single",
        [tokenAddress1],
        null,
        "id",
        [],
        null,
        address
      );
      tokens.current = [...data.tokens];
      setFetched(tokens.current);
      setFetching(false);
    } catch {
      setFetching(false);
    }
  };

  let filtered = fetched.filter((t) => t.contractAddress === tokenAddress1);
  let filtered2 = filtered.filter((t) => t.owner === address.toLowerCase());
  let filtered3 = filtered2.filter(
    (t) => t.imageURL === "https://storage.pi-marketplace.io/ipfs/PANTHER/1.png" || t.imageURL === "https://storage.pi-marketplace.io/ipfs/PHOENIX/1.png" 
  );
  const filtered4 = [...filtered3.map(({ tokenID }) => tokenID)];

  return (
    <>
      <Box className="mtvvrs_main_bx footer_spaceing">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MetaverseTitle />
          </Grid>
        </Grid>

        <Box className="justify-content-center">
          <Grid container spacing={0}>
            <Box className="cmng_sn_bx_v3">
              <Box className="two_img_bx">
                <Grid item lg={12} className="justify-content-center">
                  <Grid item xs={12}>
                    <Box className="nvr_crcl_box">
                      <img
                        src="/images/nwcrcl_top.png"
                        alt=""
                        className="nvr_tp_img"
                      />
                      <Box className="nvr_mddl_img" />
                      <img
                        src="/images/nwcrcl_bttm.png"
                        alt=""
                        className="nvr_bttm_img"
                      />
                      <Box className="nvr_bx_cntn">
                        <Typography component="h5">
                          <span>{npool.desc}</span>
                        </Typography>
                        <Typography component="h6">
                          To get a {npool.desc} Box (lootbox), you have to spend{" "}
                          {npool.price2}.
                        </Typography>
                        <Typography component="h6">
                          To unbox the Box, you have to pay {npool.price}.
                        </Typography>
                        <br></br>
                        <Typography component="h6">
                          The unbox Process takes up to 10 minutes, you can
                          check your {npool.desc} NFTs afterwards at the NFT
                          Marketplace and can be seen at Telegram, too.
                        </Typography>
                        <div className="progress_action mt-3 accordan_inn_btn_prnt btnprntv4">
                          <Box className="btn_outrborder_sdw">
                            <Button
                              href="https://www.pi-marketplace.io"
                              target="_blank"
                              type="button"
                              rel="nofollow noopener noreferrer"
                              className="btn btn_def_v3"
                            >
                              <span>{"Marketplace"}</span>
                            </Button>
                          </Box>
                        </div>
                      </Box>
                    </Box>
                    {npool.id === "1" ? (
                      <>
                        <Box className="loot3 img-fluid justify-content-center">
                          <CardBody className="card_body_as_v3">
                            <CardTitle tag="h4" className="text-center">
                              <div className="space-5"> </div>
                              <br></br>
                              <p className="text-white">
                                Your available {npool.name}:{" "}
                                {forMat(tokenBalance)}
                              </p>
                              <div className="space-200"> </div>

                              {allowance3 === false ? (
                                <div className="progress_action mt-3 accordan_inn_btn_prnt btnprntv4">
                                  <Typography
                                    component="p"
                                    className="btn_btm_p"
                                  >
                                    <span className="text-white">
                                      {" "}
                                      Buy with {npool.amount2} {npool.price2}
                                    </span>
                                  </Typography>
                                  <Box className="btn_outrborder_sdw">
                                    <button
                                      type="button"
                                      className="btn btn_def_v3"
                                      onClick={() => {
                                        onApprove3();
                                      }}
                                      disabled={isApprovePending3}
                                    >
                                      {isApprovePending3
                                        ? `${"Approving..."}`
                                        : `${"Approve"}`}
                                    </button>
                                  </Box>
                                </div>
                              ) : (
                                <>
                                  <div className="progress_action  mt-3 accordan_inn_btn_prnt btnprntv4">
                                    <Typography
                                      component="p"
                                      className="btn_btm_p"
                                    >
                                      <span className="text-white">
                                        {" "}
                                        Buy with {npool.amount2} {npool.price2}
                                      </span>
                                    </Typography>
                                    <Box className="btn_outrborder_sdw">
                                      <button
                                        type="button"
                                        className="btn btn_def_v3"
                                        onFocus={(event) =>
                                          event.stopPropagation()
                                        }
                                        disabled={isDepositPending2}
                                        onClick={(event) => {
                                          onDeposit2();
                                        }}
                                      >
                                        {" "}
                                        {"Buy Box"}
                                      </button>
                                    </Box>
                                  </div>
                                </>
                              )}
                            </CardTitle>
                          </CardBody>
                        </Box>
                      </>
                    ) : (
                      <> </>
                    )}

                    {npool.id === "2" ? (
                      <>
                        <Box className="loot3 img-fluid justify-content-center">
                          <CardBody className="card_body_as_v3">
                            <CardTitle tag="h4" className="text-center">
                              <p className="text-white">
                                Your available {npool.name}:{" "}
                                {forMat(singleBalance).toFixed(2)}
                              </p>
                              <br></br>
                              <br></br>
                              <div className="space-100"> </div>

                              {allowance3 === "0" ? (
                                <div className="progress_action mt-3 accordan_inn_btn_prnt btnprntv4">
                                  <Typography
                                    component="p"
                                    className="btn_btm_p"
                                  >
                                    <span className="text-white">
                                      {" "}
                                      Buy with {npool.amount2} {npool.price2}
                                    </span>
                                  </Typography>
                                  <Box className="btn_outrborder_sdw">
                                    <button
                                      type="button"
                                      className="btn btn_def_v3"
                                      onClick={() => {
                                        onApprove();
                                      }}
                                      disabled={isApprovePending}
                                    >
                                      {isApprovePending
                                        ? `${"Approving..."}`
                                        : `${"Approve"}`}
                                    </button>
                                  </Box>
                                </div>
                              ) : (
                                <>
                                  <div className="progress_action  mt-3 accordan_inn_btn_prnt btnprntv4">
                                    <Typography
                                      component="p"
                                      className="btn_btm_p"
                                    >
                                      <span className="text-white">
                                        {" "}
                                        Buy with {npool.amount2} {npool.price2}
                                      </span>
                                    </Typography>
                                    <Box className="btn_outrborder_sdw">
                                      <button
                                        type="button"
                                        className="btn btn_def_v3"
                                        onFocus={(event) =>
                                          event.stopPropagation()
                                        }
                                        disabled={isDepositPending2}
                                        onClick={(event) => {
                                          onDeposit2();
                                        }}
                                      >
                                        {" "}
                                        {"Buy 1 Box"}
                                      </button>
                                    </Box>

                                    <Typography
                                      component="p"
                                      className="btn_btm_p"
                                    >
                                      <span className="text-white">
                                        {" "}
                                        Buy with {npool.amount2 * 3}{" "}
                                        {npool.price2}
                                      </span>
                                    </Typography>
                                    <Box className="btn_outrborder_sdw">
                                      <button
                                        type="button"
                                        className="btn btn_def_v3"
                                        onFocus={(event) =>
                                          event.stopPropagation()
                                        }
                                        disabled={isDepositPending3}
                                        onClick={(event) => {
                                          onDeposit3();
                                        }}
                                      >
                                        {" "}
                                        {"Buy 3 Boxes"}
                                      </button>
                                    </Box>
                                  </div>
                                </>
                              )}
                            </CardTitle>
                          </CardBody>
                        </Box>
                      </>
                    ) : (
                      <> </>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      component="img"
                      className="img-fluid"
                      src="/images/placeholder.PNG"
                      alt=""
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="loot4 img-fluid justify-content-center">
                      <CardBody className="card_body_as_v3">
                        <CardTitle tag="h4" className="text-center text-black">
                          <div className="space-5"> </div>
                          <br></br>
                          <Typography component="p" className="back_bahama_p2 ">
                            <span> Unbox with {npool.price}</span>
                          </Typography>
                          <Box className="button_box">
                            <form className="saleForm">
                              <Typography
                                component="p"
                                className="back_bahama_p2"
                              >
                                {" "}
                                Enter the Token ID, {"BOX"}, can be found
                                below...
                              </Typography>
                              <input
                                type="text"
                                value={quant}
                                onChange={(e) => setQuant(e.target.value)}
                                required
                              />
                            </form>
                          </Box>
                          <div className="space-70"> </div>
                          <div className="accordan_inn_btn_prnt btnprntv3 justify-content-center">
                            <Box className="btn_outrborder_sdw accordan_inn_btn_prnt btnprntv3 justify-content-center">
                              <button
                                type="button"
                                className="btn btn_def_v3 btn_def_v3_v2"
                                onFocus={(event) => event.stopPropagation()}
                                disabled={isDepositPending}
                                onClick={(event) => {
                                  fetchNFTs();
                                }}
                              >
                                {" "}
                                <span>get your IDs</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn_def_v3 btn_def_v3_v2"
                                onFocus={(event) => event.stopPropagation()}
                                disabled={isDepositPending4 || quant === 0}
                                onClick={(event) => {
                                  onDeposit4(npool.amount, quant);
                                }}
                              >
                                {" "}
                                <span>Unbox Pi-Box</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn_def_v3 btn_def_v3_v2"
                                onFocus={(event) => event.stopPropagation()}
                                disabled={isDepositPending || filtered4.length === 0}
                                onClick={(event) => {
                                  onDeposit(npool.amount, filtered4);
                                }}
                              >
                                {" "} {console.log(filtered4)}
                                <span>Unbox ALL</span>
                              </button>
                            </Box>
                          </div>
                          <div className="space-20"> </div>
                          <div className="accordan_inn_btn_prnt btnprntv3 justify-content-center">
                            <Box className="btn_outrborder_sdw accordan_inn_btn_prnt btnprntv3 justify-content-center">
                              {filtered3.map((home) => (
                                <button
                                  type="button"
                                  className="btn btn_def_v3 btn_def_v3_v2"
                                  disabled
                                >
                                  {" "}
                                  <span>{home.tokenID}</span>
                                </button>
                              ))}
                            </Box>
                          </div>
                        </CardTitle>
                      </CardBody>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
