import React, { useEffect, useState, useCallback } from "react";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { useConnectWallet, useDisconnectWallet } from "./redux/hooks";
import { createWeb3Modal } from "../web3";
import appStyle from "./jss/appStyle.js";
import Header from "components/Header/Header";
import Footer from "components/Header/Footer";
import { Notifier } from "features/common";
import FooterLanding from "components/Header/FooterLanding";
//import { MoralisProvider } from "react-moralis";

const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const classes = useStyles();

  const { connectWallet, web3, address, connected, networkId } = useConnectWallet();

  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null);

  useEffect(() => {
    setModal(createWeb3Modal());
  }, [setModal]);

  useEffect(() => {
    if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
      connectWallet(web3Modal);
    }
  }, [web3Modal, connectWallet]);

  const connectWalletCallback = useCallback(() => {
    connectWallet(web3Modal);
  }, [web3Modal, connectWallet]);

  const disconnectWalletCallback = useCallback(() => {
    disconnectWallet(web3, web3Modal);
  }, [web3, web3Modal, disconnectWallet]);

//const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
//const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

  return (
    <>
      <SnackbarProvider className="space-100">
        <div className={classes.page}>
          <Header
            address={address}
            connected={connected}
            connectWallet={connectWalletCallback}
            disconnectWallet={disconnectWalletCallback}
            networkId={networkId}
          />
          <div className={classes.children}>
            {children}
            <Notifier />
          </div>
          <Footer />
          <FooterLanding />
        </div>
      </SnackbarProvider>
    </>
  );
}
