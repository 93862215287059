import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {
  fetchMaticPrice,
  fetchDmagicPrice,
} from "features/circle/sections/Statistic";
import {
  useEarned,
  useBalanceOf,
  useDmagicAPY,
  useDmagicWPY,
  useDuration,
  useBalanceNFTOf,
  usePriceOf,
  useSupply,
} from "../redux/hooks";

const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100000))
    .toNumber();
};

const forMatApy = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100))
    .toNumber();
};

export default function PoolHeader({ pool }) {
  const tokenBalance = useBalanceOf(pool.tokenAddress, pool.tokenId);
  const depositedBalance = useBalanceNFTOf(
    pool.earnContractAddress,
    pool.tokenId
  );

  const earned = useEarned(pool.earnContractAddress);
  const formattedEarned = byDecimals(earned);
  const dmagicAPY = useDmagicAPY(pool.earnContractAddress);
  const dmagicWPY = useDmagicWPY(pool.earnContractAddress);
  const duration = useDuration(pool.earnContractAddress);
  const [maticprice, setmaticprice] = useState(0);
  const [dmprice, setDmPrice] = useState(0);

  const price = usePriceOf(pool.tokenAddress, pool.pcs2);
  const supply = useSupply(pool.tokenAddress, pool.pcs2);

  useEffect(() => {
    const getMaticInfo = async () => {
      const {
        RAW: {
          BNB: {
            USD: { PRICE },
          },
        },
      } = await fetchMaticPrice();
      const data = {
        MaticPrice: PRICE,
      };
      const dmagicMilPrice = PRICE;
      data.dmagicMilPrice = dmagicMilPrice;
      setmaticprice(data.dmagicMilPrice);
    };
    getMaticInfo();
  }, [maticprice]);

  useEffect(() => {
    const getDmagicInfo = async () => {
      const {
        data: { usdPrice },
      } = await fetchDmagicPrice();

      const data = {
        DmagicPrice: usdPrice,
      };
      const dmagicMilPrice = usdPrice;
      data.dmagicMilPrice = dmagicMilPrice;
      setDmPrice(data.dmagicMilPrice);
    };
    getDmagicInfo();
  }, []);

  const thi2s =
    ((price[1] * maticprice + price[0] * dmprice) / supply / dmprice) * 1.14;

  return (
    <>
      <Grid container direction="row" alignItems="center" className="head_prnt">
        <Grid container spacing={1} className="align-items-center">
          <Grid item xs={12} md={6} lg={5} className="">
            <Box className="avtr_text_ash_prnt">
              <AvatarGroup max={4} className="avrggrp">
                <Avatar
                  alt={pool.token}
                  src={require(`../../../images/${pool.token}-logo.png`)}
                />
              </AvatarGroup>
              <Box className="tvl_usd_text_v3">
                <Typography component="label">
                  TVL:{" "}
                  {depositedBalance === "0" ? (
                    <>
                      <span className="clpsgreentxt">
                        {byDecimals(duration * dmprice * pool.multi).toFixed(2)}{" "}
                      </span>
                      USD{" "}
                    </>
                  ) : (
                    <>
                      <span>
                        <b>
                          {byDecimals(duration * dmprice * pool.multi).toFixed(
                            2
                          )}
                        </b>
                      </span>{" "}
                      USD{" "}
                    </>
                  )}
                </Typography>
                {pool.ended === "yes" ? (
                  <>
                    <Typography component="h6" className="grin_bustble">
                      ENDED
                    </Typography>
                  </>
                ) : (
                  <></>
                )}

                <Typography component="label">
                  {pool.tokenDescription}
                </Typography>
                <Typography component="label">
                  {pool.tokenDescription2}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3} className="bx_cnt_scnd">
            <Box className="apr_weekly_prnt_ash">
              {pool.id === "4" ? (
                <Typography component="h4">Team Token Vesting</Typography>
              ) : (
                <Typography component="h4">
                  Stake <span className="grntxtgrdnt">{pool.token}</span> for{" "}
                  <span className="yllwtxtgrdnt">{pool.earnedToken}</span>
                </Typography>
              )}

              <Typography component="label">
                {pool.chainId === 56 ? (
                  <>
                    {" "}
                    APR{" "}
                    <span className="clpsyllwtxt">
                      {forMatApy(
                        byDecimals(
                          dmagicAPY / (thi2s * pool.APYC),
                          6
                        ).toNumber()
                      )}{" "}
                      %{" "}
                    </span>
                  </>
                ) : (
                  <>
                    APR{" "}
                    <span className="clpsyllwtxt">
                      <b>
                        {forMatApy(
                          byDecimals(
                            dmagicAPY / (pool.LPP * pool.APYC),
                            6
                          ).toNumber()
                        )}{" "}
                        %{" "}
                      </b>
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="label">
                {pool.chainId === 56 ? (
                  <>
                    {" "}
                    Weekly{" "}
                    <span className="clpsyllwtxt">
                      {forMatApy(
                        byDecimals(
                          dmagicWPY / (thi2s * pool.APYC),
                          6
                        ).toNumber()
                      )}{" "}
                      %{" "}
                    </span>
                  </>
                ) : (
                  <>
                    Weekly{" "}
                    <span className="clpsyllwtxt">
                      <b>
                        {" "}
                        {forMatApy(
                          byDecimals(
                            dmagicWPY / (pool.LPP * pool.APYC),
                            6
                          ).toNumber()
                        )}{" "}
                        %{" "}
                      </b>
                    </span>{" "}
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className="bx_cnt_trd">
            <Box className="cnt_trd_box cntnt_box_ash mx_275">
              <Typography component="p">
                Earned
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(formattedEarned)}
                      </span>{" "}
                      {pool.earnedToken}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(formattedEarned)}
                      </span>{" "}
                      {pool.earnedToken}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Unstaked
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(tokenBalance)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(tokenBalance)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Staked
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(depositedBalance)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(depositedBalance)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box className="expndmr_btn"></Box>
    </>
  );
}
