import {
  erc20ABI,
  dragonDirect,
  erc1155,
  circles,
  pcs,
 // cdirect
} from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { ethers } from "ethers";
import { useConnectWallet } from "../../home/redux/hooks";
export { useFetchPoolBalances } from "./fetchPoolBalances";

export function useApprove2(tokenAddress2, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress2);

        contract.methods
          .approve(poolAddress, ethers.constants.MaxUint256.toString(10))
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress2]);

  return { isPending, onApprove2: handleApprove };
}

export function useApprove3(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc1155, tokenAddress);

        contract.methods
          .setApprovalForAll(poolAddress, true)
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

  return { isPending, onApprove3: handleApprove };
}

export function useAllowance2(tokenAddress2, spender) {
  const { web3, address } = useConnectWallet();
  const [allowance2, setAllowance2] = useState("0");

  const fetchAllowance2 = useCallback(async () => {
    if (tokenAddress2 === "") {
      setAllowance2(ethers.constants.MaxUint256.toString(10));
      return;
    }

    const contract = new web3.eth.Contract(erc20ABI, tokenAddress2);

    const allowance2 = await contract.methods
      .allowance(address, spender)
      .call();
    setAllowance2(allowance2);
  }, [address, spender, setAllowance2, tokenAddress2, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAllowance2();

      let refreshInterval = setInterval(fetchAllowance2, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAllowance2]);

  return allowance2;
}

export function useAllowance3(tokenAddress, earnContractAddress) {
  const { web3, address } = useConnectWallet();
  const [allowance3, setAllowance3] = useState("0");

  const fetchAllowance3 = useCallback(async () => {
    const contract = new web3.eth.Contract(circles, tokenAddress);
    const allowance3 = await contract.methods
      .isApprovedForAll(address, earnContractAddress)
      .call();

    setAllowance3(allowance3);
  }, [address, tokenAddress, setAllowance3, earnContractAddress, web3]);
  useEffect(() => {
    if (web3 && address) {
      fetchAllowance3();
      let refreshInterval = setInterval(fetchAllowance3, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAllowance3]);

  return allowance3;
}

export function useBalanceOf(tokenAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc1155, tokenAddress);

      balance = await contract.methods.balanceOf(address, tokenId).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3, tokenId]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

export function useDeposit(poolAddress, tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(dragonDirect, poolAddress);
          //   contract.methods.buyWithBNB().send({ from: address, value: amount })
          contract.methods
            .easyBuySmallCircle()
            .send({ from: address, value: amount })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit: handleDeposit };
}

export function useDeposit2(poolAddress, tokenAddress2) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit2 = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(dragonDirect, poolAddress);
           /*  contract.methods.buyWithERC20().send({ from: address}) */
          contract.methods
            .easyBuyFromPi(amount)
            .send({ from: address })

            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit2: handleDeposit2 };
}

export function useDeposit3(poolAddress, tokenAddress2) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit3 = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(dragonDirect, poolAddress);
          contract.methods
            .buyBigCircle(amount)
            .send({ from: address })

            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit3: handleDeposit3 };
}

export function usePriceOf(tokenAddress, pcs2) {
  const { web3, address } = useConnectWallet();
  const [reserve, setReserve] = useState("0");

  const fetchReserve = useCallback(async () => {
    let reserve;
    if (tokenAddress === "") {
      reserve = await web3.eth.getReserve(address);
    } else {
      const contract = new web3.eth.Contract(
        pcs,
        pcs2
      );

      reserve = await contract.methods.getReserves().call();
    }
    setReserve(reserve);
  }, [address, web3, tokenAddress, pcs2, setReserve]);

  useEffect(() => {
    if (web3 && address) {
      fetchReserve();

      let refreshInterval = setInterval(fetchReserve, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchReserve]);

  return reserve;
}

export function useSupply(tokenAddress, pcs2) {
  const { web3, address } = useConnectWallet();
  const [supply, setSupply] = useState("0");

  const fetchSupply = useCallback(async () => {
    let supply;
    if (tokenAddress === "") {
      supply = await web3.eth.getSupply(address);
    } else {
      const contract = new web3.eth.Contract(
        pcs,
        pcs2
      );

      supply = await contract.methods.totalSupply().call();
    }
    setSupply(supply);
  }, [address, setSupply, tokenAddress, web3, pcs2]);

  useEffect(() => {
    if (web3 && address) {
      fetchSupply();

      let refreshInterval = setInterval(fetchSupply, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchSupply]);

  return supply;
}



