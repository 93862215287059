import { erc20ABI, breed, badge, lootAbi } from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { useConnectWallet } from "../../home/redux/hooks";
import { ethers } from "ethers";
import axios from "axios";
export { useFetchPoolBalances } from "./fetchPoolBalances";

export const fetchTokens = async (
  from,
  count,
  type = "single",
  collections,
  category = null,
  sortBy = "id",
  filterBy,
  address,
  cancelToken
) => {
  const data = { from, count, type };
  if (collections.length > 0) {
    data.collectionAddresses = collections;
  }
  if (category !== null) {
    data.category = category;
  }
  if (address) {
    data.address = address;
  }
  if (filterBy.length) {
    data.filterby = filterBy;
  }
  data.sortby = sortBy;

  let apiUrl
  if ( collections[0] === "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1") {
  apiUrl = "https://api.pi-marketplace.io";
  } else 
  if ( collections[0] === "0xe651657fa2355261bbf4037cf6af43825af5da22") { 
  apiUrl = "https://api.pinftmarket.io";
  }
  
  const res = await axios({
    method: "post",
    url: `${apiUrl}/nftitems/fetchTokens`,
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken,
  });
  return res.data;
};


// ok
export function useDeposit(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (amount, quant, quant2) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(badge, poolAddress);
          contract.methods
            .stake(quant, quant2)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit: handleDeposit };
}

// OK 
export function useAllowance(tokenAddress, spender) {
  const { web3, address } = useConnectWallet();
  const [allowance, setAllowance] = useState("0");

  const fetchAllowance = useCallback(async () => {
    if (tokenAddress === "") {
      setAllowance(ethers.constants.MaxUint256.toString(10));
      return;
    }
    const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

    const allowance = await contract.methods.allowance(address, spender).call();
    setAllowance(allowance);
  }, [address, spender, setAllowance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAllowance();

      let refreshInterval = setInterval(fetchAllowance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAllowance]);

  return allowance;
}

// OK
export function useApprove(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

        contract.methods
          .approve(poolAddress, ethers.constants.MaxUint256.toString(10))
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

  return { isPending, onApprove: handleApprove };
}

// OK
export function useApproveForAll(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove2 = useCallback(
    async (owner) => {
      setIsPending(true);
      try {
        await new Promise((resolve, reject) => {
          const contract = new web3.eth.Contract(lootAbi, tokenAddress);

          contract.methods
            .setApprovalForAll(poolAddress, true)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]
  );

  return { isPending, onApprove2: handleApprove2 };
}

// OK
export function useBalanceOf(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}
// ok
export function useDuration(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [Duration, setDuration] = useState("0");

  const fetchDuration = useCallback(async () => {
    const contract = new web3.eth.Contract(breed, poolAddress);

    const Duration = await contract.methods
      .totalStaked()
      .call({ from: address });
    setDuration(Duration);
  }, [address, setDuration, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchDuration();
      let refreshInterval = setInterval(fetchDuration, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchDuration]);

  return Duration;
}

// ok
export function useWithdraw(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleWithdraw = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(breed, poolAddress);

          contract.methods
            .withdraw()
            .send({ from: address })

            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onWithdraw: handleWithdraw };
}

// ok 
export function useInfo(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState(['']);

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(breed, poolAddress);

    const earned = await contract.methods
      .userInfos(address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}
