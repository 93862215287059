import { erc20ABI, erc1155 } from "../configure";
import BigNumber from "bignumber.js";
import { enqueueSnackbar } from '../common/redux/actions';

export const approval = ({web3, address, tokenAddress, contractAddress, dispatch, call}) => {
  // console.log(`=====================================approval begin=====================================`)
  return new Promise((resolve, reject) => {
    const contract = new web3.eth.Contract(erc20ABI, tokenAddress);
    const contract2 = new web3.eth.Contract(erc1155, tokenAddress);

    // console.log(`
    //   address:${address}\n
    //   tokenAddress:${tokenAddress}\n
    //   contractAddress:${contractAddress}\n
    //   amount:${web3.utils.toWei('79228162514', "ether")}
    // `)

    if (tokenAddress === '0xcF5DbC399F3112bac87f57018A3389911241237B') {
    contract2.methods.setApprovalForAll('0xEFeadeb2445E8e2b0c027001bA3d67b29D5a84e7', true).send({ from: address }).on(
      'transactionHash', function(hash){
        dispatch(enqueueSnackbar({
          message: hash,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          },
          hash
        }));
      })
      .on('receipt', function(receipt){
        resolve(new BigNumber(79228162514).toNumber());

      })
      .on('error', function(error) {
        reject(error)
      })
      .catch((error) => {
        reject(error)
      })
  
    } else {
      contract.methods.approve(contractAddress, web3.utils.toWei('79228162514', "ether")).send({ from: address }).on(
        'transactionHash', function(hash){
          dispatch(enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            },
            hash
          }));
        })
        .on('receipt', function(receipt){
          resolve(new BigNumber(79228162514).toNumber());
  
        })
        .on('error', function(error) {
          reject(error)
        })
        .catch((error) => {
          reject(error)
        })


    }
  });

  
  // console.log(`=====================================approval success=====================================`)
}