import React from "react";
import { Container, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function MetaverseSec() {
  return (
    <>
      <Box className="metaverse_sec_main">
        <Box
          component="img"
          src="/images/big_text_meta_bg.png"
          className="img-fluid big_text_meta_bg"
        />
        <Container className="container">
          <Typography component="h2" className="def_ttl mb-3">
            <span>
              P<b className="i_small">!</b>
            </span>{" "}
            Metaverse
          </Typography>
          <Typography className="def_p">
            Pi Metaverse is unlike anything that you have seen before. It is a
            Metaverse that is fully focused on treasure hunts.
          </Typography>
          <Box className="metaverse_sec_inn">
            <Box className="fram_img_textprnt_top">
              <Box>
                <Box
                  component="img"
                  src="/images/metafram_with_img01.png"
                  className="img-fluid metafram_with_img01"
                />
              </Box>
              <Box className="textprnt_box">
                <Typography component="h5">The new ERA</Typography>
                <Typography>
                  The New Era is here. Get yourself strapped and ready to tackle
                  the most challenging and exciting treasure hunts to win
                  amazing prizes.
                </Typography>
              </Box>
            </Box>
            <Box className="btn_cntr_build_text_prnt">
              <Box className="buy_pi_arrow_btn_prnt">
                <Link to="/metaverse" className="buy_pi_arrow_btn">
                  <span>metaverse</span>
                </Link>
              </Box>
              <Box
                component="img"
                src="/images/build_new_text_img.svg"
                className="img-fluid build_new_text_img"
              />
            </Box>
            <Box className="fram_img_textprnt_bottom">
              <Box className="textprnt_box">
                <Typography component="h5">Metaverse WORLD</Typography>
              </Box>
              <Box className="ms-auto">
                <Box
                  component="img"
                  src="/images/metafram_with_img012.png"
                  className="img-fluid metafram_with_img02"
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
