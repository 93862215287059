import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import {
  STAKING_ERCFETCH_POOL_BALANCES_BEGIN,
  STAKING_ERCFETCH_POOL_BALANCES_SUCCESS,
  STAKING_ERCFETCH_POOL_BALANCES_FAILURE,
} from './constants';

export function useFetchPoolBalances() {
  // args: false value or array
  // if array, means args passed to the action creator

  const { stakingpools_erc, fetchPoolBalancesPending } = useSelector(
    state => ({
      stakingpools_erc: state.staking_erc.stakingpools_erc,
      fetchPoolBalancesPending: state.staking_erc.fetchPoolBalancesPending,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (data) => {
    },
    [],
  );

  return {
    stakingpools_erc,
    fetchPoolBalances: boundAction,
    fetchPoolBalancesPending
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case STAKING_ERCFETCH_POOL_BALANCES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchPoolBalancesPending: true,
      };

    case STAKING_ERCFETCH_POOL_BALANCES_SUCCESS:
      // The request is success
      return {
        ...state,
        stakingpools_erc: action.data,
        fetchPoolBalancesPending: false,
      };

    case STAKING_ERCFETCH_POOL_BALANCES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchPoolBalancesPending: false,
      };

    default:
      return state;
  }
}