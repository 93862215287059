
export const nftpools = [
  { 
    chainId: 56,
    name: 'sCircle',
    id: 'scircle',
    tokenAddress: '0x796263Bb5706fE09bCb44514E1525602FcEbEA31',   // scircle   
    tokenAddress2: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // pi
    earnContractAddress: '0x679F2f1C88CC0c2424AE3088Cb71b35CEAeb02F5', // wrapper
    earnContractAddress2: '0x98B07cD5Ca128E5b8a9D3a24f95dA8d551E25A46', // own contract
    tokenId: "1",
    price: "0.15 BNB",
    price2: "36 PI",
    pcs2: "0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f"  // lp
  },

  { 
    chainId: 56,
    name: 'bCircle',
    id: 'bcircle',
    tokenAddress: '0x796263Bb5706fE09bCb44514E1525602FcEbEA31',   //    
    tokenAddress2: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // 
    earnContractAddress: '0x679F2f1C88CC0c2424AE3088Cb71b35CEAeb02F5',
    earnContractAddress2: '0x98B07cD5Ca128E5b8a9D3a24f95dA8d551E25A46',
    tokenId: "2",
    price: "",
    price2: "5 sCircle",
    pcs2: "0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f"

  },

  { 
    chainId: 56,
    name: 'bitCircle',
    id: 'bitcircle',
    tokenAddress: '0x796263Bb5706fE09bCb44514E1525602FcEbEA31',   //    
    tokenAddress2: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // 
    earnContractAddress: '0x679F2f1C88CC0c2424AE3088Cb71b35CEAeb02F5',
    earnContractAddress2: '0x98B07cD5Ca128E5b8a9D3a24f95dA8d551E25A46',
    tokenId: "1",
    price: "",
    price2: "",
    pcs2: "0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f"
  },
  { 
    chainId: 1,
    name: 'sCircle',
    id: 'scircle',
    tokenAddress: '0xcF5DbC399F3112bac87f57018A3389911241237B',   // scircle   
    tokenAddress2: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // pi
    earnContractAddress: '0xEFeadeb2445E8e2b0c027001bA3d67b29D5a84e7', // wrapper
    earnContractAddress2: '0x98B07cD5Ca128E5b8a9D3a24f95dA8d551E25A46', // own contract
    tokenId: "1",
    price: "0.15 BNB",
    price2: "36 PI",
    pcs2: "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9"

  },

  { 
    chainId: 1,
    name: 'eCircle',
    id: 'ecircle',
    tokenAddress: '0xcF5DbC399F3112bac87f57018A3389911241237B',   //    
    tokenAddress2: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // 
    earnContractAddress: '0xEFeadeb2445E8e2b0c027001bA3d67b29D5a84e7',
    earnContractAddress2: '0x98B07cD5Ca128E5b8a9D3a24f95dA8d551E25A46',
    tokenId: "2",
    price: "",
    price2: "5 sCircle",
    pcs2: "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9"

  },

  { 
    chainId: 1,
    name: 'bitCircle',
    id: 'bitcircle',
    tokenAddress: '0xcF5DbC399F3112bac87f57018A3389911241237B',   //    
    tokenAddress2: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // 
    earnContractAddress: '0xEFeadeb2445E8e2b0c027001bA3d67b29D5a84e7',
    earnContractAddress2: '0x98B07cD5Ca128E5b8a9D3a24f95dA8d551E25A46',
    tokenId: "1",
    price: "",
    price2: "",
    pcs2: "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9"
  },
]