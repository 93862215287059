import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { byDecimals } from "features/helpers/bignumber";

import {
  useApprove,
  useBalanceOf,
  useDeposit,
  useWithdraw,
  useAllowance,
  useApproveForAll,
} from "../redux/hooks";
import { Image, Card } from "antd";

import depositimg from "../../../assets/img/deposit.svg";
import stakepiplogo from "../../../assets/img/stakepiplogo.svg";
import { useConnectWallet } from "../../home/redux/hooks";

import exitsign from "../../../assets/img/whh_exit.svg";
import Moment from "react-moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
Moment.globalLocal = true;

export default function PoolContent({ pool, tostaked }) {
  const [quant, setQuant] = useState("0");
  const styles = {
    NFTs: {
      display: "flex",
      flexWrap: "wrap",
      WebkitBoxPack: "start",
      justifyContent: "flex-start",
      margin: "0px auto 20px",
      maxWidth: "1200px",
      width: "100%",
      gap: "10px",
    },
  };
  const tokenBalance = useBalanceOf(pool.tokenAddress);
  let balanceSingle = byDecimals(tokenBalance, pool.tokenDecimals);
  const allowance = useAllowance(pool.tokenAddress, pool.earnContractAddress);
  const { networkId } = useConnectWallet();

  const { isPending: isApprovePending, onApprove } = useApprove(
    pool.tokenAddress,
    pool.earnContractAddress
  );

  const { isPending: isApprovePending2, onApprove2 } = useApproveForAll(
    pool.tokenAddress1,
    pool.earnContractAddress
  );

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    pool.earnContractAddress
  );

  const { onWithdraw, isPending: isWithdarwPending } = useWithdraw(
    pool.earnContractAddress,
    pool.tokenAddress
  );

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const balanceToDeposit = pool.payment;

  const handleClose = () => {
    setOpen(false);
  };

  const badgetostake = Object.keys(tostaked)
    .filter((key) => key.includes(pool.NFTTYPE))
    .map((NFTTYPE, i) => tostaked[NFTTYPE]);

  console.log(+badgetostake);

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className="head_prnt"
        spacing={5}
      >
        <Grid item lg={12}>
          <div className="progress_action text-right accordan_inn_btn_prnt">
            <Box className="btn_outrborder_sdw ms-auto-a">
              <button
                type="button"
                className="btn btn_def_v3"
                onClick={() => {
                  handleClose();
                  onApprove();
                }}
                disabled={isApprovePending}
              >
                <span>
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={stakepiplogo}
                    alt="logo"
                  />
                  {isApprovePending
                    ? `${"Approve..."}`
                    : `${"1. Approve PI Token"}`}
                </span>
              </button>
            </Box>
            <Box className="btn_outrborder_sdw">
              <button
                className="btn btn_def_v3"
                onClick={() => {
                  onApprove2();
                }}
                disabled={isApprovePending2}
              >
                <span>
                  {isApprovePending2
                    ? `${"Approving..."}`
                    : `2. Approve ${pool.item} ` }
                </span>
              </button>
            </Box>{" "}
          </div>
        </Grid>
        <br></br>
        <Grid item lg={12}>
          <div className="progress_action text-right accordan_inn_btn_prnt">
            <Box className="button_box">
              {Object.keys(tostaked)
                .filter((key) => key.includes(pool.NFTTYPE))
                .map((NFTTYPE, i) => (
                  <div key={i}>
                    <Box className="apr_weekly_prnt_ash">
                      <Typography component="h5">
                        Batch ID: {tostaked[NFTTYPE]}{" "}
                      </Typography>
                    </Box>
                  </div>
                ))}
              <div style={styles.NFTs}>
                {Object.keys(tostaked)
                  .filter(
                    (key) =>
                      key === pool.type1 ||
                      key === pool.type2 ||
                      key === pool.type3 ||
                      key === pool.type4 ||
                      key === pool.type5 ||
                      key === pool.type6
                  )
                  .map((NFTTYPE, i) => (
                    <>
                      <Box className="img_main_box">
                        <Box className="img_main_box_innr">


                        {networkId === 56 ? (

                          <Card
                            className="whit_brdr_non"
                            hoverable
                            style={{
                              width: 190,
                              border: "2px solid #e7eaf3",
                            }}
                            cover={
                              <Image
                                preview={false}
                                src={require(`../../../images/compress/${NFTTYPE}.png`)}
                                alt=""
                                style={{ height: "190px" }}
                              />
                            }
                            key={i}
                          >
                            <Typography component="h5">
                              NFT ID: {tostaked[NFTTYPE]}
                            </Typography>
                            <Typography component="h4">
                              NFT Type: {NFTTYPE}
                            </Typography>
                          </Card>

                          ) : networkId === 1 ? (
                            <Card
                            className="whit_brdr_non"
                            hoverable
                            style={{
                              width: 190,
                              border: "2px solid #e7eaf3",
                            }}
                            cover={
                              <Image
                                preview={false}
                                src={require(`../../../images/compressp/${NFTTYPE}.png`)}
                                alt=""
                                style={{ width: "190px" }}
                              />
                            }
                            key={i}
                          >
                            <Typography component="h5">
                              NFT ID: {tostaked[NFTTYPE]}
                            </Typography>
                            <Typography component="h4">
                              NFT Type: {NFTTYPE}
                            </Typography>
                          </Card>

                            ) : ( <> </>) }
                        </Box>
                      </Box>
                    </>
                  ))}
              </div>
              <form className="saleForm">
                <label htmlFor="id" className="labelform">
                  {" "}
                  3. Enter the NFT ID you want to stake:{" "}
                </label>
                <input
                  type="text"
                  value={quant}
                  onChange={(e) => setQuant(e.target.value)}
                  required
                />
              </form>
            </Box>
          </div>
        </Grid>
        <Grid item lg={12}>
          <div className="progress_action text-right accordan_inn_btn_prnt">
            <Box className="btn_outrborder_sdw btn_outrborder_sdw_v2">
              <button
                className="btn btn_def_v3"
                onFocus={(event) => event.stopPropagation()}
                disabled={
                  isDepositPending || tokenBalance === "0" || quant === "0"
                  //    quant2 === "0"
                }
                onClick={(event) => {
                  handleClickOpen();
                }}
              >
                {" "}
                <span>{"4. Stake"}</span>
              </button>
            </Box>
            <Box className="btn_outrborder_sdw btn_outrborder_sdw_v2">
              <button
                type="button"
                className="btn btn_def btn_width_160"
                disabled={isWithdarwPending}
                onClick={() => onWithdraw()}
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="20"
                  height="auto"
                  background-color="#000000"
                  src={exitsign}
                  alt="logo"
                />
                {isWithdarwPending ? `${"UNSTAKE"}` : `${"UNSTAKE"}`}
              </button>
            </Box>
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="aprvmdl"
      >
        <DialogTitle id="alert-dialog-title">{"Note"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <li> Depositing will reset the Timelock Countdown. </li>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="progress_action d-flex w-100 mt-3 justify-content-center">
            {allowance !== "0" && (
              <div className="progress_action text-right mt-3">
                <button
                  className="btn btn_def"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={
                    isDepositPending || balanceSingle < balanceToDeposit
                  }
                  onClick={() => {
                    onDeposit(balanceToDeposit, +badgetostake, quant);
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {"Deposit"}
                </button>
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
