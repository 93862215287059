import React from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

function SevenSection() {
  return (
    <>
      <Box className="sc07_prnt">
        <Box
          component="img"
          src="/static/img/sc07_text_bg.png"
          className="sc07_text_bg"
        />
        <Container className="container inn_sc07_prnt_cntant">
          <Grid container spacing={0}>
            <Grid item xs={12} lg={10}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} className="sc02_rght sc02_rght_sa_v2">
                  <Typography component="h2" className="def_ttl mb-3">
                    <span>
                      P<b className="i_small">!</b>PX
                    </span>{" "}
                    Vaults
                  </Typography>

                  <Box className="buy_pi_arrow_btn_prnt">
                    <Link to="/pipx" className="buy_pi_arrow_btn">
                      <span>Vaults</span>
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} className="text-center">
                  <Box className="nft_img_video_prnt">
                    <Box
                      component="img"
                      src="/static/img/nftvlt_img.png"
                      className="nftvlt_img"
                    />
                    <Box
                      component="img"
                      src="/static/img/pi_trancprnt_img.png"
                      className="pi_trancprnt_img"
                    />
                    <Box className="sc07_bg_video_prnt_02">
                      <video muted autoPlay loop playsinline className="sc07_bg_video">
                        <source src="sc07_bg_video.mp4" type="video/mp4" />
                      </video>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        {/* <CoverImgStyle alt="cover" src={"/static/img/new_sc_bg.jpg"} /> */}
      </Box>
    </>
  );
}

export default SevenSection;
