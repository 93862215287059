import React from "react";
import { useFetchPoolBalances } from "../redux/hooks";
import PoolContent from "./PoolContent";
import PoolContent2 from "./PoolContent2";
//import PoolContent3 from "./PoolContent3";

import { useConnectWallet } from '../../home/redux/hooks';

function CircleBoxes8() {
  let { launchpadpools } = useFetchPoolBalances();
  const { networkId } = useConnectWallet();

  return ( 
    <>
      {launchpadpools.filter(pool => (pool.chainId === 56  && networkId === 56 )).map((npool, index) => (
        <PoolContent key={index} npool={npool} />
      ))}
      {launchpadpools.filter(pool => (pool.chainId === 1  && networkId === 1 )).map((npool, index) => (
        <PoolContent2 key={index} npool={npool} />
      ))}
{/*       {launchpadpools.filter(pool => (pool.chainId === 1  && networkId === 1 )).map((npool, index) => (
        <PoolContent3 key={index} npool={npool} />
      ))} */}
    </>
  );
}

export default CircleBoxes8;
