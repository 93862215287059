import React, {useState} from 'react'
import {Box, Typography, DialogContent, IconButton, Button   } from '@material-ui/core';


function PoolsTopDetails() {
    //const [open, setOpen] = React.useState(false);

/*     const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }; */
    const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
    return (
        <>
        <Button onClick={toggleClass} className="guid_button_prnt">
            <img src='/img/guid_button.svg' alt='' className='guid_button' />
        </Button>
        {/* <Box className='cllctns_prnt'>
            <Container max-width="lg" >
                <Grid container spacing={0} className='justify-content-center'>
                    <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                        <Box className='cllctns_bx cllctns_bx_02'>
                            <Grid container spacing={1} alignItems='center'>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg='6'
                                className='tp_clcltbxinfo'
                            >
                                <Box className='voult_nfts_img_prnt'>
                                <Box
                                    component='img'
                                    src='/static/icons/pls_pg_ic.svg'
                                    height='62px'
                                    width='78px'
                                    className='info_ic'
                                />
                                <Box>
                                    <Typography className='def_p mb-3'>
                                    
                                    </Typography>
                                    <Typography
                                    component='h3'
                                    className='def_ttl_sm mb-3'
                                    >
                                    Stake Circle NFTs & Earn
                                    </Typography>
                                    <Typography className='def_p mb-0'></Typography>
                                </Box>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg='6'
                                className='tp_clcltbxinfo'
                            >
                                <Box className='voult_nfts_img_prnt'>
                                <Box
                                    component='img'
                                    src='/static/icons/nft_ic_blwt.svg'
                                    height='50px'
                                    width='50px'
                                    className='info_ic'
                                />
                                <Typography
                                    component='h3'
                                    className='def_ttl_sm mb-2 mr-2'
                                >
                                    Series 1
                                </Typography>
                              
                                <Box className='voult_brdr_inn' >
                                    <Box className='inn_img'>
                                    <Box
                                        component='img'
                                        src='/static/img/voult_popup_img.svg'
                                        alt=''
                                    />
                                    </Box>
                                    <Box className='voult_brdr_ph'>
                                    <Typography component='h4' className=''>
                                        NFT staking 
                                        <br />
                                        USER GUIDE
                                    </Typography>
                                    <Typography component='h6' className=''>
                                        please allow browser popups
                                    </Typography>
                                    </Box>
                                </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                <Grid item xs={12} lg={6}>
                                    <Box className='plmnofclcllctn plmnofclcllctn_v3 nft_vault_lable'>
                                    <Typography component='label' className=''>
                                        <Box className="ellipscss">
                                        <Box
                                        component='img'
                                        src='/static/icons/gm_icon.svg'
                                        height='30px'
                                        width='30px'
                                        />
                                        <span className=''>Pi Token Contract:</span>{' '}
                                        <b className='mr-2'>
                                        {' '}
                                        0x25C30340e6f9f6e521827cF03282943dA00c0ECE
                                        </b>
                                        </Box>
                                        <CopyIcon className='crsrpntr' />
                                    </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Box className='plmnofclcllctn nft_vault_lable_02'>
                                    <Typography
                                        component='label'
                                        className='pkmn_lft pkmn_lft_v2 '
                                    >
                                        <span className='w_300 mr-1'>
                                        Check Quality Assurance:
                                        </span>
                                        <a
                                        href='https://www.pi-protocol.io/gfx/PI_AUDIT.pdf'
                                        rel="nofollow noopener noreferrer"
                                        target='_blank'
                                        className='c_primary w_300 mr-1'
                                        >
                                        {' '}
                                        -Pi Audit & -
                                        </a>
                                        <a
                                        href='https://pi-protocol.io/gfx/Pi_staking_audit.pdf'
                                        rel="nofollow noopener noreferrer"
                                        target='_blank'
                                        className='c_primary w_300 mr-1'
                                        >
                                        {' '}
                                        Staking Audit
                                        </a>
                                    </Typography>
                                    </Box>
                                </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Box>
                        <Grid item xs={12} lg={12} className='text-center '>
                            <Typography
                            component='h3'
                            className='voult_nft_center_heder'
                            >
                            Circle Staking Pools
                            </Typography>
                        </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
                </Container>
            </Box> */}
            <Box
                className={isActive ? 'user_modal user_modal200 show': 'user_modal user_modal200'}
                // className="user_modal pop_up_dilog"
            >
                <Box component='img' src='/img/top_guid.png' className="guid_top_img" />
                <DialogContent>
                <Box className="ur_mdl_ttl">
                    <Box className="ur_mdl_ttl_inn ur_mdl_ttl_inn_01">
                    {/* <Box component='img' src='/static/icons/gm_icon.svg' /> */}
                    <Typography component="h4" className='stk_pop_up_h4'>NFT staking USER GUIDE</Typography>
                    {/* <img
                        className="mx-auto"
                        width="25"
                        height="auto"
                        background-color="#000000"
                        src={stakin_popup_ic}
                        alt="logo"
                    /> */}
                    </Box>
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={toggleClass}>
                        <Box component='img' src='/img/back_icon.svg' />
                    </IconButton>
                </Box>
                <Box className="user_m_cntent">
                    <Typography>1) Connect your wallet.</Typography>
                    <Typography>2) Choose a pool for circle staking. </Typography>
                    <Typography>3) Approve interaction with the pool (press <span className="clr_yellow">Approve</span> button).</Typography>
                    <Typography>4) Confirm approval in wallet (wait for confirmation).</Typography>
                    <Typography>5) Specify amount to stake</Typography>
                    <Typography>6) Stake into pool (press <span className="clr_yellow">Stake NFT</span> button).</Typography>
                    <Typography>7)	Confirm deposit in wallet (wait for confirmation). </Typography>
                    <Typography>8) Earn tasty <span className="clr_green">rewards</span>.</Typography>
                    <Typography>9) Press <span className="clr_yellow">Claim</span> button to claim PIP token rewards.</Typography>
                    <Typography>10) To unstake and exit pool simply press <span className="clr_yellow">Unstake</span> button.</Typography>
                    <Typography>11) Buy <span className="clr_pink">flowers</span> for your mom or sweetie with staking rewards!</Typography>
                </Box>
                </DialogContent>
                <Box className='pop_mddl_bg' />
{/*                 <Box component='img' src='/img/popup_botm_img.svg' className="w-100 pb-1 ppbtm_img" />
 */}                <Box component='img' src='/img/bottom_guid.png' className="guid_bottom_img" />     
            </Box>
        </>
    )
}

export default PoolsTopDetails
