import React from "react";
import { useConnectWallet } from "../../home/redux/hooks";
import { useFetchPoolBalances } from "../redux/hooks";
//import Gallery from "./Gallery";
import PoolContent from "./PoolContent";
import { Box } from "@material-ui/core";
//import MetaverseTitle2 from "./MetaverseTitle2";
//import GalleryETH from "./GalleryETH";

function CircleBoxes() {
  const { networkId } = useConnectWallet();
  let { lootboxpools } = useFetchPoolBalances();

  return (
    <>
      {lootboxpools
        .filter((npool) => npool.chainId === networkId)
        .map((npool, index) => (
          <PoolContent index={index} npool={npool} />
        ))}
      <Box className=" footer_spaceing circle_page_bg">
{/*         <Grid container spacing={0}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <MetaverseTitle2 />
          </Grid>
          {networkId === 56 ? (
            <>
              <Grid item xs={12} key={1}>
                <Gallery index={1} />
              </Grid>
            </>
          ) : (
            <></>
          )}

          {networkId === 1 ? (
            <>
              <Grid item xs={12} key={2}>
                <GalleryETH index={2} />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid> */}
        {/* <CoverImgStyle alt="cover" src={"/static/img/nftcrcl_bg.jpg"} /> */}
      </Box>
    </>
  );
}

export default CircleBoxes;
