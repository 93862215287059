
export const phoenixbreed = [

  { 
    chainId: 56,
    id: '1',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Phoenix Training Badge, Class 1',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenDecimals: 18,
    earnedToken: 'C1 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0x84EEAC7Ca9C3550d3c1Cc221DeC65e434D6B050C',  // c
    pricePerFullShare: 1,
    timelock: "3 Days",
    status: 'aktive',
    ended: "no",
    payment: 150
  },
  { 
    chainId: 56,
    id: '2',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Phoenix Training Badge, Class 2',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenDecimals: 18,
    earnedToken: 'C2 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0xfAF9f267bD8F55Ed9e0F8f73988F8EB405898724',  // c
    pricePerFullShare: 1,
    timelock: "7 Days",
    status: 'aktive',
    ended: "no",
    payment: 200
  },
  { 
    chainId: 56,
    id: '3',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Phoenix Training Badge, Class 3',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenDecimals: 18,
    earnedToken: 'C3 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0xdc7da8758966206Aae60395e1b0E1c1E3de4Ef89',  // c
    pricePerFullShare: 1,
    timelock: "9 Days",
    status: 'aktive',
    ended: "no",
    payment: 250
  },
  { 
    chainId: 56,
    id: '4',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Phoenix Training Badge, Class 4',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenDecimals: 18,
    earnedToken: 'C4 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0x87a27d310038Fa3537cf7264F6C2821aCc21b184',  // c
    pricePerFullShare: 1,
    timelock: "11 Days",
    status: 'aktive',
    ended: "no",
    payment: 300
  },
  { 
    chainId: 56,
    id: '5',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Phoenix Training Badge, Class 5',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenDecimals: 18,
    earnedToken: 'C5 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0x619B54D0F6d4a51722A50BECDC4823aC2E66277B',  // c
    pricePerFullShare: 1,
    timelock: "13 Days",
    status: 'aktive',
    ended: "no",
    payment: 350
  },
  { 
    chainId: 56,
    id: '6',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Phoenix Training Badge, Class 6',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenDecimals: 18,
    earnedToken: 'C6 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0xa1C653882B8e4f292f6647a52eaF0Cb8BbB63EcB',  // c
    pricePerFullShare: 1,
    timelock: "15 Days",
    status: 'aktive',
    ended: "no",
    payment: 400
  },
  { 
    chainId: 56,
    id: '7',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Phoenix Training Badge, Class 7',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenDecimals: 18,
    earnedToken: 'C7 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0x44B327AE38900E03498Bf39f5a5AfB395687a903',  // c
    pricePerFullShare: 1,
    timelock: "20 Days",
    status: 'aktive',
    ended: "no",
    payment: 450
  },

  //// ETH

  { 
    chainId: 1,
    id: '1',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Panther Training Badge, Class 1',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenDecimals: 18,
    earnedToken: 'C1 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x4a149d1a7F7c2f3065c4bA7A2Fff8A47Dd86FbCD',  // cc
    pricePerFullShare: 1,
    timelock: "3 Days",
    status: 'aktive',
    ended: "no",
    payment: 150
  },
  { 
    chainId: 1,
    id: '2',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Panther Training Badge, Class 2',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenDecimals: 18,
    earnedToken: 'C2 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x52f34C3BB03B489920664D717e0D5Ba84A208be1',  // cc
    pricePerFullShare: 1,
    timelock: "7 Days",
    status: 'aktive',
    ended: "no",
    payment: 200
  },
  { 
    chainId: 1,
    id: '3',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Panther Training Badge, Class 3',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenDecimals: 18,
    earnedToken: 'C3 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0xbc58f709A727177C23685D5278EE4163e5a94f95',  // cc
    pricePerFullShare: 1,
    timelock: "9 Days",
    status: 'aktive',
    ended: "no",
    payment: 250
  },
  { 
    chainId: 1,
    id: '4',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Panther Training Badge, Class 4',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenDecimals: 18,
    earnedToken: 'C4 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x7889b4e46Edc5848B62536eba852A191DD5F42C0',  // cc
    pricePerFullShare: 1,
    timelock: "11 Days",
    status: 'aktive',
    ended: "no",
    payment: 300
  },
  { 
    chainId: 1,
    id: '5',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Panther Training Badge, Class 5',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenDecimals: 18,
    earnedToken: 'C5 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0xA0856105C1ccAb9503799E05472F4AdA6339d025',  // cc
    pricePerFullShare: 1,
    timelock: "13 Days",
    status: 'aktive',
    ended: "no",
    payment: 350
  },
  { 
    chainId: 1,
    id: '6',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Panther Training Badge, Class 6',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenDecimals: 18,
    earnedToken: 'C6 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x7c69a9eaA4Ac0439aBD9e3D3BAa6B4e996fC0681',  // cc
    pricePerFullShare: 1,
    timelock: "15 Days",
    status: 'aktive',
    ended: "no",
    payment: 400
  },
  { 
    chainId: 1,
    id: '7',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'Panther Training Badge, Class 7',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenDecimals: 18,
    earnedToken: 'C7 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x478464aF62bd6C46307310717e87108827B848bF',  // cc
    pricePerFullShare: 1,
    timelock: "20 Days",
    status: 'aktive',
    ended: "no",
    payment: 450
  },
 ]
 