import React from "react";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";

function Cs() {
  return (
    <>
      <Box className="pd-20">
      <Link to="/" className="vttl_v3">
          <img src="/img/ttl_top.png" className="vttl_v3_tp_mi" alt="" />
          <Box className="invlt_ttl_v3">
            Coming <span>Soon</span>
          </Box>
          <Box className="vttl_v3_mddl_mi" />
          <img src="/img/ttl_bottom.png" className="vttl_v3_btm_mi" alt="" />
        </Link>
      </Box>
    </>
  );
}

export default Cs;
