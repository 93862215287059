import React from 'react'
import {Container, Grid, Box, Typography } from '@material-ui/core';


function MetaverseTitle() {
    return (
        <>
            <Box className="nft_ttl_prnt">
                <Box className=""></Box>
                <Container max-width="lg">
                    <Grid container spacing={0} className="justify-content-center">
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography component="h2" className="def_ttl_v3">
                                        <span>P<b>!</b></span> LootBox
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default MetaverseTitle
