import React, { useState, useRef, useEffect, useCallback } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { useConnectWallet } from "../../home/redux/hooks";
import axios from "axios";
import {
  fetchTokens,
  useRange1,
  useRange2,
  useApproveForAll,
  useDeposit,
  useWithdraw,
  useBoost,
  useIsApproved,
} from "../redux/hooks";
import { NFTUPGABI, FACABI } from "features/configure";
import { CardTitle, CardBody } from "reactstrap";
import Countdown from "react-countdown";
import Moment from "react-moment";
import "moment-timezone";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Rings } from "react-loader-spinner";
import * as Enumerable from "linq-es2015";

Moment.globalLocal = true;

function CircleBoxes() {
  const { networkId } = useConnectWallet();
  const [fetchingNFT, setFetchingNFT] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [fetchingx, setFetchingx] = useState(true);
  const { address, web3 } = useConnectWallet();
  const [fetchedIDs, setFetchedIDs] = useState(true);
  const [mytypes, setMyTypes] = useState([]);
  const [holdings, setHolding] = useState([]);
  const [holdings2, setHolding2] = useState([]);
  const [holdings3, setHolding3] = useState([]);
  const [infos, setInfos] = useState([]);
  const [infos1, setInfos1] = useState([]);
  const [infos2, setInfos2] = useState([]);
  const [infos3, setInfos3] = useState([]);

  const [checked, setChecked] = useState([]);
  const [tobefetched, setTobefetched] = useState(true);
  const [fetchingy, setFetchingy] = useState(true);

  const tokenAddress = "0x8543e7728b35794502f1f14B26eb818ccfb83a29";
  const contract = "0x9873a0beb16ee9e2ccf6d11a8da9aeeae0d1c0a8";

  const { isPending: isApprovePending, onApprove } = useApproveForAll(
    tokenAddress,
    contract
  );

  const { onDeposit, isPending: isDepositPending } = useDeposit(tokenAddress);

  const { onWithdraw, isPending: isWithdarwPending } =
    useWithdraw(tokenAddress);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const isApproved = useIsApproved(contract, tokenAddress);

  const handleClose = () => {
    setOpen(false);
    setFetchingy(true);
    setFetching(true);
    setFetchingx(true);
  };
  let tokenAddress1;
  if (networkId === 56) {
    tokenAddress1 = "0x9873a0beb16ee9e2ccf6d11a8da9aeeae0d1c0a8";
  } else if (networkId === 1) {
    tokenAddress1 = "0x9873a0beb16ee9e2ccf6d11a8da9aeeae0d1c0a8";
  }

  const fetchNFTs = async (address) => {
    const cancelTokenSource = axios.CancelToken.source();
    setFetching(true);
    try {
      const { data } = await fetchTokens(
        0,
        5000,
        "single",
        [tokenAddress1],
        null,
        "id",
        [],
        address,
        cancelTokenSource.token
      );

      tokens.current = [...tokens.current, ...data.tokens];

      setFetching(false);
    } catch {
      setFetching(false);
    }
  };

  const tokens = useRef([]);

  const filtered = tokens.current.filter(
    (t) => t.owner === address.toLowerCase()
  );

  const filtered3 = [...filtered.map(({ tokenID }) => tokenID)];

  const fetchOwner = useCallback(async () => {
    let newowner = [];
    try {
      const contract = new web3.eth.Contract(
        NFTUPGABI,
        "0x9873a0beb16ee9e2ccf6d11a8da9aeeae0d1c0a8"
      );
      newowner = await contract.methods
        .getNftTypesForTokenIDs(filtered3)
        .call({ from: address });

      setMyTypes(newowner);
      setFetchingx(false);
    } catch (error) {
      console.log("failed");
    }
  }, [filtered3, web3, address]);

  const fetchInfos = useCallback(async () => {
    try {
      const contract = new web3.eth.Contract(FACABI, tokenAddress);
      const infosx = await contract.methods
        .getFactoryInfos(filtered3)
        .call({ from: address });

      setInfos(infosx[0]);
      setInfos1(infosx[1]);
      setInfos2(infosx[2]);
      setFetchingy(false);

      const infosxy = await contract.methods
        .getClaimableAmounts(filtered3)
        .call({ from: address });

      setInfos3(infosxy);
    } catch (error) {
      console.log("failed");
    }
  }, [filtered3, web3, address]);

  const getHoldings = async () => {
    let res = filtered;
    const item = res;

    const items = await Promise.all(
      item.map(async (item, idx) => {
        try {
          const data = mytypes;
          return { ...item, Types: data[idx] };
        } catch {
          return item;
        }
      })
    );
    setHolding(items);

    const items2 = await Promise.all(
      item.map(async (item, idx) => {
        try {
          const data = mytypes;
          return {
            tokenId: item.tokenID,
            nftType: +data[idx],
            tokenId2: item.tokenID,
          };
        } catch {
          return item;
        }
      })
    );
    setHolding2(items2);
  };

  useEffect(() => {
    if (web3 && address && fetchingNFT === true) {
      fetchNFTs(address);
      setFetchingNFT(false);
    }
    if (web3 && address && filtered3 > [] && fetchedIDs === true) {
      fetchOwner();
      setFetchedIDs(false);
    }

    if (web3 && address && mytypes.length > 0 && fetchingx === true) {
      getHoldings();
      setFetchingx(false);
      fetchInfos();
      setFetchingy(false);
      getFilter();
    }

    if (web3 && address && holdings.length > 0) {
      getFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    web3,
    address,
    fetchingNFT,
    filtered3,
    fetchedIDs,
    mytypes,
    fetchingx,
    fetchingy,
  ]);

  const [open, setOpen] = useState(false);

  const handleCheck = (event) => {
    var updatedList = [];
    setChecked([]);
    if (event.target.checked) {
      updatedList = [event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    if (event.target.checked) {
      updatedList = [event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const range1 = useRange1(tokenAddress);
  const range2 = useRange2(tokenAddress);
  const boost = useBoost(tokenAddress);
  //const claimable = useClaim(tokenAddress, filtered3)

  const RangeFrom = Number(range1);
  const RangeTo = Number(range2);

  const obli1 = [5006, 5207, 5408, 5609, 5810];
  let [shard, setShard] = useState("none");

  let handleShardChange = (e) => {
    setShard(e.target.value);
  };

  const Completionist = () => <span>Completed!</span>;
  const Completionist1 = () => <span>BOOST!</span>;
  const Completionist2 = () => <span>START!</span>;

  const renderer = ({ completed }) => {
    if (completed) {
      return <Completionist2 />;
    } else {
      return <Completionist1 />;
    }
  };

  const getFilter = () => {
    let filteredHoldings = [];
    if (holdings2.length > 0 && tobefetched === true) {
      for (var x = 0; x < obli1.length; x++) {
        let thisShard = Enumerable.asEnumerable(holdings2).FirstOrDefault(
          // eslint-disable-next-line no-loop-func
          (y) => +y.nftType === +obli1[x]
        );
        if (thisShard !== undefined) {
          filteredHoldings.push(thisShard);
          setHolding3(filteredHoldings);
          setTobefetched(false);
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={0} className="justify-content-center">
        <Grid item xs={12} lg={7} className="cstm_lg_12">
          <Grid container spacing={3}>
            {fetching && fetchingx && fetchingy && (
              <div className="loadingContainer">
                <Rings
                  type="ThreeDots"
                  color="#FFFFFF"
                  height={260}
                  width={260}
                  className="loader"
                />
              </div>
            )}

            {(holdings || []).map((item, idx) => (
              <>
                {+item.Types >= RangeFrom && +item.Types <= RangeTo && (
                  <>
                    <Grid
                      item
                      xs={9}
                      sm={6}
                      md={3}
                      index={idx}
                      className="rspnsv_12"
                    >
                      <Box className="crcl_actlbx checkBoxMain">
                        <label>
                          <Box className="crclr_tp_txt_as">
                            <p>Select here</p>
                          </Box>
                          <input
                            value={item.tokenID}
                            type="checkbox"
                            //className="check_box"
                            onChange={handleCheck}
                          />
                        </label>
                        {/* 
                        <Box className="crclr_tp_txt_as">
                          <p>Select here</p>
                        </Box> */}

                        <Box className="crclr_tp_txt_as">
                          <img
                            className="img-fluid"
                            alt=""
                            src={item.imageURL}
                          />
                        </Box>

                        <CardBody className="card_body_as_v4">
                          <CardTitle tag="h4" className="text-center">
                            <Box className="nwprgrss2">
                              <Typography
                                component="p"
                                className="back_bahama_p "
                              >
                                <span> Finished in:</span>{" "}
                                {infos ? (
                                  <>
                                    {infos[idx] && (
                                      <Countdown date={infos[idx] * 1000}>
                                        <Completionist />
                                      </Countdown>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Typography>

                              <Typography
                                component="p"
                                className="back_bahama_p "
                              >
                                <span> BOOSTS: </span>{" "}
                                {infos ? (
                                  <>
                                    {infos2[idx]} ({" "}
                                    {(infos2[idx] * boost) / 100} %)
                                  </>
                                ) : (
                                  <> </>
                                )}
                              </Typography>

                              <Typography
                                component="p"
                                className="back_bahama_p "
                              >
                                <span> TOTAL PRODUCTION </span>{" "}
                                {infos ? <>{infos1[idx]}</> : <></>}
                              </Typography>
                              <br></br>
                              <div className="progress_action">
                                <Box className="btn_outrborder_sdw accordan_inn_btn_prnt btnprntv3">
                                  <button
                                    type="button"
                                    className="btn btn_def_v3 btn_def_v3_v2"
                                    onFocus={(event) => event.stopPropagation()}
                                    disabled={
                                      checked.length === 0 ||
                                      checked.length >= 2 ||
                                      checked.toString() !==
                                        item?.tokenID.toString()
                                    }
                                    onClick={(event) => {
                                      handleClickOpen();
                                    }}
                                  >
                                    {" "}
                                    {infos[idx] && (
                                      <Countdown
                                        date={infos[idx] * 1000}
                                        renderer={renderer}
                                      >
                                        <Completionist2 />
                                      </Countdown>
                                    )}
                                  </button>

                                  <div className="space-20"></div>
                                  {infos3[idx] !== "0" ? (
                                    <button
                                      type="button"
                                      className="btn btn_def_v3 btn_def_v3_v2"
                                      onFocus={(event) =>
                                        event.stopPropagation()
                                      }
                                      disabled={
                                        checked.length === 0 ||
                                        checked.length >= 2 ||
                                        checked.toString() !==
                                          item?.tokenID.toString() ||
                                        isWithdarwPending
                                      }
                                      onClick={() => onWithdraw(item.tokenID)}
                                    >
                                      <span>{"CLAIM"}</span>
                                    </button>
                                  ) : (
                                    <></>
                                  )}

                                  <div className="space-20"></div>
                                </Box>
                              </div>
                            </Box>
                          </CardTitle>
                        </CardBody>
                      </Box>
                    </Grid>
                  </>
                )}
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="aprvmdl"
      >
        <DialogTitle id="alert-dialog-title">{"Your Shards"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select your Powershard:
            <br></br>
            <br></br>
            <select onChange={handleShardChange}>
              <option>Select a Shard from your Inventory:</option>
              {holdings3.map((item) => (
                <option value={item.tokenId2}>
                  {item.nftType === 5006 && "Common (+10%)"}
                  {item.nftType === 5207 && "Uncommon (+20%)"}
                  {item.nftType === 5408 && "Rare + (+40%)"}
                  {item.nftType === 5609 && "Epic +(50% + 1PP)"}
                  {item.nftType === 5810 && "Legendary +(50% + 2PP)"}
                </option>
              ))}
            </select>
            <br></br>
            <br></br>
            You selected: <br></br>
            Token ID: {shard}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="progress_action d-flex w-100 mt-3 justify-content-center">
            <div className="progress_action text-right mt-3">
              {isApproved !== true ? (
                <>
                  <button
                    className="btn btn_def"
                    onFocus={(event) => event.stopPropagation()}
                    onClick={() => {
                      onApprove();
                    }}
                    disabled={isApprovePending}
                  >
                    <span>
                      {isApprovePending ? `${"Approving..."}` : `Approve`}
                    </span>
                  </button>{" "}
                </>
              ) : (
                <>
                  <button
                    className="btn btn_def"
                    onFocus={(event) => event.stopPropagation()}
                    onClick={(event) => {
                      onDeposit(checked.toString(), shard);
                    }}
                    disabled={isDepositPending}
                  >
                    <span>
                      {isDepositPending ? `${"Executing..."}` : `Execute`}
                    </span>
                  </button>{" "}
                </>
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CircleBoxes;
