import React, { useCallback, useEffect, useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import {
  useDeposit,
  useWithdraw,
  useApproveForAll,
  useDepositP,
  usePoints,
  useFetchGetReward,
  useBalanceNFTOf,
  useWithdrawSingle,
} from "../redux/hooks";
import { Image, Card } from "antd";
import exitsign from "../../../assets/img/whh_exit.svg";
import Moment from "react-moment";
import { useConnectWallet } from "../../home/redux/hooks";
import { erc721P, lootAbi } from "features/configure/abi";

Moment.globalLocal = true;

export default function PoolContent({ sum, pool, filtered3, tostaked }) {
  const styles = {
    NFTs: {
      display: "flex",
      flexWrap: "wrap",
      WebkitBoxPack: "start",
      justifyContent: "flex-start",
      margin: "0px auto 20px",
      maxWidth: "1200px",
      width: "100%",
      gap: "10px",
    },
  };
  const { address, web3, networkId } = useConnectWallet();
  const [owner, setOwner] = useState([]);
  const [owner2, setOwner2] = useState([]);
  const [fetchedIDs, setFetchedIDs] = useState(true);
  const [fetchingNFT, setFetchingNFT] = useState(true);

  const [quant, setQuant] = useState("0");

  const { isPending: isApprovePending2, onApprove2 } = useApproveForAll(
    pool.tokenAddress,
    pool.earnContractAddress
  );

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    pool.earnContractAddress,
    pool.stakingclass
  );

  const { onDepositP, isPending: isDepositPendingP } = useDepositP(
    pool.earnContractAddress,
    pool.tokenAddress
  );

  const { onWithdraw, isPending: isWithdarwPending } = useWithdraw(
    pool.earnContractAddress,
    pool.tokenAddress
  );

  const { onWithdrawSingle, isPending: isWithdrawSinglePending } =
    useWithdrawSingle(pool.earnContractAddress, pool.tokenAddress);

  const { onGetReward, isPending: isGetRewardPending } = useFetchGetReward(
    pool.earnContractAddress
  );

  const points = usePoints(pool.BLL, filtered3);

  const firstPool = pool.firstPool;
  const tokenAddress1 = pool.tokenAddress1;

  const depositedBalance = useBalanceNFTOf(
    pool.earnContractAddress,
    pool.tokenId
  );

  const fetchOwner = useCallback(async () => {
    const contract = new web3.eth.Contract(erc721P, firstPool);
    const owner = await contract.methods
      .tokenIdsStaked(address)
      .call({ from: address });
    setOwner(owner);
  }, [web3, firstPool, address]);

  const fetchOwner2 = useCallback(async () => {
    const contract = new web3.eth.Contract(lootAbi, tokenAddress1);
    const owner2 = await contract.methods
      .getNFTTypesForTokenIDs(owner)
      .call({ from: address });
    setOwner2(owner2);
  }, [web3, tokenAddress1, owner, address]);

  useEffect(() => {
    if (web3 && address && depositedBalance > 0 && fetchingNFT === true) {
      fetchOwner();
      setFetchingNFT(false);
    }
    if (
      web3 &&
      address &&
      owner > [] &&
      fetchedIDs === true &&
      fetchingNFT === false
    ) {
      fetchOwner2();
      setFetchedIDs(false);
    }
  }, [
    web3,
    address,
    filtered3,
    fetchOwner,
    fetchOwner2,
    depositedBalance,
    fetchedIDs,
    owner,
    fetchingNFT,
  ]);


  const newDeposit = Object.keys(tostaked)
    .filter(
      (key) =>
        key === pool.type1 ||
        key === pool.type2 ||
        key === pool.type3 ||
        key === pool.type4 ||
        key === pool.type5 ||
        key === pool.type6
    )
    .map((NFTTYPE, i) => tostaked[NFTTYPE]);

  const tostaked2 = owner.reduce((accumulator, element, index) => {
    return { ...accumulator, [element]: owner2[index] };
  }, {});

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className="head_prnt"
        spacing={5}
      >
        <Grid item lg={12}>
          <div className="progress_action text-right accordan_inn_btn_prnt">
            <Box className="btn_outrborder_sdw">
              <button
                className="btn btn_def_v3 btn_def_v3_v2"
                onClick={() => {
                  onApprove2();
                }}
                disabled={isApprovePending2 || sum > 0}
              >
                <span>
                  {isApprovePending2
                    ? `${"Approving..."}`
                    : `1. Approve All ${pool.tokenDescription}`}
                </span>
              </button>
            </Box>{" "}
            {pool.id === "20" ? (
              <Box className="btn_outrborder_sdw">
                <button
                  className="btn btn_def_v3 btn_def_v3_v2"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={
                    isDepositPendingP || filtered3.length === 0 || sum > 0
                  }
                  onClick={(event) => {
                    onDepositP(filtered3);
                  }}
                >
                  {" "}
                  <span>{"2. Stake ALL"}</span>
                </button>
              </Box>
            ) : (
              <Box className="btn_outrborder_sdw">
                <button
                  className="btn btn_def_v3 btn_def_v3_v2"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={
                    isDepositPending || newDeposit.length < 6 || sum > 0
                  }
                  onClick={(event) => {
                    onDeposit(newDeposit);
                  }}
                >
                  {" "}
                  <span>{"2. Stake"}</span>
                </button>
              </Box>
            )}
            <Box className="btn_outrborder_sdw ms-auto-a">
              <button
                type="button"
                className="btn btn_def btn_width_160"
                disabled={isWithdarwPending}
                onClick={() => onWithdraw()}
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="20"
                  height="auto"
                  background-color="#000000"
                  src={exitsign}
                  alt="logo"
                />
                {isWithdarwPending ? `${"UNSTAKE ALL"}` : `${"UNSTAKE ALL"}`}
              </button>
            </Box>{" "}
            <Box className="btn_outrborder_sdw ms-auto-a">
              <button
                type="button"
                className="btn btn_def btn_width_160"
                disabled={isGetRewardPending}
                onClick={() => onGetReward()}
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="20"
                  height="auto"
                  background-color="#000000"
                  src={exitsign}
                  alt="logo"
                />
                {isWithdarwPending ? `${"CLAIM"}` : `${"CLAIM"}`}
              </button>
            </Box>
          </div>
          <br></br>
          {sum > 0 ? (
            <Typography component="h5">
              Staking disabled, you have NFTs listed on PI Marketplace!! Please
              unlist first!
            </Typography>
          ) : (
            <></>
          )}
        </Grid>

        {pool.id !== "20" ? (
          <Grid item lg={12}>
            <div className="progress_action text-right accordan_inn_btn_prnt">
              <Box className="button_box">
                <div style={styles.NFTs}>
                  {Object.keys(tostaked)
                    .filter(
                      (key) =>
                        key === pool.type1 ||
                        key === pool.type2 ||
                        key === pool.type3 ||
                        key === pool.type4 ||
                        key === pool.type5 ||
                        key === pool.type6
                    )
                    .map((NFTTYPE, i) => (
                      <>
                        <Box className="img_main_box">
                          <Box className="img_main_box_innr">
                            {networkId === 56 ? (
                              <Card
                                className="whit_brdr_non"
                                hoverable
                                style={{
                                  width: 190,
                                  border: "2px solid #e7eaf3",
                                }}
                                cover={
                                  <Image
                                    preview={false}
                                    src={require(`../../../images/compress/${NFTTYPE}.png`)}
                                    alt=""
                                    style={{ height: "190px" }}
                                  />
                                }
                                key={i}
                              >
                                <Typography component="h5">
                                  NFT ID: {tostaked[NFTTYPE]}
                                </Typography>
                                <Typography component="h4">
                                  NFT Type: {NFTTYPE}
                                </Typography>
                              </Card>
                            ) : networkId === 1 ? (
                              <Card
                                className="whit_brdr_non"
                                hoverable
                                style={{
                                  width: 190,
                                  border: "2px solid #e7eaf3",
                                }}
                                cover={
                                  <Image
                                    preview={false}
                                    src={require(`../../../images/compressp/${NFTTYPE}.png`)}
                                    alt=""
                                    style={{ width: "190px" }}
                                  />
                                }
                                key={i}
                              >
                                <Typography component="h5">
                                  NFT ID: {tostaked[NFTTYPE]}
                                </Typography>
                                <Typography component="h4">
                                  NFT Type: {NFTTYPE}
                                </Typography>
                              </Card>
                            ) : (
                              <> </>
                            )}
                          </Box>
                        </Box>
                      </>
                    ))}
                </div>
              </Box>
            </div>
          </Grid>
        ) : (
          <Grid item lg={12}>
            <Typography component="h5">Staked NFTs:</Typography>
            <div className="progress_action text-right accordan_inn_btn_prnt">
              <Box className="button_box">
                <div style={styles.NFTs}>
                  {Object.keys(tostaked2).map((NFTTYPE, i) => (
                    <>
                      <Box className="img_main_box">
                        <Box className="img_main_box_innr">
                          {networkId === 56 ? (
                            <Card
                              className="whit_brdr_non"
                              hoverable
                              style={{
                                width: 190,
                                border: "2px solid #e7eaf3",
                              }}
                              cover={
                                <Image
                                  preview={false}
                                  src={require(`../../../images/compress/${tostaked2[NFTTYPE]}.png`)}
                                  alt=""
                                  style={{ height: "190px" }}
                                />
                              }
                              key={i}
                            >
                              <Typography component="h5">
                                NFT ID: {NFTTYPE}
                              </Typography>
                              <Typography component="h4">
                                NFT Type: {tostaked2[NFTTYPE]}
                              </Typography>
                            </Card>
                          ) : networkId === 1 ? (
                            <Card
                              className="whit_brdr_non"
                              hoverable
                              style={{
                                width: 190,
                                border: "2px solid #e7eaf3",
                              }}
                              cover={
                                <Image
                                  preview={false}
                                  src={require(`../../../images/compressp/${tostaked2[NFTTYPE]}.png`)}
                                  alt=""
                                  style={{ width: "190px" }}
                                />
                              }
                              key={i}
                            >
                              <Typography component="h5">
                                NFT ID: {NFTTYPE}
                              </Typography>
                              <Typography component="h4">
                                NFT Type: {tostaked2[NFTTYPE]}
                              </Typography>
                            </Card>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                    </>
                  ))}
                </div>
              </Box>
            </div>
            <Typography component="h5">
              <br></br>
              Single Withdraw:
              <form className="saleForm">
                <label htmlFor="id" className="labelform">
                  {" "}
                  Enter the NFT ID you want to withdraw:{" "}
                </label>
                <input
                  type="text"
                  value={quant}
                  onChange={(e) => setQuant(e.target.value)}
                  required
                />
              </form>
            </Typography>
            <div className="progress_action text-right accordan_inn_btn_prnt">
              <Box className="btn_outrborder_sdw">
                <button
                  type="button"
                  className="btn btn_def btn_width_160"
                  disabled={isWithdrawSinglePending || quant === "0"}
                  onClick={() => onWithdrawSingle(quant)}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="20"
                    height="auto"
                    background-color="#000000"
                    src={exitsign}
                    alt="logo"
                  />
                  {isWithdrawSinglePending ? `${"UNSTAKE"}` : `${"UNSTAKE"}`}
                </button>
              </Box>{" "}
            </div>
          </Grid>
        )}
        <div className="othrinfo_as othrinfo_as_v3">
          {pool.id === "20" ? (
            <Typography component="p">
              Avl. Points: <span>{points}</span>
            </Typography>
          ) : (
            <> </>
          )}
          <Typography component="p">
            Duration: <span>{pool.duration}</span>
          </Typography>
          <Typography component="p">
            Startdate: <span>{pool.startdate}</span>{" "}
          </Typography>
          <Typography component="p">
            Timelock: <span>NONE!</span>
          </Typography>
        </div>
      </Grid>
    </>
  );
}
