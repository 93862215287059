/* eslint-disable */
import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {Box, Grid} from '@material-ui/core';
import { useConnectWallet } from '../../home/redux/hooks';
import { useFetchPoolBalances } from '../redux/hooks';
import sectionPoolsStyle from '../jss/sections/sectionPoolsStyle';
import PoolHeader from './PoolHeader';
import PoolContent from './PoolContent';
const useStyles = makeStyles(sectionPoolsStyle);

export default function SectionPoolsV2({ index, toggleboost }) {
  const { networkId } = useConnectWallet();
  let { stakingpools_erc } = useFetchPoolBalances();
  const [openedCardList, setOpenCardList] = useState([]);
  const classes = useStyles();

  const openCard = (id) => {
    return setOpenCardList((openedCardList) => {
      if (openedCardList.includes(id)) {
        return openedCardList.filter((item) => item !== id);
      } else {
        return [...openedCardList, id];
      }
    });
  };

  return (
    <Grid item xs={12} className='mt-3 col-12' >
      {stakingpools_erc
        .filter((pool) => pool.status === 'aktive' && pool.chainId === networkId )
        .map((pool, index) => (
          <>
          <Box className="mn_a_box">
            <img src="/images/tab_top.png" className='tab_tp_mi' />
              <Accordion
                className='cstmaccrdn cstmaccrdn_ash_a'
                expanded={Boolean(openedCardList.includes(index))}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  onClick={(event) => {
                    event.stopPropagation();
                    openCard(index);
                  }}
                >
                  <PoolHeader
                    index={index}
                    pool={pool}
                    classes={classes}
                    openedCardList={openedCardList}
                    openCard={openCard}
                  />
                </AccordionSummary>

                <AccordionDetails>
                  <PoolContent
                    index={index}
                    pool={pool}
                    classes={classes}
                    toggle={toggleboost}
                  />
                </AccordionDetails>
              </Accordion>
              <Box className='tbmddl_sc' />
              <img src="/images/tab_bottom.png" className='tab_btm_mi' />
          </Box>
          
          </>
        ))}
    </Grid>
  );
}
