import { erc1155, circles, lootAbi, erc20ABI } from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { useConnectWallet } from "../../home/redux/hooks";
import axios from "axios";
import { ethers } from "ethers";

export { useFetchPoolBalances } from "./fetchPoolBalances";

export function useApprove(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

        contract.methods
          .approve(poolAddress, ethers.constants.MaxUint256.toString(10))
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

  return { isPending, onApprove: handleApprove };
}

export function useApprove3(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc1155, tokenAddress);

        contract.methods
          .setApprovalForAll(poolAddress, true)
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

  return { isPending, onApprove3: handleApprove };
}

export function useAllowance3(tokenAddress, earnContractAddress) {
  const { web3, address } = useConnectWallet();
  const [allowance3, setAllowance3] = useState("0");

  const fetchAllowance3 = useCallback(async () => {
    const contract = new web3.eth.Contract(circles, tokenAddress);
    let allowance3;
    if (tokenAddress === "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707") {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);
      allowance3 = await contract.methods.allowance(address, earnContractAddress).call();
    } else {
      allowance3 = await contract.methods
        .isApprovedForAll(address, earnContractAddress)
        .call();
    }

    setAllowance3(allowance3);
  }, [address, tokenAddress, setAllowance3, earnContractAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAllowance3();
      let refreshInterval = setInterval(fetchAllowance3, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAllowance3]);

  return allowance3;
}

export function useBalanceOf(tokenAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else if ( tokenAddress === "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707" ) {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    else if ( tokenAddress === "0x796263Bb5706fE09bCb44514E1525602FcEbEA31" ) {
      const contract = new web3.eth.Contract(erc1155, tokenAddress);
      balance = await contract.methods.balanceOf(address, tokenId).call();
    }

    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3, tokenId]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

export function useBalanceOfNAT(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

export function useDeposit(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (amount, entry2) => {
        const arr = entry2.filter(function(e) {
          return String(e).trim();
      });
      const test = arr.length * amount
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(lootAbi, poolAddress);
          console.log(entry2, arr)
          contract.methods
            .uncrateBatch(arr)
            .send({ from: address, value: test })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit: handleDeposit };
}

export function useDeposit4(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit4 = useCallback(
    async (amount, entry2) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(lootAbi, poolAddress);
          contract.methods
            .uncrate(entry2)
            .send({ from: address, value: amount })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit4: handleDeposit4 };
}

export function useDeposit2(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit2 = useCallback(async (amt) => {
    setIsPending(true);
    try {
      await new Promise(async (resolve, reject) => {
        const contract = new web3.eth.Contract(lootAbi, poolAddress);
        contract.methods
          .buyLootCrate()
          .send({ from: address })

          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress]);

  return { isPending, onDeposit2: handleDeposit2 };
}

export function useDeposit3(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit3 = useCallback(async (amt) => {
    setIsPending(true);
    try {
      await new Promise(async (resolve, reject) => {
        const contract = new web3.eth.Contract(lootAbi, poolAddress);
        contract.methods
          .batchBuyLootCrate(3)
          .send({ from: address })

          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress]);

  return { isPending, onDeposit3: handleDeposit3 };
}

export const fetchTokens = async (
  from,
  count,
  type = "single",
  collections,
  category = null,
  sortBy,
  filterBy = [],
  address = null,
  cancelToken
) => {
  const data = { from, count, type };
  if (collections.length > 0) {
    data.collectionAddresses = collections;
  }
  if (category !== null) {
    data.category = category;
  }
  if (address) {
    data.address = address;
  }
  if (filterBy.length) {
    data.filterby = filterBy;
  }
  data.sortby = sortBy;

  let apiUrl
  if ( collections[0] === "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1") {
  apiUrl = "https://api.pi-marketplace.io";
  } else 
  if ( collections[0] === "0xe651657fa2355261bbf4037cf6af43825af5da22") { 
  apiUrl = "https://api.pinftmarket.io";
  }

  const res = await axios({
    method: "post",
    url: `${apiUrl}/nftitems/fetchTokens`,
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken,
  });
  return res.data;
};