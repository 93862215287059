import { erc1155, staking, circles, pcs } from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { ethers } from "ethers";
import { useConnectWallet } from "../../home/redux/hooks";
export { useFetchPoolBalances } from "./fetchPoolBalances";

// ok

export function usePriceOf(tokenAddress, pcs2) {
  const { web3, address } = useConnectWallet();
  const [reserve, setReserve] = useState("0");

  const fetchReserve = useCallback(async () => {
    let reserve;
    if (tokenAddress === "") {
      reserve = await web3.eth.getReserve(address);
    } else {
      const contract = new web3.eth.Contract(pcs, pcs2);

      reserve = await contract.methods.getReserves().call();
    }
    setReserve(reserve);
  }, [address, setReserve, tokenAddress, web3, pcs2]);

  useEffect(() => {
    if (web3 && address) {
      fetchReserve();

      let refreshInterval = setInterval(fetchReserve, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchReserve]);

  return reserve;
}

export function useSupply(tokenAddress, pcs2) {
  const { web3, address } = useConnectWallet();
  const [supply, setSupply] = useState("0");

  const fetchSupply = useCallback(async () => {
    let supply;
    if (tokenAddress === "") {
      supply = await web3.eth.getSupply(address);
    } else {
      const contract = new web3.eth.Contract(pcs, pcs2);

      supply = await contract.methods.totalSupply().call();
    }
    setSupply(supply);
  }, [address, setSupply, tokenAddress, web3, pcs2]);

  useEffect(() => {
    if (web3 && address) {
      fetchSupply();

      let refreshInterval = setInterval(fetchSupply, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchSupply]);

  return supply;
}

export function useApprove(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc1155, tokenAddress);

        contract.methods
          .setApprovalForAll(poolAddress, true)
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

  return { isPending, onApprove: handleApprove };
}

// ok
export function useAllowance(tokenAddress, earnContractAddress) {
  const { web3, address } = useConnectWallet();
  const [allowance, setAllowance] = useState("0");

  const fetchAllowance = useCallback(async () => {
    if (tokenAddress === "") {
      setAllowance(ethers.constants.MaxUint256.toString(10));
      return;
    }
    const contract = new web3.eth.Contract(circles, tokenAddress);

    const allowance = await contract.methods
      .isApprovedForAll(address, earnContractAddress)
      .call();
    setAllowance(allowance);
  }, [address, earnContractAddress, setAllowance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAllowance();

      let refreshInterval = setInterval(fetchAllowance, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAllowance]);
  return allowance;
}

// OK
export function useBalanceOf(tokenAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc1155, tokenAddress);

      balance = await contract.methods.balanceOf(address, tokenId).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3, tokenId]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

// OK
export function useBalanceNFTOf(tokenAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [balanceNFT, setBalanceNFT] = useState("0");

  const fetchBalanceNFT = useCallback(async () => {
    let balanceNFT;
    if (tokenAddress === "") {
      balanceNFT = await web3.eth.balanceOfNFT(address);
    } else {
      const contract = new web3.eth.Contract(staking, tokenAddress);

      balanceNFT = await contract.methods.balanceOfNFT(address, tokenId).call();
    }
    setBalanceNFT(balanceNFT);
  }, [address, setBalanceNFT, tokenAddress, web3, tokenId]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalanceNFT();

      let refreshInterval = setInterval(fetchBalanceNFT, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalanceNFT]);

  return balanceNFT;
}

// ok
export function useEarned(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(staking, poolAddress);

    const earned = await contract.methods
      .earned(address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}

//ok
export function useDmagicAPY(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [APY2, setAPY2] = useState("0");

  const fetchAPY2 = useCallback(async () => {
    const contract = new web3.eth.Contract(staking, poolAddress);

    const APY2 = await contract.methods
      .getRewardToken1APY()
      .call({ from: address });
    setAPY2(APY2);
  }, [address, setAPY2, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAPY2();
    }
  }, [web3, address, fetchAPY2]);

  return APY2;
}

export function useDmagicWPY(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [Apy, setApy] = useState("0");

  const fetchApy = useCallback(async () => {
    const contract = new web3.eth.Contract(staking, poolAddress);

    const Apy = await contract.methods
      .getRewardToken1WPY()
      .call({ from: address });
    setApy(Apy);
  }, [address, setApy, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchApy();
    }
  }, [web3, address, fetchApy]);

  return Apy;
}

export function useDuration(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [Duration, setDuration] = useState("0");

  const fetchDuration = useCallback(async () => {
    const contract = new web3.eth.Contract(staking, poolAddress);

    const Duration = await contract.methods
      .totalSupply()
      .call({ from: address });
    setDuration(Duration);
  }, [address, setDuration, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchDuration();
    }
  }, [web3, address, fetchDuration]);

  return Duration;
}

// ok
export function useDeposit(poolAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(staking, poolAddress);
          contract.methods
            .stake(tokenId, amount)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress, tokenId]
  );

  return { isPending, onDeposit: handleDeposit };
}

// OK
export function useWithdraw(poolAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleWithdraw = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(staking, poolAddress);

          contract.methods
            .withdraw(tokenId, amount)
            .send({ from: address })

            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress, tokenId]
  );

  return { isPending, onWithdraw: handleWithdraw };
}

// ok
export function useFetchGetReward(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleGetReward = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise(async (resolve, reject) => {
        const contract = new web3.eth.Contract(staking, poolAddress);
        contract.methods
          .getReward()
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress]);

  return { isPending, onGetReward: handleGetReward };
}
