import React, { useState } from "react";
import { Grid, Box } from "@material-ui/core";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";

import {
  useApprove,
  useBalanceOf,
  useDeposit,
  useWithdraw,
  useFetchGetReward,
  useAllowance,
} from "../redux/hooks";
import depositimg from "../../../assets/img/deposit.svg";
import stakepiplogo from "../../../assets/img/stakepiplogo.svg";

import exitsign from "../../../assets/img/whh_exit.svg";
import Moment from "react-moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
Moment.globalLocal = true;

export default function PoolContent({ index, pool }) {
  const tokenBalance = useBalanceOf(pool.tokenAddress);
  let balanceSingle = byDecimals(tokenBalance, pool.tokenDecimals);
  const allowance = useAllowance(pool.tokenAddress, pool.earnContractAddress);
  const { isPending: isApprovePending, onApprove } = useApprove(
    pool.tokenAddress,
    pool.earnContractAddress
  );
  const { onDeposit, isPending: isDepositPending } = useDeposit(
    pool.earnContractAddress,
    pool.tokenAddress
  );

  const { onWithdraw, isPending: isWithdarwPending } = useWithdraw(
    pool.earnContractAddress,
    pool.tokenAddress
  );

  const { onGetReward, isPending: isGetRewardPending } = useFetchGetReward(
    pool.earnContractAddress
  );

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const balanceToDeposit = pool.payment;

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className="head_prnt"
        spacing={5}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          className="head_prnt"
        >
          <Grid item lg={12}>
            <div className="progress_action text-right accordan_inn_btn_prnt">
              {allowance === "0" ? (
                <Box className="btn_outrborder_sdw ms-auto-a">
                  <button
                    type="button"
                    className="btn btn_def_v3"
                    onClick={() => {
                      handleClose();
                      onApprove();
                    }}
                    disabled={isApprovePending}
                  >
                    <span>
                      <img
                        className="mr-2 fr_def"
                        width="25"
                        height="auto"
                        background-color="#000000"
                        src={stakepiplogo}
                        alt="logo"
                      />
                      {isApprovePending ? `${"Approve..."}` : `${"Approve"}`}
                    </span>
                  </button>
                </Box>
              ) : (
                <Box className="btn_outrborder_sdw ms-auto-a">
                  <button
                    type="button"
                    className="btn btn_def_v3"
                    onFocus={(event) => event.stopPropagation()}
                    disabled={isDepositPending}
                    onClick={(event) => {
                      handleClickOpen();
                    }}
                  >
                    {" "}
                    <img
                      className="mr-2 fr_def"
                      width="25"
                      height="auto"
                      background-color="#000000"
                      src={depositimg}
                      alt="logo"
                    />
                    {"Deposit"}
                  </button>
                </Box>
              )}{" "}
              <Box className="btn_outrborder_sdw ms-auto-a">
                <button
                  type="button"
                  className="btn btn_def_v3"
                  disabled={isWithdarwPending}
                  onClick={() => onWithdraw()}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="20"
                    height="auto"
                    background-color="#000000"
                    src={exitsign}
                    alt="logo"
                  />
                  {isWithdarwPending ? `${"UNSTAKE"}` : `${"UNSTAKE"}`}
                </button>
              </Box>{" "}
              <Box className="btn_outrborder_sdw ms-auto-a">
                <button
                  type="button"
                  className="btn btn_def_v3"
                  disabled={isGetRewardPending}
                  onClick={() => onGetReward()}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="20"
                    height="auto"
                    background-color="#000000"
                    src={exitsign}
                    alt="logo"
                  />
                  {isWithdarwPending ? `${"CLAIM"}` : `${"CLAIM"}`}
                </button>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="aprvmdl"
      >
        <DialogTitle id="alert-dialog-title">{"Note"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <li> Depositing will reset the Timelock Countdown. </li>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="progress_action d-flex w-100 mt-3 justify-content-center">
            {allowance !== "0" && (
              <div className="progress_action text-right mt-3">
                <button
                  className="btn btn_def"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={
                    isDepositPending || balanceSingle < balanceToDeposit
                  }
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClose();
                    onDeposit(
                      new BigNumber(balanceToDeposit)
                        .multipliedBy(
                          new BigNumber(10).exponentiatedBy(pool.tokenDecimals)
                        )
                        .toString(10)
                    );
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {"Deposit"}
                </button>
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
