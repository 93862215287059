import { erc721, staking, pcs, erc20ABI, PINFT, NEWNFT } from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { useConnectWallet } from "../../home/redux/hooks";
import axios from "axios";

export { useFetchPoolBalances } from "./fetchPoolBalances";

export function usePriceOf(tokenAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [reserve, setReserve] = useState("0");

  const fetchReserve = useCallback(async () => {
    let reserve;
    if (tokenAddress === "") {
      reserve = await web3.eth.getReserve(address);
    } else {
      const contract = new web3.eth.Contract(
        pcs,
        "0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f"
      );

      reserve = await contract.methods.getReserves().call();
    }
    setReserve(reserve);
  }, [address, setReserve, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchReserve();

      let refreshInterval = setInterval(fetchReserve, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchReserve]);

  return reserve;
}

export function useSupply(tokenAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [supply, setSupply] = useState("0");

  const fetchSupply = useCallback(async () => {
    let supply;
    if (tokenAddress === "") {
      supply = await web3.eth.getSupply(address);
    } else {
      const contract = new web3.eth.Contract(
        pcs,
        "0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f"
      );

      supply = await contract.methods.totalSupply().call();
    }
    setSupply(supply);
  }, [address, setSupply, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchSupply();

      let refreshInterval = setInterval(fetchSupply, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchSupply]);

  return supply;
}
export const fetchTokens = async (
  from,
  count,
  type = "single",
  collections,
  category = null,
  sortBy = "id",
  filterBy,
  address,
  cancelToken
) => {
  const data = { from, count, type };
  if (collections.length > 0) {
    data.collectionAddresses = collections;
  }
  if (category !== null) {
    data.category = category;
  }
  if (address) {
    data.address = address;
  }
  if (filterBy.length) {
    data.filterby = filterBy;
  }
  data.sortby = sortBy;

  let apiUrl = 'https://api.pi-marketplace.io'

  const res = await axios({
    method: "post",
    url: `${apiUrl}/nftitems/fetchTokens`,
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken,
  });
  return res.data;
};

export function useApprove(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(
    async (owner) => {
      setIsPending(true);
      try {
        await new Promise((resolve, reject) => {
          const contract = new web3.eth.Contract(PINFT, tokenAddress);

          contract.methods
            .setApprovalForAll(poolAddress, true)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]
  );

  return { isPending, onApprove: handleApprove };
}

// OK
export function useBalanceOf(tokenAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

// OK
export function useBalanceNFTOf(tokenAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [balanceNFT, setBalanceNFT] = useState("0");

  const fetchBalanceNFT = useCallback(async () => {
    let balanceNFT;
    if (tokenAddress === "") {
      balanceNFT = await web3.eth.balanceOfNFT(address);
    } else {
      const contract = new web3.eth.Contract(erc721, tokenAddress);

      balanceNFT = await contract.methods.balanceOfNFT(address).call();
    }
    setBalanceNFT(balanceNFT);
  }, [address, setBalanceNFT, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalanceNFT();

      let refreshInterval = setInterval(fetchBalanceNFT, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalanceNFT]);

  return balanceNFT;
}

// ok
export function useEarned(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(erc721, poolAddress);

    const earned = await contract.methods
      .earned(address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 10000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}

//ok
export function useDmagicAPY(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [APY2, setAPY2] = useState("0");

  const fetchAPY2 = useCallback(async () => {
    const contract = new web3.eth.Contract(erc721, poolAddress);

    const APY2 = await contract.methods
      .getRewardToken1APY()
      .call({ from: address });
    setAPY2(APY2);
  }, [address, setAPY2, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAPY2();
      let refreshInterval = setInterval(fetchAPY2, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAPY2]);

  return APY2;
}

export function useDmagicWPY(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [Apy, setApy] = useState("0");

  const fetchApy = useCallback(async () => {
    const contract = new web3.eth.Contract(erc721, poolAddress);

    const Apy = await contract.methods
      .getRewardToken1WPY()
      .call({ from: address });
    setApy(Apy);
  }, [address, setApy, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchApy();
      let refreshInterval = setInterval(fetchApy, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchApy]);

  return Apy;
}

export function useDuration(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [Duration, setDuration] = useState("0");

  const fetchDuration = useCallback(async () => {
    const contract = new web3.eth.Contract(staking, poolAddress);

    const Duration = await contract.methods
      .totalSupply()
      .call({ from: address });
    setDuration(Duration);
  }, [address, setDuration, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchDuration();
      let refreshInterval = setInterval(fetchDuration, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchDuration]);

  return Duration;
}

// ok
export function useDeposit(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (tokenId) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(erc721, poolAddress);
          contract.methods
            .stake(tokenId)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit: handleDeposit };
}

export function useDeposit2(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit2 = useCallback(
    async (tokenId) => {
     
      const result = tokenId.slice(1);
      console.log('this', result)
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(NEWNFT, poolAddress);
          contract.methods
            .stakeAll(tokenId)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit2: handleDeposit2 };
}

export function useWithdraw(poolAddress, tokenId) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleWithdraw = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(erc721, poolAddress);

          contract.methods
            .exit()
            .send({ from: address })

            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onWithdraw: handleWithdraw };
}

export function useFetchGetReward(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleGetReward = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise(async (resolve, reject) => {
        const contract = new web3.eth.Contract(erc721, poolAddress);
        contract.methods
          .getReward()
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress]);

  return { isPending, onGetReward: handleGetReward };
}
