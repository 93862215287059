import { erc20ABI, PIPXStaking } from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { ethers } from "ethers";
import { useConnectWallet } from "../../home/redux/hooks";
import axios from "axios";

export { useFetchPoolBalances } from "./fetchPoolBalances";

const apiUrl = "https://api.pi-marketplace.io";

export const getVaults = async () => {
  const res = await axios.get(`${apiUrl}/info/getVaults`);
  return res.data;
};

const LPStaking = "0xa9cD9AAc6564FfDF328EA94AAC97D4B6E93C772d";

////////////////////////////
// OK
export function useBalanceOf(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}
// OK
export function useBalanceOfVault(vaultId) {
  const { web3, address } = useConnectWallet();
  const [balanceNFT, setBalanceNFT] = useState("0");

  const fetchBalanceNFT = useCallback(async () => {
    let balanceNFT;
    const contract = new web3.eth.Contract(PIPXStaking, LPStaking);

    balanceNFT = await contract.methods.balanceOf(vaultId, address).call();
    setBalanceNFT(balanceNFT);
  }, [address, vaultId, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalanceNFT();

      let refreshInterval = setInterval(fetchBalanceNFT, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalanceNFT]);

  return balanceNFT;
}

// ok
export function useEarned(vaultId) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(PIPXStaking, LPStaking);

    const earned = await contract.methods
      .adjustedDividendOf(vaultId, address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, web3, vaultId]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}

// ok
export function useFetchGetReward(vaultId) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleGetReward = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise(async (resolve, reject) => {
        const contract = new web3.eth.Contract(PIPXStaking, LPStaking);
        contract.methods
          .claimRewards(vaultId)
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, vaultId]);

  return { isPending, onGetReward: handleGetReward };
}

// ok
export function useAllowance(tokenAddress, spender) {
  const { web3, address } = useConnectWallet();
  const [allowance, setAllowance] = useState("0");

  const fetchAllowance = useCallback(async () => {

    const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

    const allowance = await contract.methods.allowance(address, spender).call();
    setAllowance(allowance);
  }, [address, spender, setAllowance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAllowance();

      let refreshInterval = setInterval(fetchAllowance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAllowance]);

  return allowance;
}
// OK
export function useWithdraw(vaultId) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleWithdraw = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(PIPXStaking, LPStaking);

          contract.methods
            .withdraw(vaultId, amount)
            .send({ from: address })

            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, vaultId]
  );

  return { isPending, onWithdraw: handleWithdraw };
}

// ok
export function useDeposit(vaultId) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(PIPXStaking, LPStaking);
          contract.methods
            .deposit(vaultId, amount)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, vaultId]
  );

  return { isPending, onDeposit: handleDeposit };
}

export function useApprove(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

        contract.methods
          .approve(poolAddress, ethers.constants.MaxUint256.toString(10))
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

  return { isPending, onApprove: handleApprove };
}

export function useLocked(vaultId) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(PIPXStaking, LPStaking);

    const earned = await contract.methods
      .lockedUntil(vaultId, address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, web3, vaultId]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 2000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}
/////////////////////////////
