
export const zapOrSwap = async ({web3, address, address2, contract, call, call2, amount, dispatch}) => {
  // console.log(`=====================================deposit begin=====================================`)

  const data = await _zapOrSwap({web3, address,address2, contract, call,call2, amount, dispatch});
  // console.log(`=====================================deposit success=====================================`)
  return data;
}

const _zapOrSwap = ({contract, amount, call,call2,address,address2, dispatch}) => {
  return new Promise((resolve, reject) => {

    const lptoken = '0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f'
    // console.log(isAll)
    if (call==='deposit') {
    contract.methods[call]().send({ from: address,  value:amount }).on('transactionHash', function(hash){
      console.log(hash)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='easySell3') {
    contract.methods[call](amount).send({ from: address, gas: 2500000, }).on('transactionHash', function(hash){
      console.log(hash)
      console.log(contract)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='easySell4') {
    contract.methods[call](lptoken, amount).send({ from: address, gas: 2500000, }).on('transactionHash', function(hash){
      console.log(hash)
      console.log(contract)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })


  } else if (call==='depositTokens') {
    contract.methods[call](address2, amount).send({ from: address, gas: 2500000,}).on('transactionHash', function(hash){
      console.log(hash)
      console.log(address2)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='easyBuy2') {
    contract.methods[call]().send({ from: address, gas: 2500000, value:amount}).on('transactionHash', function(hash){
      console.log(hash)
      console.log(contract)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })
  } else {

  contract.methods[call](amount).send({ from: address,gas: 2500000, value:amount }).on('transactionHash', function(hash){
    console.log(hash)

  })
  .on('receipt', function(receipt){
    resolve()
  })
  .on('error', function(error) {
    console.log(error)
    reject(error)
  })
  .catch((error) => {
    console.log(error)
    reject(error)
  })
   } })
}