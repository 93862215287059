import { VaultPage } from '.';


export default {
  path: "lootbox",
  childRoutes: [
    { path: "lootbox", component: VaultPage, isIndex: true },

  ],
};
