
export const phoenixpools = [

  { 
    chainId: 56,
    id: '20',
    name: 'PHOENIX',
    token: 'PHOENIX',
    tokenDescription: '',
    tokenDescription2: 'All Tails, Class 1-8 (2-48)',
    tokenAddress: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',  // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: 'PIP',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    earnContractAddress: '0x230ee43a50cA6D36Ce1Fa5E2DfAB733Dae21870C',  // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 days",
    startdate: "2022-09-26",
    status: 'aktive',
    typ: 'nft',
    multi: 1,
    APYC: 0.015,
    ended: "no",
    BLL: "0x57C6aBC6Db2a726616805bC87539082f4678bC01",
    type1: "",
    type2: "",
    type3: "",
    type4: "",
    type5: "",
    type6: "",
    stakingclass: "none",
    firstPool: "0x230ee43a50cA6D36Ce1Fa5E2DfAB733Dae21870C",
    tokenAddress1: "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1"
  },
 { 
    chainId: 56,
    id: '1',
    name: 'PHOENIX',
    token: 'PHOENIX',
    tokenDescription: '',
    tokenDescription2: 'Yellow Tails, Class 3 (14-19) ',
    tokenAddress: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',  // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: 'PIP',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    earnContractAddress: '0x2F703c2Ff9e83d5125D9624522A25BaA956F0b5d',  // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 days",
    startdate: "2022-09-30",
    status: 'aktive',
    typ: 'nft',
    multi: 1,
    APYC: 0.015,
    ended: "no",
    BLL: "0x1C9C3e03eBF4004Ee1dD3F3273de260BdC3d46a1",
    type1: "14",
    type2: "15",
    type3: "16",
    type4: "17",
    type5: "18",
    type6: "19",
    stakingclass: "1",
    firstPool: "0x230ee43a50cA6D36Ce1Fa5E2DfAB733Dae21870C",
    tokenAddress1: "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1"
  },
  { 
    chainId: 56,
    id: '2',
    name: 'PHOENIX',
    token: 'PHOENIX',
    tokenDescription: '',
    tokenDescription2: 'Green Tails, Class 4 (20-25) ',
    tokenAddress: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',  // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: 'PIP',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    earnContractAddress: '0xC5b171454c80A3fC99fC8fF3489F22786DF6F5aF',  // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 days",
    startdate: "2022-09-30",
    status: 'aktive',
    typ: 'nft',
    multi: 1,
    APYC: 0.015,
    ended: "no",
    BLL: "0x1C9C3e03eBF4004Ee1dD3F3273de260BdC3d46a1",
    type1: "20",
    type2: "21",
    type3: "22",
    type4: "23",
    type5: "24",
    type6: "25",
    stakingclass: "0",
    firstPool: "0x230ee43a50cA6D36Ce1Fa5E2DfAB733Dae21870C",
    tokenAddress1: "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1"
  },
  { 
    chainId: 56,
    id: '2',
    name: 'PHOENIX',
    token: 'PHOENIX',
    tokenDescription: '',
    tokenDescription2: 'Grey Tails, Class 5 (26-31) ',
    tokenAddress: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',  // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: 'PIP',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    earnContractAddress: '0xb6f32237d6bC9b606b71019110AC25195f2960F6',  // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 days",
    startdate: "2022-01-02",
    status: 'aktive',
    typ: 'nft',
    multi: 1,
    APYC: 0.015,
    ended: "no",
    BLL: "0x1C9C3e03eBF4004Ee1dD3F3273de260BdC3d46a1",
    type1: "26",
    type2: "27",
    type3: "28",
    type4: "29",
    type5: "30",
    type6: "31",
    stakingclass: "2",
    firstPool: "0x230ee43a50cA6D36Ce1Fa5E2DfAB733Dae21870C",
    tokenAddress1: "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1"
  },
//////////////////////////////////////// ETH /////////////////////////////////////
  { 
    chainId: 1,
    id: '20',
    name: 'PANTHER',
    token: 'PHOENIX',
    tokenDescription: '',
    tokenDescription2: 'All NFTs, Class 1-8 (2-48)',
    tokenAddress: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',  // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: 'PIP',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    earnContractAddress: '0x6Ec28b420Bb596f9df8B0b8fbD2F6ca004FDdc92',  // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "365 days",
    startdate: "2022-10-10",
    status: 'aktive',
    typ: 'nft',
    multi: 1,
    APYC: 0.015,
    ended: "no",
    BLL: "0xFD95eCa870336399f8c30Bf999C80b502C58e8A4",
    type1: "",
    type2: "",
    type3: "",
    type4: "",
    type5: "",
    type6: "",
    stakingclass: "none",
    firstPool: "0x6Ec28b420Bb596f9df8B0b8fbD2F6ca004FDdc92",
    tokenAddress1: "0xE651657fa2355261Bbf4037cF6af43825Af5Da22"
  },
  { 
    chainId: 1,
    id: '1',
    name: 'PANTHER',
    token: 'PHOENIX',
    tokenDescription: '',
    tokenDescription2: 'Yellow Class 3 (14-19) ',
    tokenAddress: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',  // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: 'PIP',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    earnContractAddress: '0x3dAc83d177a6869289dc99E56588E047Aa1cD687',  // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 days",
    startdate: "2022-12-28",
    status: 'aktive',
    typ: 'nft',
    multi: 1,
    APYC: 0.015,
    ended: "no",
    BLL: "0x5Bb52c7C2e68bFd3884072459b628b0b81eAcc69",
    type1: "14",
    type2: "15",
    type3: "16",
    type4: "17",
    type5: "18",
    type6: "19", 
    stakingclass: "0",
    firstPool: "0x6Ec28b420Bb596f9df8B0b8fbD2F6ca004FDdc92",
    tokenAddress1: "0xE651657fa2355261Bbf4037cF6af43825Af5Da22"
  },
  { 
    chainId: 1,
    id: '1',
    name: 'PANTHER',
    token: 'PHOENIX',
    tokenDescription: '',
    tokenDescription2: 'Green, Class 4 (20-25) ',
    tokenAddress: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',  // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: 'PIP',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    earnContractAddress: '0x890dF647663cb4696952D9626f0391F43f7d12e9',  // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 days",
    startdate: "2022-12-28",
    status: 'aktive',
    typ: 'nft',
    multi: 1,
    APYC: 0.015,
    ended: "no",
    BLL: "0x5Bb52c7C2e68bFd3884072459b628b0b81eAcc69",
    type1: "20",
    type2: "21",
    type3: "22",
    type4: "23",
    type5: "24",
    type6: "25",
    stakingclass: "1",
    firstPool: "0x6Ec28b420Bb596f9df8B0b8fbD2F6ca004FDdc92",
    tokenAddress1: "0xE651657fa2355261Bbf4037cF6af43825Af5Da22"
  },
 ]
 