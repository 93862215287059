import React, { useEffect } from "react";
import CircleBoxes8 from "./sections/CircleBoxes8";
import { Box, Container, SimpleGrid, Grid } from "@chakra-ui/react";
import AOS from "aos";
import LaunchpadTitle from "./sections/LaunchpadTitle";
import { ChakraProvider } from "@chakra-ui/react";
//import NewContant from "./newsections/NewContant";
//import NewContantTwo from "./newsections/NewContantTwo";
//import NewContantThree from "./newsections/NewContantThree";
//import NewContantFour from "./newsections/NewContantFour";

export default function VaultPage() {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
      <ChakraProvider>
        <Box className="nft_prnt footer_spaceing stkng_main_bx">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <LaunchpadTitle />
            </Grid>
          </Grid>

          <div className="p_rltv">
            <div className="box_p">
              <Box className="responsive_padding_for_space">
                <Box
                  className="gallary_sect"
                  data-aos="zoom-in-down"
                  data-aos-duration="2000"
                >
                  <Box className="bor_width"></Box>
                </Box>
                <Container className="cstmcntnr_ash">
                  <SimpleGrid columns={12} columnGap={0} rowGap={2}>
                    <CircleBoxes8 index="index" />
{/*                     <NewContant />
                    <NewContantTwo />
                    <NewContantThree />
                    <NewContantFour /> */}
                  </SimpleGrid>
                </Container>
              </Box>
            </div>
            <div className="shadow_box"></div>
          </div>
        </Box>
      </ChakraProvider>
    </>
  );
}
