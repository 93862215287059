//  fetchBalances
export const SWAPSU_FETCH_BALANCES_BEGIN = 'SWAPSU_FETCH_BALANCES_BEGIN';
export const SWAPSU_FETCH_BALANCES_SUCCESS = 'SWAPSU_FETCH_BALANCES_SUCCESS';
export const SWAPSU_FETCH_BALANCES_FAILURE = 'SWAPSU_FETCH_BALANCES_FAILURE';

//  checkApproval
export const SWAPSU_CHECK_APPROVAL_BEGIN = 'SWAPSU_CHECK_APPROVAL_BEGIN';
export const SWAPSU_CHECK_APPROVAL_SUCCESS = 'SWAPSU_CHECK_APPROVAL_SUCCESS';
export const SWAPSU_CHECK_APPROVAL_FAILURE = 'SWAPSU_CHECK_APPROVAL_FAILURE';

//  fetchApproval
export const SWAPSU_FETCH_APPROVAL_BEGIN = 'SWAPSU_FETCH_APPROVAL_BEGIN';
export const SWAPSU_FETCH_APPROVAL_SUCCESS = 'SWAPSU_FETCH_APPROVAL_SUCCESS';
export const SWAPSU_FETCH_APPROVAL_FAILURE = 'SWAPSU_FETCH_APPROVAL_FAILURE';

//  fetchZapOrSwap
export const SWAPSU_FETCH_ZAP_OR_SWAPSU_BEGIN = 'SWAPSU_FETCH_ZAP_OR_SWAPSU_BEGIN';
export const SWAPSU_FETCH_ZAP_OR_SWAPSU_SUCCESS = 'SWAPSU_FETCH_ZAP_OR_SWAPSU_SUCCESS';
export const SWAPSU_FETCH_ZAP_OR_SWAPSU_FAILURE = 'SWAPSU_FETCH_ZAP_OR_SWAPSU_FAILURE';

export const LIQUIDITY_FETCH_FULL_SHARE_BEGIN = 'LIQUIDITY_FETCH_FULL_SHARE_BEGIN';
export const LIQUIDITY_FETCH_FULL_SHARE_SUCCESS = 'LIQUIDITY_FETCH_FULL_SHARE_SUCCESS';
export const LIQUIDITY_FETCH_FULL_SHARE_FAILURE = 'LIQUIDITY_FETCH_FULL_SHARE_FAILURE';

export const LIQUIDITY_FETCH_VEIN_BEGIN = 'LIQUIDITY_FETCH_VEIN_BEGIN';
export const LIQUIDITY_FETCH_VEIN_SUCCESS = 'LIQUIDITY_FETCH_VEIN_SUCCESS';
export const LIQUIDITY_FETCH_VEIN_FAILURE = 'LIQUIDITY_FETCH_VEIN_FAILURE';

export const LIQUIDITY_FETCH_SUPPLY_BEGIN = 'LIQUIDITY_FETCH_SUPPLY_BEGIN';
export const LIQUIDITY_FETCH_SUPPLY_SUCCESS = 'LIQUIDITY_FETCH_SUPPLY_SUCCESS';
export const LIQUIDITY_FETCH_SUPPLY_FAILURE = 'LIQUIDITY_FETCH_SUPPLY_FAILURE';

