import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./history";
import homeReducer from "features/home/redux/reducer";
//import gameReducer from "features/game/redux/reducer";
import dexReducer from 'features/dex/redux/reducer';
import dex_ethReducer from 'features/eth_dex/redux/reducer';
import stakingReducer from 'features/staking/redux/reducer';
//import nftvaultsReducer from 'features/nftvaults/redux/reducer';
//import nftvaults2Reducer from 'features/nftvaults2/redux/reducer';
//import nftvaults3Reducer from 'features/nftvaults3/redux/reducer';
import staking_ercReducer from 'features/staking_erc/redux/reducer';
import staking_721Reducer from 'features/staking_721/redux/reducer';
import stakingPReducer from 'features/stakingP/redux/reducer'
import commonReducer from "features/common/redux/reducer";
import circleReducer from "features/circle/redux/reducer";
import demigodsReducer from "features/demigods/redux/reducer";

import breedReducer from "features/breed/redux/reducer"
import launchpadReducer from "features/launchpad/redux/reducer";
//import mythReducer from "features/myth/redux/reducer";
//import presaleReducer from "features/presale/redux/reducer";
import lootboxReducer from "features/lootbox/redux/reducer";
import badge_stakeReducer from "features/badge_stake/redux/reducer"
import pipxReducer from "features/pipx/redux/reducer"
import pipsReducer from "features/pips/redux/reducer"

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  //game: gameReducer,
  common: commonReducer,
  dex: dexReducer,
  dex_eth: dex_ethReducer,
  staking: stakingReducer,
  staking_erc: staking_ercReducer,
  staking_721: staking_721Reducer,
  stakingP: stakingPReducer,
  breed: breedReducer,
  //nftvaults: nftvaultsReducer,
  //nftvaults2: nftvaults2Reducer,
  //nftvaults3: nftvaults3Reducer,
  circle: circleReducer,
  badge_stake: badge_stakeReducer,
  launchpad: launchpadReducer,
  //myth: mythReducer,
  //presale: presaleReducer,
  lootbox: lootboxReducer,
  pipx: pipxReducer,
  pips: pipsReducer,
  demigods: demigodsReducer

};

export default combineReducers(reducerMap);
