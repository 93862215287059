import { pETH, pETHDirectABI, pETHLiq, eCircleDirect } from "../../configure";

const AxMaticAddress = "0xbb9Bb97BD5A7d94431bB4DD134886522034e7251"; // PETHAdd RR
const axMaticDirectAddress = "0x2E8A85b131A2cFBcb339a493652bB201c1331c08"; // PETHDirectPancakeSwappingAdd    RR
const dragonDirectAddress = "0xEFeadeb2445E8e2b0c027001bA3d67b29D5a84e7"; // circleDirect
const DRAXliqContract = "0xBFcF45366679384bad328F08Af39c449F4b03dE1"; // PETHLiquidityLPAdd

export const allowance = [
  {
    // Confirmed Working
    name: "ETH",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", //OK
    pools: [
      {
        name: "AxMaticAddress",
        address: AxMaticAddress,
        allowance: 1,
      },
      {
        name: "axMaticDirectAddress",
        address: axMaticDirectAddress,
        allowance: 1,
      },
      {
        name: "dragonDirectAddress",
        address: dragonDirectAddress,
        allowance: 1,
      },
    ],
  },
  {
    name: "PIP",
    address: "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707", //  OK RR
    pools: [
      {
        name: "axMaticDirectAddress",
        address: axMaticDirectAddress,
        allowance: 0,
      },
      {
        name: "dragonDirectAddress",
        address: dragonDirectAddress,
        allowance: 0,
      },
    ],
  },
  {
    name: "PETH",
    address: "0xbb9Bb97BD5A7d94431bB4DD134886522034e7251", //   OK RR
    pools: [
      {
        name: "dragonDirectAddress",
        address: dragonDirectAddress,
        allowance: 0,
      },
      {
        name: "AxMaticAddress",
        address: AxMaticAddress,
        allowance: 0,
      },
      {
        name: "axMaticDirectAddress",
        address: axMaticDirectAddress,
        allowance: 0,
      },
    ],
  },

  {
    name: "PETH-PIP-LP",
    address: "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9", //  OK RR
    pools: [
      {
        name: "DRAXliqContract",
        address: DRAXliqContract,
        allowance: 0,
      },
    ],
  },

  {
    name: "SCIRCLE",
    address: "0xcF5DbC399F3112bac87f57018A3389911241237B", // OK
    pools: [
      {
        name: "dragonDirectAddress",
        address: dragonDirectAddress,
        allowance: 0,
      },
    ],
  },
];

export const tokens = [
  {
    // Working !!
    name: "ETH",
    description: "ETH",
    address: "", //  22
    decimals: 18,
    balance: 0,
    pricePerFullShare: "0",
    receivableList: [
      {
        name: "PIP",
        type: "swap",
        contract: {
          name: "axMaticDirectAddress",
          address: axMaticDirectAddress,
          abi: pETHDirectABI,
          call: "easyBuy",
          call2: "easyBuy2",
        },
      },
      {
        name: "PETH",
        type: "swap",
        contract: {
          name: "AxMaticAddress",
          address: AxMaticAddress,
          abi: pETH,
          call: "deposit",
          call2: "deposit",
        },
      },
      {
        name: "SCIRCLE",
        type: "swap",
        contract: {
          name: "dragonDirectAddress",
          address: dragonDirectAddress,
          abi: eCircleDirect,
          call: "easyBuySmallCircle",
          call2: "easyBuy2",
        },
      },
    ],
  },

  {
    name: "PIP",
    description: "PIP",
    address: "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707", //  OK
    decimals: 18,
    balance: 0,
    pricePerFullShare: "0",
    receivableList: [
      {
        name: "ETH",
        type: "swap",
        contract: {
          name: "axMaticDirectAddress",
          address: axMaticDirectAddress,
          abi: pETHDirectABI,
          call: "easySell",
          call2: "easySell3",
        },
      },
      {
        name: "SCIRCLE ",
        type: "swap",
        contract: {
          address2: "", //
          name: "dragonDirectAddress",
          address: dragonDirectAddress,
          abi: eCircleDirect,
          call: "easyBuyFromPi",
          call2: "easySell3",
        },
      },
      {
        name: "PETH ",
        type: "swap",
        contract: {
          address2: "", //
          name: "axMaticDirectAddress",
          address: axMaticDirectAddress,
          abi: pETHDirectABI,
          call: "easySellToPETH",
          call2: "easySell3",
        },
      },
    ],
  },
  {
    name: "PETH",
    description: "PETH",
    address: "0xbb9Bb97BD5A7d94431bB4DD134886522034e7251", //   OK RR
    decimals: 18,
    balance: 0,
    pricePerFullShare: "0",
    receivableList: [
      {
        name: "SCIRCLE  ",
        type: "swap",
        contract: {
          address2: "", //
          name: "dragonDirectAddress",
          address: dragonDirectAddress,
          abi: eCircleDirect,
          call: "easyBuyFromPETH",
          call2: "easySell3",
        },
      },
      {
        name: "ETH  ",
        type: "swap",
        contract: {
          name: "AxMaticAddress",
          address: AxMaticAddress,
          abi: pETH,
          call: "withdraw",
          call2: "easySell3",
        },
      },
      {
        name: "PIP ",
        type: "swap",
        contract: {
          name: "axMaticDirectAddress",
          address: axMaticDirectAddress,
          abi: pETHDirectABI,
          call: "easyBuyFromPETH",
          call2: "easySell3",
        },
      },
    ],
  },
  {
    name: "PETH-PIP-LP",
    description: "PETH-PIP-LP",
    address: "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9", //   OK
    address2: "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9", //  OK
    decimals: 18,
    balance: 0,
    pricePerFullShare: "0",
    receivableList: [
      {
        name: "SCIRCLE   ",
        type: "swap",
        contract: {
          address2: "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9", // OK
          name: "DRAXliqContract",
          address: DRAXliqContract,
          abi: pETHLiq,
          call: "depositTokens",
          call2: "depositTokens",
        },
      },
    ],
  },

  {
    name: "SCIRCLE",
    description: "SCIRCLE",
    address: "0xcF5DbC399F3112bac87f57018A3389911241237B", //  OK
    decimals: 0,
    balance: 0,
    pricePerFullShare: "0",
    receivableList: [
      {
        name: "ETH   ",
        type: "zap",
        contract: {
          name: "dragonDirectAddress",
          address: dragonDirectAddress,
          abi: eCircleDirect,
          call: "easySellSmallCircleToBNB",
          call2: "easySell4",
        },
      },
      {
        name: "PETH  ",
        type: "zap",
        contract: {
          name: "dragonDirectAddress",
          address: dragonDirectAddress,
          abi: eCircleDirect,
          call: "easySellSmallCircleToPETH",
          call2: "easySell4",
        },
      },
      {
        name: "PIP  ",
        type: "zap",
        contract: {
          name: "dragonDirectAddress",
          address: dragonDirectAddress,
          abi: eCircleDirect,
          call: "easySellSmallCircleToPi",
          call2: "easySell4",
        },
      },
      {
        name: "PETH-PIP-LP",
        type: "zap",
        contract: {
          name: "dragonDirectAddress",
          address: dragonDirectAddress,
          abi: eCircleDirect,
          call: "sellSmallCircleToLP",
          call2: "easySell4",
        },
      },
      {
        name: "ECIRCLE",
        type: "zap",
        contract: {
          name: "dragonDirectAddress",
          address: dragonDirectAddress,
          abi: eCircleDirect,
          call: "buyBigCircle",
          call2: "easySell3",
        },
      },
    ],
  },
];

const initialState = {
  tokens,
  allowance,
  fetchBalancesPending: false,
  checkApprovalPending: false,
  fetchApprovalPending: false,
  fetchZapOrSwapPending: false
};

export default initialState;
