import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { fetchDmagicPrice } from "features/circle/sections/Statistic";
import {
  useEarned,
  useBalanceOf,
  useDmagicAPY,
  useDuration,
  useBalanceNFTOf,
  useBalanceOfS,
} from "../redux/hooks";

const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100000))
    .toNumber();
};

const forMatApy = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100))
    .toNumber();
};

export default function PoolHeader({ pool }) {
  const tokenBalance = useBalanceOf(pool.tokenAddress, pool.tokenId);
  const depositedBalance = useBalanceNFTOf(
    pool.earnContractAddress,
    pool.tokenId
  );

  const earned = useEarned(pool.earnContractAddress);
  const formattedEarned = byDecimals(earned);
  const dmagicAPY = useDmagicAPY(pool.earnContractAddress);
  const tvl = useDuration(pool.earnContractAddress);
  // eslint-disable-next-line no-unused-vars
  const [dmprice, setDmPrice] = useState(0);

  const pointsS = useBalanceOfS(pool.earnContractAddress);
  const stakedpoints = byDecimals(pointsS, 18).toFixed(0);

  const persValue = (depositedBalance * 35) / stakedpoints;
  const pers =
    (stakedpoints / 100) * forMatApy(byDecimals(dmagicAPY, 6).toNumber());
  const further = (pers / ((forMat(depositedBalance) * 35) / 100)) * 1;

  useEffect(() => {
    const getDmagicInfo = async () => {
      const {
        data: { price },
      } = await fetchDmagicPrice();

      const data = {
        DmagicPrice: price,
      };
      const dmagicMilPrice = price;
      data.dmagicMilPrice = dmagicMilPrice;
      setDmPrice(data.dmagicMilPrice);
    };
    getDmagicInfo();
  }, []);

  return (
    <>
      <Grid container direction="row" alignItems="center" className="head_prnt">
        <Grid container spacing={1} className="align-items-center">
          <Grid item xs={12} md={6} lg={5} className="">
            <Box className="avtr_text_ash_prnt">
              <AvatarGroup max={4} className="avrggrp">
                <Avatar
                  alt={pool.token}
                  src={require(`../../../images/${pool.token}-logo.png`)}
                />
              </AvatarGroup>
              <Box className="tvl_usd_text_v3">
                <Typography component="label">
                  Total Staked Pts:{" "}
                  <span className="clpsgreentxt">
                    {byDecimals(tvl).toFixed(0)}{" "}
                  </span>
                </Typography>
                {pool.ended === "yes" ? (
                  <>
                    <Typography component="h6" className="grin_bustble">
                      ENDED
                    </Typography>
                  </>
                ) : (
                  <>
                    {" "}
                    <Typography component="p" className="grin_bustble2">
                      Please wait 5 min between staking.
                    </Typography>
                  </>
                )}

                <Typography component="label">
                  {pool.tokenDescription}
                </Typography>
                <Typography component="label">
                  {pool.tokenDescription2}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3} className="bx_cnt_scnd">
            <Box className="apr_weekly_prnt_ash">
              <Typography component="h4">
                Stake <span className="grntxtgrdnt">{pool.name}</span> for{" "}
                <span className="yllwtxtgrdnt">{pool.earnedToken}</span>
              </Typography>
              <Typography component="label">
                {depositedBalance === "0" ? (
                  <>
                    {" "}
                    APR per Point:{" "}
                    <span className="clpsyllwtxt">
                      {forMatApy(
                        byDecimals(dmagicAPY, 6).toNumber()
                        //byDecimals(dmagicAPY / pool.APYC, 6).toNumber()
                      )}{" "}
                      %{" "}
                    </span>
                  </>
                ) : (
                  <>
                    APR per Point:{" "}
                    <span className="clpsyllwtxt">
                      <b>{forMatApy(byDecimals(dmagicAPY, 6).toNumber())} % </b>
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="label">
                {depositedBalance === "0" ? (
                  <>
                    {" "}
                    Your APR:{" "}
                    <span className="clpsyllwtxt">{further.toFixed(2)}% </span>
                  </>
                ) : (
                  <>
                    Your APR:{" "}
                    <span className="clpsyllwtxt">
                      <b> {further.toFixed(2)}% </b>
                    </span>{" "}
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className="bx_cnt_trd">
            <Box className="cnt_trd_box cntnt_box_ash mx_275">
              <Typography component="p">
                Earned
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(formattedEarned)}
                      </span>{" "}
                      {pool.earnedToken}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(formattedEarned)}
                      </span>{" "}
                      {pool.earnedToken}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Unstaked
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(tokenBalance)}
                      </span>{" "}
                      {pool.name}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(tokenBalance)}
                      </span>{" "}
                      {pool.name}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>

              <Typography component="p">
                Staked
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(depositedBalance)}
                      </span>{" "}
                      {pool.name}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(depositedBalance)}
                      </span>{" "}
                      {pool.name}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>

              <Typography component="p">
                Staked Value
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(depositedBalance) * 35}
                      </span>{" "}
                      USD
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(depositedBalance) * 35}
                      </span>{" "}
                      USD
                    </span>{" "}
                  </>
                )}
              </Typography>

              <Typography component="p">
                Staked Points
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">{stakedpoints}</span> PTS
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">{stakedpoints}</span> PTS
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Costs per Point
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">{persValue}</span> USD
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {persValue.toFixed(2)}
                      </span>{" "}
                      USD
                    </span>{" "}
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box className="expndmr_btn"></Box>
    </>
  );
}
