import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";

import {
  useDepositC,
  useRewards,
  useUsers,
} from "../redux/hooks";
import { byDecimals } from "features/helpers/bignumber";


function PoolsTopDetails2OG(pool) {
  const claim = "0x34d52A6C001E8982a0229a15A32EB1a71EEaa5bf";
  const { onDeposit, isPending: isDepositPending } = useDepositC(claim);
  const earned = useRewards(claim);
  const users = useUsers(claim);

  return (
    <>
      <Box className="cllctns_prnt">
        <Container max-width="lg">
          <Grid container spacing={0} className="justify-content-center">
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid item xs={12} lg={12} className="text-center ">
                    <Typography
                      component="h3"
                      className="voult_nft_center_heder"
                    >
                      OG Holder: Your NFT Rewards
                    </Typography>
                    Your Rewards (unclaimed): {byDecimals(earned).toFixed(2)}{" "}
                    PIP <br></br>
                    Lifetime Rewards: {byDecimals(users[0]).toFixed(2)} PIP{" "}
                    <br></br>
                    Refill each 1st of Month! (6x)
                    <br></br>
                    <Box className="btn_outrborder_sdw accordan_inn_btn_prnt accrdan_inn_btn_prnt_v3">
                      <br></br>
                      <button
                        className="btn btn_def_v3"
                        onFocus={(event) => event.stopPropagation()}
                        disabled={isDepositPending}
                        onClick={(event) => {
                          onDeposit();
                        }}
                      >
                        {" "}
                        <span>{"Claim Rewards"}</span>
                      </button>{" "}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default PoolsTopDetails2OG;
