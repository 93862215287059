/* eslint-disable */
import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { inputLimitPass, inputFinalVal, isEmpty } from "features/helpers/utils";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import FormControl from "@material-ui/core/FormControl";

import {
  useAllowance,
  useApprove,
  useDeposit,
  useWithdraw,
  useFetchGetReward,
  useBalanceOf,
  useBalanceNFTOf

} from "../redux/hooks";
import Slider from "@material-ui/core/Slider";
import depositimg from "../../../assets/img/truesign.svg";
import exitsign from "../../../assets/img/exitsign.svg";
import truesign from "../../../assets/img/truesign.svg";

import Moment from "react-moment";
import "moment-timezone";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

Moment.globalLocal = true;


export default function PoolContent({ index, pool}) {

  const tokenBalance = useBalanceOf(pool.tokenAddress, pool.tokenId);
  let balanceSingle = byDecimals(tokenBalance);
  const depositedBalance = useBalanceNFTOf(pool.earnContractAddress, pool.tokenId);

  const allowance = useAllowance(pool.tokenAddress, pool.earnContractAddress);


  const { isPending: isApprovePending, onApprove } = useApprove(
    pool.tokenAddress,
    pool.earnContractAddress,
  );
  const { onGetReward, isPending: isGetRewardPending } = useFetchGetReward(
    pool.earnContractAddress
  );
  const { onDeposit, isPending: isDepositPending } = useDeposit(
    pool.earnContractAddress,
    pool.tokenId
  ); 
  const { onWithdraw, isPending: isWithdarwPending } = useWithdraw(
    pool.earnContractAddress,
    pool.tokenId
  );

  const [balanceToDeposit, setBalanceToDeposit] = useState("0");
  const [balanceToDepositSlider, setBalanceToDepositSlider] = useState(0);
  const [balanceToWithdraw, setBalanceToWithdraw] = useState("0");
  const [balanceToWithdrawSlider, setBalanceToWithdrawSlider] = useState(0);

  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    let sliderNum = 0;
    let inputVal = Number(value.replace(",", ""));
    if (value) {
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }
    switch (type) {
      case "depositedBalance":
        setBalanceToDeposit(inputFinalVal(value, total, tokenDecimals));
        setBalanceToDepositSlider(sliderNum);
        break;
      case "withdrawAmount":
        setBalanceToWithdraw(inputFinalVal(value, total, tokenDecimals));
        setBalanceToWithdrawSlider(sliderNum);
      default:
        break;
    }
  };

  const handleDepositedBalance = (
    index,
    total,
    tokenDecimals,
    event,
    sliderNum
  ) => {
    const val =
      sliderNum == 0
        ? "0"
        : new BigNumber(total).times(sliderNum).div(100).toFixed(0);
    setBalanceToDeposit(byDecimals(val, tokenDecimals));
    setBalanceToDepositSlider(sliderNum);
  };
  const handleWithdrawAmount = (
    index,
    total,
    tokenDecimals,
    event,
    sliderNum
  ) => {
    const val =
      sliderNum == 0
        ? "0"
        : new BigNumber(total).times(sliderNum).div(100).toFixed(0);
    setBalanceToWithdraw(byDecimals(val, tokenDecimals));
    setBalanceToWithdrawSlider(sliderNum);
  };

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function valuetext(value) {
    return `${value}%`;
  }
  ///withdraw slider
  const withdraw = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function withdrawvaluetext(value) {
    return `${value}%`;
  }
  ///Aprove Modal
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return ( 
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className="head_prnt"
        spacing={5}
      >
        <Grid item xs={12} lg={6} className="fr_brdr_right">
          <Grid item xs={12} className="cstmaccrdn2 cstmaccrdn2_ash_01">
            <div className="d-flex input_bx">
              <FormControl>
                <input
                  className="input_fld"
                  value={balanceToDeposit}
                  onChange={changeDetailInputValue.bind(
                    this,
                    "depositedBalance",
                    index,
                    tokenBalance,
                    pool.tokenDecimals
                  )}
                />
              </FormControl>
            </div>

            <div className="progress_bx">
              <Slider
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={marks}
                value={balanceToDepositSlider}
                onChange={handleDepositedBalance.bind(
                  this,
                  index,
                  tokenBalance,
                  pool.tokenDecimals
                )}
              />
            </div>
          </Grid>

          <div className="progress_action accordan_inn_btn_prnt text-right mt-3 accordan_inn_btn_prnt_flx">

            {allowance === false ? (
               <Box className="btn_outrborder_sdw">
                <button
                type="button"
                className="btn btn_def_v3"
                  onClick={() => {
                    handleClose();
                    onApprove();
                  }}
                  disabled={isApprovePending[index]}
                >
                   <span>
                  {isApprovePending
                    ? `${("Approve...")}`
                    : `${("Approve")}`}
                    </span>
                </button>
                </Box>
            ) : (
              <Box className="btn_outrborder_sdw">
                <button
                type="button"
                className="btn btn_def_v3"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={isDepositPending}
                  onClick={(event) => {
                    onDeposit(
                        balanceToDepositSlider >= 100
                          ? tokenBalance
                          : new BigNumber(balanceToDeposit)
                            .multipliedBy(
                              new BigNumber(10).exponentiatedBy(
                                pool.tokenDecimals
                              )
                            )
                            .toString(10)
                      );
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="20"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {("Stake")}
                </button>
                </Box>
            )}
          </div>
        </Grid>


        <Grid item xs={12} lg={6}>

          {/* <h6 className="dpst_ttl">
            Withdraw{" "}
            <span>
              <b>{depositedBalance} </b> {pool.token}
            </span>
          </h6> */}
          <Grid item xs={12} className="cstmaccrdn2 cstmaccrdn2_ash_01">
            <div className="d-flex input_bx">
              <FormControl fullWidth variant="outlined">
                <input
                  className="input_fld"
                  type="text"
                  value={balanceToWithdraw}
                  onChange={changeDetailInputValue.bind(
                    this,
                    "withdrawAmount",
                    index,
                    depositedBalance,
                    pool.tokenDecimals
                  )}
                />
              </FormControl>
            </div>
            <div className="progress_bx progress_bx_rd">
              <Slider
                value={balanceToWithdrawSlider}
                onChange={handleWithdrawAmount.bind(
                  this,
                  index,
                  depositedBalance,
                  pool.tokenDecimals
                )}
                getAriaValueText={withdrawvaluetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={withdraw}
              />
            </div>
          </Grid>
            <div className="progress_action accordan_inn_btn_prnt text-right mt-3 accordan_inn_btn_prnt_flx">
              <Box className="btn_outrborder_sdw">
              <button
                type="button"
                className="btn btn_def_v3 btn_def_v3_v2"
                disabled={isWithdarwPending}
                onClick={() =>
                  onWithdraw(
                    balanceToWithdrawSlider >= 100
                      ? depositedBalance
                      : new BigNumber(balanceToWithdraw)
                        .multipliedBy(
                          new BigNumber(10).exponentiatedBy(
                            pool.tokenDecimals
                          )
                        )
                        .toString(10)
                  )
                }
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={exitsign}
                  alt="logo"
                />
                 <span>
                {isWithdarwPending
                  ? `${("Withdrawing")}`
                  : `${("Unstake")}`}
                  </span>
              </button>
              </Box>{" "}
              <Box className="btn_outrborder_sdw">
              <button
                type="button"
                className="btn btn_def_v3 btn_def_v3_v2"
                onClick={() => onGetReward()}
              >
                <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={truesign}
                    alt="logo"
                  />
                  <span>
                {isGetRewardPending
                  ? `${("Claiming")}`
                  : `${("Claim")}`}
                  </span>
              </button>
              </Box>
            </div>
        </Grid>
      </Grid>
      <div className="othrinfo_as othrinfo_as_v3">
        <Typography component="p">Duration: <span>{pool.duration}</span></Typography>
        <Typography component="p">Startdate: <span>{pool.startdate}</span> </Typography>
        <Typography component="p">Pool fee: <span>{pool.penalty}</span></Typography>
        <Typography component="p">Timelock: <span>NONE!</span></Typography>

      </div>

    {/*   <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="aprvmdl"
      >
        <DialogTitle id="alert-dialog-title">{"Note"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <li> Depositing will reset the Timelock Countdown. </li>
            <li>Withdrawing before Timelock expiration incurs a {pool.penalty} {' '} fee.</li>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="progress_action d-flex w-100 mt-3 justify-content-center">
            {allowance !== "0" && (
              <div className="progress_action text-right mt-3">
                <button
                  className="btn btn-stk"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={isDepositPending}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClose();
                    if (!isEmpty(pool.depostAlert)) {
                      setWaitDialogConfirmJson({
                        content: pool.depostAlert,
                        func: () => {
                          onDeposit(pool, index, false, balanceSingle, event);
                        },
                      });
                      setOpenDialog(true);
                    } else {
                      onDeposit(
                        balanceToDepositSlider >= 100
                          ? tokenBalance
                          : new BigNumber(balanceToDeposit)
                            .multipliedBy(
                              new BigNumber(10).exponentiatedBy(
                                pool.tokenDecimals
                              )
                            )
                            .toString(10)
                      );
                    }
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {("Vault-DepositButton")}
                </button>
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog> */}
    </>
  );
}