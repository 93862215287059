import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { Button } from "@material-ui/core";
//import { makeStyles } from "@material-ui/core/styles";
import {
  useAllowance2,
  useApprove2,
  useAllowance3,
  useApprove3,
  useDeposit,
  useDeposit2,
  useDeposit3,
  useBalanceOf,
  usePriceOf,
  useSupply,
} from "../redux/hooks";

import {
  fetchMaticPrice,
  fetchDmagicPrice,
} from "features/circle/sections/Statistic";

import {
  fetchMaticPrice_ETH,
  fetchPIPrice,
} from "features/circle/sections/Statisticeth";

import Moment from "react-moment";
import "moment-timezone";
import { CardTitle, CardBody } from "reactstrap";
import { Grid, Box, Typography } from "@material-ui/core";

Moment.globalLocal = true;

export default function PoolContent({ index, npool }) {
  const [maticprice, setmaticprice] = useState(0);
  const [ethprice, setethprice] = useState(0);
  const [dmprice, setDmPrice] = useState(0);
  const [piprice, setPiPrice] = useState(0);

  //const allowance = useAllowance(npool.tokenAddress2, npool.earnContractAddress);
  //const allowance2 = useAllowance2(npool.tokenAddress2, npool.earnContractAddress2);
  const allowance2 = useAllowance2(
    npool.tokenAddress2,
    npool.earnContractAddress
  );
  const allowance3 = useAllowance3(
    npool.tokenAddress,
    npool.earnContractAddress
  );

  const { isPending: isApprovePending2, onApprove2 } = useApprove2(
    npool.tokenAddress2,
    npool.earnContractAddress
  );
  const { isPending: isApprovePending3, onApprove3 } = useApprove3(
    npool.tokenAddress,
    npool.earnContractAddress
  );

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress2
  );

  const { onDeposit3, isPending: isDepositPending3 } = useDeposit3(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  useEffect(() => {
    const getMaticInfo = async () => {
      const {
        RAW: {
          BNB: {
            USD: { PRICE },
          },
        },
      } = await fetchMaticPrice();
      const data = {
        MaticPrice: PRICE,
      };
      const dmagicMilPrice = PRICE;
      data.dmagicMilPrice = dmagicMilPrice;
      setmaticprice(data.dmagicMilPrice);
    };
    getMaticInfo();
  }, [maticprice]);

  useEffect(() => {
    const getDmagicInfo = async () => {
      const {
        data: { usdPrice },
      } = await fetchDmagicPrice();

      const data = {
        DmagicPrice: usdPrice,
      };
      const dmagicMilPrice = usdPrice;
      data.dmagicMilPrice = dmagicMilPrice;
      setDmPrice(data.dmagicMilPrice);
    };
    getDmagicInfo();
  }, [dmprice]);

  useEffect(() => {
    const getETHInfo = async () => {
      const {
        RAW: {
          ETH: {
            USD: { PRICE },
          },
        },
      } = await fetchMaticPrice_ETH();
      const data = {
        MaticPrice: PRICE,
      };
      const dmagicMilPrice = PRICE;
      data.dmagicMilPrice = dmagicMilPrice;
      setethprice(data.dmagicMilPrice);
    };
    getETHInfo();
  }, [ethprice]);

  useEffect(() => {
    const getPiInfo = async () => {
      const {
        data: { usdPrice },
      } = await fetchPIPrice();
      const data = {
        MaticPrice: usdPrice,
      };
      const dmagicMilPrice = usdPrice;
      data.dmagicMilPrice = dmagicMilPrice;
      setPiPrice(data.dmagicMilPrice);
    };
    getPiInfo();
  }, [piprice]);

  const tokenBalance = useBalanceOf(npool.tokenAddress, npool.tokenId);
  const price = usePriceOf(npool.tokenAddress, npool.pcs2);
  const supply = useSupply(npool.tokenAddress, npool.pcs2);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  const thi1s =
    ((price[1] * maticprice + price[0] * dmprice) / supply / maticprice) * 1.11;

  const thi2s =
    ((price[1] * maticprice + price[0] * dmprice) / supply / dmprice) * 1.14;

  const thi3s =
    ((price[1] * ethprice + price[0] * piprice) / supply / ethprice) * 2.11;

  const thi4s =
    ((price[1] * ethprice + price[0] * piprice) / supply / piprice) * 2.28;

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        index={index}
        npool={npool}
        className="rspnsv_12"
      >
        <Box className="crcl_actlbx">
          <Box className="crclr_tp_txt_as">
            {npool.id !== "bitcircle" ? (
              <>
                <p>
                  Your available {npool.name}:{" "}
                  <span>{forMat(tokenBalance)}</span>
                </p>
              </>
            ) : (
              <>
                <p>
                  <span></span>bitCircle
                </p>
              </>
            )}
          </Box>
          <video
            className="img-fluid"
            autoPlay="manually"
            loop="loop"
            muted="muted"
            playsinline
          >
            <source
              src={require(`assets/video/${npool.id}.mp4`)}
              type="video/mp4"
            />
          </video>
          <CardBody className="card_body_as_v3">
            <CardTitle tag="h4" className="text-center">
              {npool.id === "scircle" ? (
                <>
                  <Box className="nwprgrss">
                    {npool.chainId === 56 ? (
                      <Typography component="p" className="back_bahama_p ">
                        <span> Buy with {thi1s.toFixed(4)} BNB</span>

                        <br></br>
                      </Typography>
                    ) : (
                      <Typography component="p" className="back_bahama_p ">
                        <span> Buy with {thi3s.toFixed(4)} ETH </span>
                        <br></br>
                      </Typography>
                    )}

                    <div className="progress_action">
                      <Box className="btn_outrborder_sdw accordan_inn_btn_prnt btnprntv3">

                      {npool.chainId === 56? (

                        <button
                          type="button"
                          className="btn btn_def_v3 btn_def_v3_v2"
                          onFocus={(event) => event.stopPropagation()}
                          disabled={isDepositPending}
                          onClick={(event) => {
                            onDeposit(
                              new BigNumber(thi1s)
                                .multipliedBy(
                                  new BigNumber(10).exponentiatedBy(18)
                                )
                                .toString(10)
                            );
                          }}
                        >
                          {" "}
                          <span>{"Buy sCircle"}</span>
                        </button>

                        ) : (
                        <button
                          type="button"
                          className="btn btn_def_v3 btn_def_v3_v2"
                          onFocus={(event) => event.stopPropagation()}
                          disabled={isDepositPending}
                          onClick={(event) => {
                            onDeposit(
                              new BigNumber(thi3s)
                                .multipliedBy(
                                  new BigNumber(10).exponentiatedBy(18)
                                )
                                .toString(10)
                            );
                          }}
                        >
                          {" "}
                          <span>{"Buy sCircle"}</span>
                        </button>
                      )
                      
                      }

                      </Box>
                    </div>
                  </Box>
                  {allowance2 === "0" ? (
                    <div className="progress_action nwprgrss accordan_inn_btn_prnt btnprntv3">
                      {npool.chainId === 56 ? (
                        <Typography component="p" className="btn_btm_p">
                          <span>
                            {" "}
                            Buy with {thi2s.toFixed(4)} PIP {/* 37 PIP */}{" "}
                          </span>
                        </Typography>
                      ) : (
                        <Typography component="p" className="btn_btm_p">
                          <span>
                            {" "}
                            Buy with {thi4s.toFixed(4)} PIP {/* 37 PIP */}{" "}
                          </span>
                        </Typography>
                      )}
                      <Box className="btn_outrborder_sdw">
                        <button
                          type="button"
                          className="btn btn_def_v3"
                          onClick={() => {
                            onApprove2();
                          }}
                          disabled={isApprovePending2[index]}
                        >
                          {isApprovePending2
                            ? `${"Approving..."}`
                            : `${"Approve"}`}
                        </button>
                      </Box>
                    </div>
                  ) : (
                    <>
                      <div className="progress_action nwprgrss accordan_inn_btn_prnt btnprntv3">
                        {npool.chainId === 56 ? (
                          <Typography component="p" className="btn_btm_p">
                            <span>
                              {" "}
                              Buy with {thi2s.toFixed(2)} PIP {/* 37 PIP */}{" "}
                            </span>
                          </Typography>
                        ) : (
                          <Typography component="p" className="btn_btm_p">
                            <span>
                              Buy with {thi4s.toFixed(2)} PIP {/* 37 PIP */}{" "}
                            </span>
                          </Typography>
                        )}
                        <Box className="btn_outrborder_sdw">

                        {npool.chainId === 56 ? (


                          <button
                            type="button"
                            className="btn btn_def_v3"
                            onFocus={(event) => event.stopPropagation()}
                            disabled={isDepositPending2}
                            onClick={(event) => {
                              onDeposit2(
                                new BigNumber(thi2s)
                                  .multipliedBy(
                                    new BigNumber(10).exponentiatedBy(18)
                                  )
                                  .toString(10)
                              );
                            }}
                          >
                            {"Buy"}
                          </button>

                          ) : ( 
                          <button
                            type="button"
                            className="btn btn_def_v3"
                            onFocus={(event) => event.stopPropagation()}
                            disabled={isDepositPending2}
                            onClick={(event) => {
                              onDeposit2(
                                new BigNumber(thi4s)
                                  .multipliedBy(
                                    new BigNumber(10).exponentiatedBy(18)
                                  )
                                  .toString(10)
                              );
                            }}
                          >
                            {"Buy"}
                          </button>
                        ) }
                        
                        </Box>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <> </>
              )}
              {npool.id === "bcircle" || npool.id === "ecircle" ? (
                <>
                  {allowance3 === false ? (
                    <div className="progress_action mt-3 accordan_inn_btn_prnt btnprntv4">
                      <Typography component="p" className="btn_btm_p">
                        <span> Buy with {npool.price2}</span>
                      </Typography>
                      <Box className="btn_outrborder_sdw">
                        <button
                          type="button"
                          className="btn btn_def_v3"
                          onClick={() => {
                            onApprove3();
                          }}
                          disabled={isApprovePending3[index]}
                        >
                          {isApprovePending3
                            ? `${"Approving..."}`
                            : `${"Approve"}`}
                        </button>
                      </Box>
                    </div>
                  ) : (
                    <>
                      <div className="progress_action  mt-3 accordan_inn_btn_prnt btnprntv4">
                        <Typography component="p" className="btn_btm_p">
                          <span> Buy with {npool.price2}</span>
                        </Typography>
                        <Box className="btn_outrborder_sdw">
                          <button
                            type="button"
                            className="btn btn_def_v3"
                            onFocus={(event) => event.stopPropagation()}
                            disabled={isDepositPending3}
                            onClick={(event) => {
                              onDeposit3("5");
                            }}
                          >
                            {" "}
                            {"Buy bCircle"}
                          </button>
                        </Box>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <> </>
              )}

              {npool.id === "bitcircle" ? (
                <>
                  <div className="progress_action mt-3 accordan_inn_btn_prnt btnprntv4">
                    <Typography component="p" className="btn_btm_p">
                      <span> Buy on Marketplace</span>
                    </Typography>
                    <Box className="btn_outrborder_sdw">
                      <Button
                        href="https://www.pi-marketplace.io"
                        target="_blank"
                        type="button"
                        rel="nofollow noopener noreferrer"
                        className="btn btn_def_v3"
                      >
                        <span>{" Marketplace"}</span>
                      </Button>
                    </Box>
                  </div>
                </>
              ) : (
                <> </>
              )}
            </CardTitle>
          </CardBody>
        </Box>
      </Grid>
    </>
  );
}
