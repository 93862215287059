import React from "react";
import { Grid, Box } from "@material-ui/core";
import PoolsTitle from "./sections/PoolsTitle";
//import PoolsTopDetails from "./sections/PoolsTopDetails";
import SectionPoolsV2 from "./sections/SectionPoolsV2";
import { useConnectWallet } from "../home/redux/hooks";
//import Cs from "./sections/Cs";
import PoolsTitleETH from "./sections/PoolsTitleETH";

export default function VaultPage() {
  const { networkId } = useConnectWallet();

  return (
    <>
      {/* <PoolsTopDetails /> */}
      <Box className="nft_prnt footer_spaceing stkng_main_bx">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {networkId === 56 ? <PoolsTitle key={3} /> : <></>}
            {networkId === 1 ? <PoolsTitleETH key={4} /> : <></>}
          </Grid>
          <Grid item xs={12} className="mb-4">
            <Box className="cstmcntnr" key={1}>
              <SectionPoolsV2 key={2} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
