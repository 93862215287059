import React from "react";
import { Container, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

function FourSection() {
  return (
    <>
      <Box className="sc04_prnt sc04_prnt_as_v2">
        <Container className="container z_index">
          <Box className="your_jurney_img_text_prnt">
            <Box>
              <Box
                component="img"
                src="/images/your_jurney_text_img.svg"
                className="img-fluid your_jurney_text_img"
              />
            </Box>
            <Typography className="">
              In addition to yield faming, PI Protocol would like to offer users
              the opportunity to go on a real journey, to take part in exciting
              puzzles, to search for unique treasures and, above all, the
              possibility to collect equally rare NFTs.
            </Typography>
          </Box>
          <Box className="your_jurney_cntnt">
            <Box className="img_text_bx_prnt">
              <Box
                component="img"
                src="/images/yor_jurn_ecap01.svg"
                className="img-fluid yor_jurn_ecap01"
              />
              <Box className="text_bx">
                <Typography component="h4">Excitement</Typography>
                <Typography>
                  Our goal is to create exciting puzzles and riddles over the
                  long term
                </Typography>
              </Box>
            </Box>
            <Box className="img_text_bx_prnt">
              <Box
                component="img"
                src="/images/yor_jurn_ecap02.svg"
                className="img-fluid yor_jurn_ecap01"
              />
              <Box className="text_bx">
                <Typography component="h4">Continuous growth</Typography>
                <Typography>New NFTs and puzzles are created weekly</Typography>
              </Box>
            </Box>
            <Box className="btn_your_jurney_flex_bx">
              <Box className="buy_pi_ntn_prnt">
                <Box className="buy_pi_btn_box_bg_prnt">
                  <Box
                    component="img"
                    src="/images/buy_pi_btn_box_bg.png"
                    className="img-fluid buy_pi_btn_box_bg"
                  />
                </Box>
                <Box className="buy_pi_arrow_btn_prnt">
                <Link to="/dex" className="buy_pi_arrow_btn">
                  <span>buy pi</span>
                </Link>
                </Box>
              </Box>
              <Box className="right_design_pasn_box">
                <Box className="img_text_bx_prnt">
                  <Box
                    component="img"
                    src="/images/yor_jurn_ecap03.svg"
                    className="img-fluid yor_jurn_ecap01"
                  />
                  <Box className="text_bx">
                    <Typography component="h4">Awesome Design</Typography>
                    <Typography>
                      Together, with our partner we create impressive and unique
                      NFT's
                    </Typography>
                  </Box>
                </Box>
                <Box className="img_text_bx_prnt">
                  <Box
                    component="img"
                    src="/images/yor_jurn_ecap04.svg"
                    className="img-fluid yor_jurn_ecap01"
                  />
                  <Box className="text_bx">
                    <Typography component="h4">Passion</Typography>
                    <Typography>
                      Unleash your passion for the treasure hunt
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
        {/* <CoverImgStyle alt="cover" src={"/static/img/sc04_bg.jpg"} /> */}
      </Box>
    </>
  );
}

export default FourSection;
