import React from "react";
import { Grid, Box } from "@material-ui/core";
import MetaverseTitle from "./MetaverseTitle";

export default function Metaverse() {
  return (
    <>
      <Box className="mtvvrs_main_bx footer_spaceing meta">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MetaverseTitle />
          </Grid>
        </Grid>
        <Box className="cmng_sn_bx_v3">
          <Box className="two_img_bx">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box
                component="img"
                className="img-fluid metaverse_img01"
                src="/images/metaverse_img01.png"
                alt=""
              />
              </Grid>
              <Grid item xs={12}>
                <Box className="coming_soon_gr_text_prnt">
                  <Box
                    component="img"
                    className="img-fluid coming_soon_gr_text"
                    src="/images/coming_soon_gr_text.png"
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="metaverse_img02_prnt">
                  <Box
                    component="img"
                    className="img-fluid"
                    src="/images/metaverse_img02.png"
                    alt=""
                  />
                </Box>
              </Grid>
            </Grid>
            
            
          </Box>
        </Box>
      </Box>
    </>
  );
}
