import { erc20ABI, erc1155 } from "../configure";
import BigNumber from "bignumber.js";

export const fetchBalance = async ({ web3, address, tokenAddress }) => {
  // console.log(`=====================================fetchBalance begin=====================================`)
  if (!tokenAddress) {
    const ethBalance = await web3.eth.getBalance(address);
    return ethBalance;
  }

  if (tokenAddress === "0xcF5DbC399F3112bac87f57018A3389911241237B") {
    const contract2 = new web3.eth.Contract(erc1155, tokenAddress);
    const balance = await contract2.methods
      .balanceOf(address, 1)
      .call({ from: address });
    return new BigNumber(balance).toNumber();
  }

  const contract = new web3.eth.Contract(erc20ABI, tokenAddress);
  const balance = await contract.methods
    .balanceOf(address)
    .call({ from: address });

  return new BigNumber(balance).toNumber();
};
