import React from "react";
import {
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import FaqTitle from "./FaqTitle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function Faq() {
  return (
    <>
      <Box className="faq_main_box footer_spaceing">
        <FaqTitle />
        <Box className="cstmcntnr">
          <Typography component="h3" className="faq_cntr_head">
            FAQ
          </Typography>
          <Grid container spacing={"3"}>
            <Grid item xs={12} lg={12}>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion className="grdnbrdr_bx">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg">1</span>
                      <Typography>What is PI Protocol?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        PI Protocol is a self-sustaining yield ecosystem that
                        allows you to make your NFT work for you instead of
                        letting it linger and do nothing in your wallet.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg02">2</span>
                      <Typography>What are PI Protocol tokenomics?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Transaction fee: <span className="yllwtxt">4.5%</span>,
                        allocated as follows: <br />
                        <ul>
                          <li>
                            <span className="yllwtxt">1.5%</span> Treasure Game
                          </li>
                          <li>
                            <span className="yllwtxt">0.5%</span> Development
                            fund
                          </li>
                          <li>
                            <span className="yllwtxt">0.5%</span> Burns, removed
                            from supply forever
                          </li>
                          <li>
                            <span className="yllwtxt">2.0%</span> Circle Pool,
                            infinite rewards for Circle Holders (circle NFT
                            staking, see below)
                          </li>
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg03">3</span>
                      <Typography>
                        How do I earn passive income through PI-Protocol?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Buy sCircles (“small circles”) or bCircles (“big
                        circles”) and stake them for rewards. By staking PIP
                        (the fundamental token of PI PROTOCOL) one can earn more
                        PIP or optionally partner tokens. One can also earn PIP
                        by staking prize NFTs or partner NFTs (see questions 13
                        and 14 respectively).
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg04">4</span>
                      <Typography>
                        What is the difference between a sCircle and a bCircle?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        A sCircle is a LP token that also acts as an NFT. A
                        bCircle is also an LP token that also acts as an NFT,
                        but it is equal to <span className="yllwtxt">5</span>{" "}
                        sCircles.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg05">5</span>
                      <Typography>
                        How many PIP do I need to buy a sCircle?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        It depends on the price of PIP. Based on the latest
                        price, you need around{" "}
                        <span className="yllwtxt">25</span> PIP or PI tokens to
                        buy a sCircle.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg">6</span>
                      <Typography>
                        How many PIP do I need to buy a bCircle?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        It depends on the price of PIP. Based on the latest
                        price, you need around{" "}
                        <span className="yllwtxt">125</span> PIP, or{" "}
                        <span className="yllwtxt">5</span> sCircles to buy a
                        bCircle.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg07">7</span>
                      <Typography>
                        What is the easiest way to buy a sCircle or a bCircle?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Buy it using BNB on the Circles page -{" "}
                        https://www.pi-protocol.io/#/circle
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg">8</span>
                      <Typography>
                        What happens when I buy a sCircle using BNB?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        From your <span className="yllwtxt">100%</span> BNB
                        value, <span className="yllwtxt">50%</span> of it will
                        be automatically converted to pBNB (see Question 9
                        below) and another <span className="yllwtxt">50%</span>{" "}
                        will be automatically used to buy PIP or PI tokens on
                        your behalf. After locking{" "}
                        <span className="yllwtxt">50%</span> of pBNB and{" "}
                        <span className="yllwtxt">50%</span> of PIP in the LP
                        pool, the smart contract will mint an LP wrapper token
                        called sCircle for you.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg02">9</span>
                      <Typography>What is pBNB?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        pBNB is a synthetic BNB token, minted at a{" "}
                        <span className="yllwtxt">1:1</span> ratio to BNB. This
                        token is needed to convert BNB into PI.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg03">
                        10
                      </span>
                      <Typography>Can sCircles be traded?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        You can sell your sCircle back to the ecosystem for{" "}
                        <span className="yllwtxt">50%</span> of your initial
                        capital or list it on PI Protocol open marketplace.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg04">
                        11
                      </span>
                      <Typography>Can bCircles be traded?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        You can list your bCircle on PI Protocol open
                        marketplace.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg05">
                        12
                      </span>
                      <Typography>
                        What are PI Protocol NFT vaults?: How do the NFT Vaults
                        work?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        NFT vaults give your NFTs an actual use case and in the
                        case of partner NFT vaults. PI Protocol offers many
                        types of NFTs for staking, including sCircle and bCircle
                        NFTs, prize NFTs won through treasure hunts, and partner
                        NFTs that allow PI Protocol the opportunity to create
                        partnerships with other NFT and token projects to get
                        additional free marketing exposure.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg">13</span>
                      <Typography>
                        What happens when I stake my NFT prize into PI Protocol
                        NFT Vaults?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        The contract will automatically sell{" "}
                        <span className="yllwtxt">200</span> PIP or PI tokens
                        for BNB and take those BNB over to Pancakeswap and stake
                        them in the CAKE/BNB staking pool for rewards, and you
                        will receive a large portion of those rewards. In
                        summary, PI Protocol is lending you{" "}
                        <span className="yllwtxt">200</span> PIP or PI tokens if
                        you stake your NFT prize in the NFT vaults.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg07">
                        14
                      </span>
                      <Typography>
                        What happens when I stake a Partner NFT in PI-Protocol
                        NFT Vaults?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        The contract will automatically sell{" "}
                        <span className="yllwtxt">60</span> PIP or PI tokens for
                        BNB and take those BNB over to Pancakeswap and stake
                        them in the CAKE/BNB staking pool for rewards and you
                        will receive a large portion of those rewards. In
                        summary, PI Protocol is lending you{" "}
                        <span className="yllwtxt">60</span> PIP or PI tokens if
                        you stake a partner NFT in the NFT vaults.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg">15</span>
                      <Typography>
                        What is the total supply of PIP token?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <span className="yllwtxt">600,000</span> excluding
                        burns.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg02">
                        16
                      </span>
                      <Typography>
                        What does “deflationary” mean with respect to
                        cryptocurrency?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        In crypto terms, deflationary means that the circulating
                        supply of coins or tokens will decrease over time,
                        making them more valuable. It stands in contrast to
                        “inflationary”, where tokens lose value because more are
                        being minted.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg03">
                        17
                      </span>
                      <Typography>What is a Price Floor</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        As the liquidity is locked and new PI tokens cannot be
                        minted, PI token has a Price Floor. This means that it
                        will never drop under a certain price due to
                        mathematical calculations.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg04">
                        18
                      </span>
                      <Typography>
                        What is the Compound Button on the NFT Vaults page?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        The compound button helps to automatically compound the
                        staking rewards and updates the rewards for every user
                        staking in the vault.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="acrdng_main_box acrdng_main_box_v3">
                <Box
                  component="img"
                  src="/images/roadmap_top_part.png"
                  className="roadmap_top_part"
                />
                <Box className="roadmp_center_bg" />
                <Box
                  component="img"
                  src="/images/roadmap_bttm_part.png"
                  className="roadmap_bttm_part"
                />
                <Box className="rght_cntnt">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text_cercle_bg text_cercle_bg05">
                        19
                      </span>
                      <Typography>
                        I am getting errors when I try to convert my 1.25
                        PIP-pBNB LP to 1 sCircle.
                        <br />
                        How do I fix that?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Always use a round number when converting your PIP-pBNB
                        LP to sCircle. In the example above, use 1 PIP-pBNB LP
                        instead of <span className="yllwtxt">1.25</span>, or
                        else your transaction will keep failing and you will
                        keep losing gas fees.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
