import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  useDepositC,
  useRewards,
  //useTotalClaim,
  //useTotalPending,
  useUsers,
  fetchPanthers,
  useBalanceOf,
} from "../redux/hooks";
import { byDecimals } from "features/helpers/bignumber";
import { useConnectWallet } from "../../home/redux/hooks";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function PoolsTopDetails2(pool) {
  const claim = "0xB6C910c9F8ED835A8757Fab6Ce8B20F43FD689Ee";
  const tokenaddi = "0xe651657fa2355261bbf4037cf6af43825af5da22";
  const { onDeposit, isPending: isDepositPending } = useDepositC(claim);
  const { address, web3 } = useConnectWallet();
  const earned = useRewards(claim);
  const tokenBalance = useBalanceOf(tokenaddi);
  //const totalClaim = useTotalClaim(claim);
  //const totalPending = useTotalPending(claim);
  const users = useUsers(claim);
  const [fetchingNFT, setFetchingNFT] = useState(true);
  const [accountsX, setAccountsX] = useState(null);
  const [pp, setPP] = useState(0);
  const classes = useStyles();
  const [share, setShare] = useState(0)

  const fetchNFTs = async () => {
    try {
      const { data } = await fetchPanthers();
      const newData = data[0].slice(0, 10);
      setAccountsX(newData);

     const filtered = data[0].filter(employee => {
     return employee.holderAddress === address.toLowerCase()
    });

      setPP(filtered[0].supplyPerHolder);

      const totalPoints = (data[0].reduce((a,v) =>  a = a + v.supplyPerHolder , 0 ))

      const math = ( filtered[0].supplyPerHolder / totalPoints ) * 100
            
      setShare(math)
      

      setFetchingNFT(false); 
    } catch {
      setFetchingNFT(false);
    }
  };

  useEffect(() => {
    if (web3 && address && fetchingNFT === true && accountsX === null) {
      fetchNFTs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, address, fetchingNFT, accountsX]);

  return (
    <>
      <Box className="cllctns_prnt">
        <Container max-width="lg">
          <Grid container spacing={0} className="justify-content-center">
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid item xs={12} lg={12} className="text-center ">
                    <Typography
                      component="h3"
                      className="voult_nft_center_heder"
                    >
                      Gamification Staking: Your NFT Rewards
                    </Typography>
                    Your Rewards (unclaimed): {byDecimals(earned).toFixed(2)}{" "}
                    PIP <br></br>
                    Lifetime Rewards: {byDecimals(users[0]).toFixed(2)} PIP{" "}
                    <br></br>
                    Pers. APR {(((24000 / 100 * share) / (tokenBalance * 30)) * 100).toFixed(2)} %
                    <br></br>
                    {/*                     Pool Total Claimed: {byDecimals(totalClaim).toFixed(2)} PIP
                    <br></br>
                    Pool Total UnClaimed: {byDecimals(totalPending).toFixed(
                      2
                    )}{" "}
                    PIP<br></br> */}
                    <Box className="btn_outrborder_sdw accordan_inn_btn_prnt accrdan_inn_btn_prnt_v3">
                      <br></br>
                      <button
                        className="btn btn_def_v3"
                        onFocus={(event) => event.stopPropagation()}
                        disabled={isDepositPending}
                        onClick={(event) => {
                          onDeposit();
                        }}
                      >
                        {" "}
                        <span>{"Claim Rewards"}</span>
                      </button>{" "}
                    </Box>
                    <Box className="trnsctn_hash_bx">
                      <Box className="brdg_trnsctn_hstry_main_bx brdg_main_bx brdg_main_bx_ash brdg_main_bx_ash02 footer_spaceing">
                      <span>Your </span> NFTs: {tokenBalance}
                          <br></br>
                          Your Points: / Share: <br></br>
                          <span>{pp} pts. / {share.toFixed(2)} %</span> 
                          <br></br>
                        <Box className="tbl_main_bx">
                          <span>{"Top 10 Holder"}</span><br></br>
                          <span>{"*update every 24h"}</span>
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">ID</TableCell>
                                  <TableCell align="left">Account</TableCell>
                                  <TableCell align="left">
                                    Total Points
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {accountsX?.map((row, index) => (
                                  <TableRow key={row.token}>
                                    <TableCell align="left">{index}</TableCell>
                                    <TableCell align="left">
                                      {" "}
                                      {row.holderAddress.slice(0, 13)}...
                                      {row.holderAddress.slice(-13)}
                                    </TableCell>

                                    <TableCell align="left">
                                      {" "}
                                      {row.supplyPerHolder} Points
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default PoolsTopDetails2;
