import React, { useState } from "react";
import { Box, Button, GridItem, Heading, Image, Text } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import {
  useDeposit,
  useDeposit2,
  useOwner,
  useBNBPrice1,
  useNatPrice1,
  useAllowance,
  useApprove2,
  useMax,
} from "../redux/hooks";
import { byDecimals } from "features/helpers/bignumber";

export default function PoolContent3({ index, npool }) {
  const [isActiveEight, setActiveEight] = useState(false);
  const toggleClassEight = () => {
    setActiveEight(!isActiveEight);
  };

  const [isActiveAsh, setActiveAsh] = useState(false);
  const toggleClassAsh = () => {
    setActiveAsh(!isActiveAsh);
  };

  const owner = useOwner(npool.earnContractAddress);
  const max = useMax(npool.earnContractAddress);
  const bnbprice = useBNBPrice1(npool.earnContractAddress);
  let bnbpricing = byDecimals(bnbprice, npool.tokeDecimals);

  const netprice = useNatPrice1(npool.earnContractAddress);
  let natpricing = byDecimals(netprice, npool.tokeDecimals);

  const allowance = useAllowance(
    npool.tokenAddress2,
    npool.earnContractAddress
  );

  const { isPending: isApprovePending2, onApprove2 } = useApprove2(
    npool.tokenAddress2,
    npool.earnContractAddress
  );

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress2
  );

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  return (
    <>
      <GridItem
        colSpan={[12, 12, 6, 4]}
        data-aos="fade-up"
        data-aos-delay="1000"
        data-aos-duration="1000"
      >
        <Box className="cntmt_info_btn_prnt">
          <Button
            className={isActiveAsh ? "info_btn_02 active" : "info_btn_02"}
            onClick={toggleClassAsh}
          ></Button>
          <Button
            className={isActiveEight ? "info_btn active" : "info_btn"}
            onClick={toggleClassEight}
          ></Button>
          <Box
            className={
              isActiveEight ? "flip-card-inner active" : "flip-card-inner"
            }
          >
            <Box className="same_nine_ractangle">
              <Box
                className={
                  isActiveAsh
                    ? "same_nine_ractangle_inn_video activets"
                    : "same_nine_ractangle_inn_video"
                }
              >
                <Box className="blue_hole_video_prnt">
                  <video className="width_266_video" loop playsinline autoPlay muted>
                    <source
                      src={require(`../../../images/${npool.nft}`)}
                      type="video/mp4"
                    />
                  </video>
                </Box>
              </Box>
              <Box className={npool.bg}>
                <Box className={npool.bg2}>
                  <Box className="hp_pad_box">
                    <Heading as="h2">{npool.title}</Heading>
                    <Text>
                      {npool.desc}
                      <br></br>
                      <br></br>
                      Items sold: {owner} of {npool.maxmint} <br></br>Max: {max}{" "}
                      per Wallet <br></br>
                      Future Utility: {npool.util}
                    </Text>
                  </Box>
                  <Box className="teresr_two_btn">
                    <Box className="buy_btn_min_box buy_min_box">
                      <Image
                        src="/img/buy_nft_btn_img.png"
                        alt=""
                        className="buy_nft_btn_img"
                      />
                      <Heading as="h2">
                        1 NFT
                        <span>
                          {" "}
                          {forMat(bnbpricing)} {npool.cur}
                        </span>
                      </Heading>
                      <Button
                        className="buy_nft_btn"
                        onFocus={(event) => event.stopPropagation()}
                        disabled={isDepositPending}
                        onClick={(event) => {
                          onDeposit(1, bnbprice);
                        }}
                      >
                        Buy NFT
                      </Button>
                    </Box>

                    {npool.multi === "1" ? (
                      <Box className="buy_btn_min_box buy_min_box">
                        <Image
                          src="/img/buy_nft_btn_img.png"
                          alt=""
                          className="buy_nft_btn_img"
                        />
                        <Heading as="h2">
                          3 NFTs
                          <span>
                            {" "}
                            {forMat(bnbpricing * 3)} {npool.cur}
                          </span>
                        </Heading>
                        <Button
                          className="buy_nft_btn"
                          onFocus={(event) => event.stopPropagation()}
                          disabled={isDepositPending2}
                          onClick={(event) => {
                            onDeposit(3, bnbprice * 3);
                          }}
                        >
                          Buy NFTs
                        </Button>
                      </Box>
                    ) : (
                      <></>
                    )}

                    {allowance === "0" ? (
                      <>
                        {npool.withpi === "1" ? (
                          <Box className="buy_btn_min_box buy_min_box">
                            <Image
                              src="/img/buy_nft_btn_img.png"
                              alt=""
                              className="buy_nft_btn_img"
                            />
                            <Heading as="h2">
                              1 NFT<span> {forMat(natpricing)} PIP</span>
                            </Heading>
                            <Button
                              className="buy_nft_btn"
                              onClick={() => {
                                onApprove2();
                              }}
                              disabled={isApprovePending2[index]}
                            >
                              {isApprovePending2
                                ? `${"Approving..."}`
                                : `${"Approve for PIP"}`}
                            </Button>
                          </Box>
                        ) : (
                          <> </>
                        )}
                        {npool.multi === "1" ? (
                          <Box className="buy_btn_min_box buy_min_box">
                            <Image
                              src="/img/buy_nft_btn_img.png"
                              alt=""
                              className="buy_nft_btn_img"
                            />
                            <Heading as="h2">
                              3 NFTs<span> {forMat(natpricing * 3)} PIP</span>
                            </Heading>
                            <Button
                              className="buy_nft_btn"
                              onClick={(event) => {
                                onDeposit2(1);
                              }}
                              disabled={isApprovePending2[index]}
                            >
                              {isApprovePending2
                                ? `${"Approving..."}`
                                : `${"Approve for PIP"}`}
                            </Button>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        {npool.withpi === "1" ? (
                          <Box className="buy_btn_min_box buy_min_box">
                            <Image
                              src="/img/buy_nft_btn_img.png"
                              alt=""
                              className="buy_nft_btn_img"
                            />
                            <Heading as="h2">
                              1 NFT<span> {forMat(natpricing)} PIP</span>
                            </Heading>
                            <Button
                              className="buy_nft_btn"
                              onFocus={(event) => event.stopPropagation()}
                              disabled={isDepositPending2}
                              onClick={(event) => {
                                onDeposit2(1);
                              }}
                            >
                              Buy NFT
                            </Button>
                          </Box>
                        ) : (
                          <></>
                        )}

                        {npool.multi === "1" && npool.withpi === "1" ? (
                          <Box className="buy_btn_min_box buy_min_box">
                            <Image
                              src="/img/buy_nft_btn_img.png"
                              alt=""
                              className="buy_nft_btn_img"
                            />
                            <Heading as="h2">
                              3 NFTs<span> {forMat(natpricing * 3)} PIP</span>
                            </Heading>
                            <Button
                              className="buy_nft_btn"
                              onFocus={(event) => event.stopPropagation()}
                              disabled={isDepositPending2}
                              onClick={(event) => {
                                onDeposit2(3);
                              }}
                            >
                              Buy NFTs
                            </Button>
                          </Box>
                        ) : (
                          <> </>
                        )}
                      </>
                    )}
                    <span className="buy_nft_btn_txt">
                      {npool.note}
                      <br></br>
                    </span>
                    <span className="buy_nft_btn_txt">
                      {npool.note2}
                      <br></br>
                    </span>
                    <br></br>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="flip-card-back">
              <Box className="same_nine_ractangle">
                <Box className="same_nine_ractangle_inn_back">
                  <Image
                    src={require(`../../../images/${npool.logo}`)}
                    alt=""
                    className="tres_back_img"
                  />
                  <Box className="back_inn_p">
                    <Text>
                      {npool.desc2} <br /> <br />
                    </Text>
                    <Text>
                      {npool.desc3} <br />
                      <br />
                    </Text>
                    <Text>
                      {npool.desc4}
                      <br />
                      {npool.desc5}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="galla_img_links_box">
            <Box className="galla_img_links_box_inn">
              <Button
                as="a"
                href={npool.link}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="galla_img_links_first"
              >
                <Image
                  src="/img/galla_img_links_ic01.svg"
                  alt=""
                  className="galla_img_links_ic01"
                />
              </Button>

              {npool.TG === "" ? (
                <></>
              ) : (
                <>
                  <Button
                    as="a"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={npool.TG}
                    className="galla_img_links_second"
                  >
                    {" "}
                    <Image
                      src="/img/galla_img_links_ic02.svg"
                      alt=""
                      className="galla_img_links_ic0"
                    />
                  </Button>
                </>
              )}

              {npool.TW === "" ? (
                <></>
              ) : (
                <>
                  <Button
                    as="a"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={npool.TW}
                    className="galla_img_links_second"
                  >
                    {" "}
                    <Image
                      src="/img/galla_img_links_ic04.svg"
                      alt=""
                      className="galla_img_links_ic0"
                    />
                  </Button>
                </>
              )}

              {npool.DC === "" ? (
                <></>
              ) : (
                <>
                  {" "}
                  <Button
                    as="a"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={npool.DC}
                    className="galla_img_links_second"
                  >
                    {" "}
                    <Image
                      src="/img/galla_img_links_ic03.svg"
                      alt=""
                      className="galla_img_links_ic0"
                    />
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </GridItem>
    </>
  );
}
