export const launchpadpools = [
  /*   {
      chainId: 1,
      name: "bbc",
      id: "6",
      tokenDecimals: 18,
      tokenAddress: "",
      tokenAddress2: "",
      earnContractAddress: "0x053abB888946d56635f795DDA97e6a7fF6217Fd5",
      desc: "Charity Dogs Club #1",
      desc2: "Charity Dogs, is a collection of 5,555 randomly generated galactic dogs in Ethereum Chain. This collection helps foundations responsible for saving street dogs.",
      desc3: "",
      desc4: "",
      desc5: "",
      nft: "cdc.mp4",
      title: "NFT",
      maxmint: "5.555",
      multi: "1",
      util: "yes",
      bg: "same_nine_ractangle_inn same_nine_ractangle_inn09b",
      bg2: "blr_box",
      link: "https://twitter.com/dogs_charity",
      TG: "",
      TW: "https://twitter.com/dogs_charity",
      DC: "https://discord.gg/4VmhcTzT",
      twitter: "",
      discord: "discord.gg/4VmhcTzT",
      logo: "cd2.PNG",
      withpi: "0",
      active: "yes",
      note: "*** Partner Project sales! Pi is not resposible for the Collection or any further Utility.",
      note2: "Do your own research before buying!",
      cur: "ETH"
    }, */
  {
    chainId: 56,
    name: "bbc",
    id: "6",
    tokenDecimals: 18,
    tokenAddress: "",
    tokenAddress2: "",
    earnContractAddress: "0xE2385f3CcbE3d290F2fb9b18fD68ABA73773C24c",
    desc: "Cards of Loving Dragons ($COLD)",
    desc2: "A unique Deck of Cards of Loving Dragons,56 Cards only (1 of 1s) Designed by Shelley from DHD for the 💜COLD💜 project only. Every card will return a long-term passive gifting system,with chances of winning wheel monthly prizes . Join TG page for more details and questions.",
    desc3: "",
    desc4: "",
    desc5: "",
    nft: "",
    title: "NFT",
    maxmint: "56",
    multi: "1",
    util: "yes",
    bg: "same_nine_ractangle_inn same_nine_ractangle_inn08b",
    bg2: "blr_box",
    link: "https://twitter.com/Blairsville210",
    TG: "https://t.me/deckofcards56",
    TW: "https://twitter.com/Blairsville210",
    DC: "",
    twitter: "https://twitter.com/babybot68",
    discord: "",
    logo: "HAPE.PNG",
    withpi: "0",
    active: "yes",
    note: "*** Direct artist sales! Pi is not resposible for the Collection or any further Utility.",
    note2: "Do your own research before buying!",
    cur: "BNB"

  },

  /*   {
    chainId: 97,
    name: "oir",
    id: "5",
    tokenDecimals: 18,
    tokenAddress: "",
    tokenAddress2: "",
    earnContractAddress: "0xF218d9762dDEfF969c8e02cBb5AEfCCd2EAD5bb3",
    desc: "OIR - Revenue Ticket",
    desc2: "Only 200 ÓIR Utility NFT’s will ever exist",
    desc3:
      "Each NFT held will receive 0.5% of the projects distributed profits that can be claimed on a weekly basis, No lock-in period for staking,",
    desc4:
      "Profit distribution: 60% of profits will be distributed weekly to NFT holders, 20% of Profits to Marketing and Development wallet, 20% of profits to the team",
    desc5: "",
    nft: "oir.mp4",
    title: "NFT",
    maxmint: "200",
    multi: "0",
    util: "yes",
    bg: "same_nine_ractangle_inn same_nine_ractangle_inn08a",
    bg2: "blr_box1",
    link: "https://oir.finance/",
    tg: "",
    twitter: "",
    discord: "",
    logo: "oir.png",
    withpi: "0",
    active: "yes",
    cur: "BNB"
  }, */
  /*   {
      chainId: 56,
      name: "Free",
      id: "4",
      tokenDecimals: 18,
      tokenAddress: "",
      tokenAddress2: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE",
      earnContractAddress: "0x718230fdECE67b92fC051b472b084aaB7DA086b6",
      desc: "Pi - Community NFT",
      desc2:
        "PI is a Defi project built on BSC, that includes yield farming for NFTs on NFT aggregator Vaults, extended by a price floor sweeper mechanic!",
      desc3:
        "A full service provider for NFT Utility, we make Artist dreams come true!",
      desc4:
        "PI Protocol introduces the Pi Protocol Community NFT!  This is FREE to anyone who wants one!  Supplies are still limited, so spread the word to your family and friends!",
      desc5: "",
      nft: "Com.webm",
      title: "NFT",
      maxmint: "10000",
      multi: "0",
      util: "no",
      bg: "same_nine_ractangle_inn same_nine_ractangle_inn08",
      bg2: "blr_box",
      link: "https://pi-protocol.io",
      tg: "https://t.me/PIProtocol",
      twitter: "https://twitter.com/PiProtocol",
      discord: "https://discord.com/invite/jSP329kDaj",
      logo: "tres_back_img.png",
      withpi: "1",
      active: "yes",
      TG: "",
      TW: "",
      DC: "",
      note: "",
      cur: "BNB"
    }, */

  /*   {
      chainId: 56,
      name: "Miami",
      id: "1",
      tokenDecimals: 18,
      tokenAddress: "",
      tokenAddress2: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE",
      earnContractAddress: "0xf98411AAa15FBa2Fa37dDE4243DA377C1543410c",
      desc: "Pi Miami - Convention NFT",
      desc2:
        "PI is a Defi project built on BSC, that includes yield farming for NFTs on NFT aggregator Vaults, extended by a price floor sweeper mechanic!",
      desc3:
        "A full service provider for NFT Utility, we make Artist dreams come true!",
      desc4:
        " In commemoration of Miami NFT week, PI Protocol proudly presents this unique animated artwork! Join the ever expanding vortex of NFT owners!",
      desc5: "This is a limited edition series, so get yours today!",
      nft: "pi_video.mp4",
      title: "NFT",
      maxmint: "314",
      multi: "1",
      util: "yes",
      bg: "same_nine_ractangle_inn same_nine_ractangle_inn08",
      bg2: "blr_box",
      link: "https://pi-protocol.io",
      tg: "https://t.me/PIProtocol",
      twitter: "https://twitter.com/PiProtocol",
      discord: "https://discord.com/invite/jSP329kDaj",
      logo: "tres_back_img.png",
      withpi: "1",
      active: "yes",
      TG: "",
      TW: "",
      DC: "",
      note: "",
      cur: "BNB"
    }, */
  /*   {
      chainId: 56,
      name: "Child",
      id: "2",
      tokenDecimals: 18,
      tokenAddress: "",
      tokenAddress2: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE",
      earnContractAddress: "0xcf44A553da623a900977884e0e8EF36987306567",
      desc: "Pi Child - Special Edition NFT",
      desc2:
        "PI is a Defi project built on BSC, that includes yield farming for NFTs on NFT aggregator Vaults, extended by a price floor sweeper mechanic!",
      desc3:
        "A full service provider for NFT Utility, we make Artist dreams come true!",
      desc4:
        "Are you a Child of PI?   Take the first step of discovery into the beauty, mystery and utility of the Pi Protocol ecosystem with the purchase of this limited edition NFT!",
      desc5: "",
      nft: "Child.webm",
      title: "NFT",
      maxmint: "50",
      multi: "1",
      util: "yes",
      bg: "same_nine_ractangle_inn same_nine_ractangle_inn08",
      bg2: "blr_box",
      link: "https://pi-protocol.io",
      tg: "https://t.me/PIProtocol",
      twitter: "https://twitter.com/PiProtocol",
      discord: "https://discord.com/invite/jSP329kDaj",
      logo: "tres_back_img.png",
      withpi: "1",
      active: "yes",
      TG: "",
      TW: "",
      DC: "",
      note: "",
      cur: "BNB"
    }, */
  /*   {
      chainId: 56,
      name: "Ticket",
      id: "3",
      tokenDecimals: 18,
      tokenAddress: "",
      tokenAddress2: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE",
      earnContractAddress: "0x12f4588c95eA148aBD6978B010520c4113B19F2F",
      desc: "Pi - Presale Ticket",
      desc2:
        "PI is a Defi project built on BSC, that includes yield farming for NFTs on NFT aggregator Vaults, extended by a price floor sweeper mechanic!",
      desc3:
        "A full service provider for NFT Utility, we make Artist dreams come true!",
      desc4:
        "Join the inner Circle!  Keep this NFT in your wallet to gain special early access to future sales!  The utility of this NFT is undeniable!",
      desc5: "",
      nft: "Ticket.mp4",
      title: "NFT",
      maxmint: "50",
      multi: "0",
      util: "yes",
      bg: "same_nine_ractangle_inn same_nine_ractangle_inn08",
      bg2: "blr_box",
      link: "https://pi-protocol.io",
      tg: "https://t.me/PIProtocol",
      twitter: "https://twitter.com/PiProtocol",
      discord: "https://discord.com/invite/jSP329kDaj",
      logo: "tres_back_img.png",
      withpi: "1",
      active: "yes",
      TG: "",
      TW: "",
      DC: "",
      note: "",
      cur: "BNB"
    }, */
];
