import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
//import { PIPXIStakingAbi } from "features/configure/abi";
import {
  useBalanceOf,
  useBalanceOfVault,
  useTimelock,
  useMaxNFT,
  //useShareValue,
} from "../redux/hooks";
import Countdown from "react-countdown";
//import { useConnectWallet } from "../../home/redux/hooks";

import Moment from "react-moment";
import "moment-timezone";
Moment.globalLocal = true;

const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100000))
    .toNumber();
};

export default function PoolHeader({ pool }) {
  //const { web3, address } = useConnectWallet();
  const tokenBalance = useBalanceOf(pool.rewardToken);

  const depositedBalance = useBalanceOfVault(pool.vaultId);
  //const [share, setShare] = useState(0);

  const timelock = useTimelock(pool.vaultId);
  //const share =  useShareValue(pool.vaultId);
  const totalStaked = depositedBalance;
  //const finalShre = share // BASE;
  //const IStaking = "0x23620179Cf01e40478E8576210c72aF8b639d5D3";

  const maxnft = useMaxNFT(pool.vaultId, pool.lpaddress);

 /*  const fetchShare = useCallback(async () => {
    const contract = new web3.eth.Contract(PIPXIStakingAbi, IStaking);
    //const data = await contract.methods
      //.xTokenShareValue(pool.vaultId)
      //.call({ from: address });
    //setShare(data);
  }, [address, pool.vaultId, web3]);
 *//* 
  useEffect(() => {
    if (web3 && address && totalStaked > 10000000000) {
      //fetchShare();
      let refreshInterval = setInterval(fetchShare, 25000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchShare, totalStaked]);
 */
  return (
    <>
      <Grid container direction="row" alignItems="center" className="head_prnt">
        <Grid container spacing={1} className="align-items-center">
          <Grid item xs={12} md={6} lg={5} className="">
            <Box className="avtr_text_ash_prnt">
              <AvatarGroup max={4} className="avrggrp">
                <Avatar
                  alt={pool.token}
                  src={`https://pi-protocol.mypinata.cloud/ipfs/${pool.logoImageHash}`}
                />
              </AvatarGroup>
              <Box className="tvl_usd_text_v3">
                <Typography component="label">
                  Vault ID: {pool.vaultId}
                </Typography>
                <Typography component="label">
                  Name: {pool.collectionName}
                </Typography>
                <Typography component="h6" className="grin_bustble">
                  Autocompound
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3} className="bx_cnt_scnd">
            <Box className="apr_weekly_prnt_ash">
              <Typography component="h4">
                Stake <span className="grntxtgrdnt">x{pool.symbol}</span> for{" "}
                <span className="yllwtxtgrdnt">x{pool.symbol}</span>
              </Typography>

              <Typography component="label">
                <span className="clpsyllwtxt">
                  <a
                    href={`https://bsc.pinft.market/vault/${pool.rewardToken}`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    Check Vault Data
                  </a>
                </span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className="bx_cnt_trd">
            <Box className="cnt_trd_box cntnt_box_ash mx_275">
              <Typography component="p">
                Unstaked
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(byDecimals(tokenBalance))}
                      </span>{" "}
                      x{pool.symbol}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(byDecimals(tokenBalance))}
                      </span>{" "}
                      x{pool.symbol}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Staked
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(byDecimals(totalStaked))}
                      </span>{" "}
                      x{pool.symbol}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(byDecimals(totalStaked))}
                      </span>{" "}
                      x{pool.symbol}{" "}
                    </span>{" "}
                  </>
                )}{" "}
              </Typography>
              <Typography component="p">
                Claimable NFTs
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">{maxnft[0]}</span> x
                      NFTs{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">{maxnft[0]}</span> x
                      NFTs{" "}
                    </span>{" "}
                  </>
                )}{" "}
              </Typography>

              <Typography component="p">
                Timelock
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {timelock && <Countdown date={timelock * 1000} />}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {timelock && <Countdown date={timelock * 1000} />}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box className="expndmr_btn"></Box>
    </>
  );
}
