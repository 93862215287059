import React from "react";
import { Grid } from "@material-ui/core";
import FirstSection from "./sections/FirstSection";
import SecondSection from "./sections/SecondSection";
import FourSection from "./sections/FourSection";
import SevenSection from "./sections/SevenSection";
import RoadMap from "./sections/RoadMap";
import TeamSection from "./sections/TeamSection";
import LaunchPadSec from "./sections/LaunchPadSec";
import MetaverseSec from "./sections/MetaverseSec";

export const Landing = () => {
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <FirstSection />
        </Grid>
        <Grid item xs={12}>
          <SecondSection />
        </Grid>
        <Grid item xs={12}>
          <LaunchPadSec />
        </Grid>
        <Grid item xs={12}>
          <MetaverseSec />
        </Grid>
        <Grid item xs={12}>
          <SevenSection />
        </Grid>
        {/* <Grid item xs={12}>
          <ThirdSection />
        </Grid> */}
        <Grid item xs={12}>
          <FourSection />
        </Grid>
        {/* <Grid item xs={12}>
          <FiveSection />
        </Grid> */}
        {/* <Grid item xs={12}>
          <SixSection />
        </Grid> */}
        <Grid item xs={12}>
          <RoadMap />
        </Grid>
{/*         <Grid item xs={12}>
          <TeamSection />
        </Grid> */}
      </Grid>
    </>
  );
};
