import React from "react";
import { Grid, Box } from "@material-ui/core";
import PoolsTitle from "./sections/PoolsTitle";
import PoolsTopDetails from "./sections/PoolsTopDetails";
import SectionPoolsV2 from "./sections/SectionPoolsV2";
import { useConnectWallet } from "../home/redux/hooks";
//import Cs from "./sections/Cs";

export default function VaultPage() {
  const { networkId } = useConnectWallet();

  return (
    <>
      <PoolsTopDetails />
      <Box className="nft_prnt footer_spaceing stkng_main_bx">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <PoolsTitle />
          </Grid>
          <Grid item xs={12}>
            <Box className="cstmcntnr">
              {networkId === 56 ? <SectionPoolsV2 /> : <></>}
            </Box>
            <Box className="cstmcntnr">{networkId === 1 ? <SectionPoolsV2 /> : <></>}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
