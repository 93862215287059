import { lootboxpools } from "../../configure";

const tokens = {
  56: {},
  1: {},

};

lootboxpools.map(({chainId, token, tokenAddress })=> {
  tokens[chainId][token] = {
    tokenAddress: tokenAddress,
    tokenBalance: 0
  }
  tokens[chainId] = {
    tokenBalance: 0
  }
  return '';
})

// console.log(tokens)

const initialState = {
  lootboxpools,
  tokens,
};

export default initialState;