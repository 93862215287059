import { nftpools } from "../../configure";

const tokens = {
  56: {},
  1: {}

};

nftpools.map(({chainId, token, tokenAddress,tokenAddress2 })=> {
  tokens[chainId][token] = {
    tokenAddress: tokenAddress,
    tokenAddress2: tokenAddress2,
    tokenBalance: 0
  }
  tokens[chainId] = {
    tokenBalance: 0
  }
  return '';
})

// console.log(tokens)

const initialState = {
  nftpools,
  tokens,
};

export default initialState;