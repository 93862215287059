import WalletConnectProvider from "@walletconnect/web3-provider";
//import { DeFiConnector } from 'deficonnect';
import { connectors } from "web3modal";
import WalletLink from "walletlink";
// import { CloverConnector } from "@clover-network/clover-connector";
import { allNetworks } from "../../network";

export const appNetworkId = window.REACT_APP_NETWORK_ID;

const networkTxUrls = {
  1: (hash) => `https://etherscan.io/tx/${hash}`,
  56: (hash) => `https://bscscan.com/tx/${hash}`,
  128: (hash) => `https://hecoinfo.com/tx/${hash}`,
  43114: (hash) => `https://snowtrace.io/tx/${hash}`,
  137: (hash) => `https://polygonscan.com/tx/${hash}`,
  250: (hash) => `https://ftmscan.com/tx/${hash}`,
  1666600000: (hash) => `https://explorer.harmony.one/tx/${hash}`,
  42161: (hash) => `https://arbiscan.io/tx/${hash}`,
  42220: (hash) => `https://explorer.celo.org/tx/${hash}`,
  1285: (hash) => `https://moonriver.moonscan.io/tx/${hash}`,
  25: (hash) => `https://cronos.crypto.org/explorer/tx/${hash}`,
  1313161554: (hash) => `https://explorer.mainnet.aurora.dev/tx/${hash}`,
  122: (hash) => `https://explorer.fuse.io/tx/${hash}`,
  1088: (hash) => `https://andromeda-explorer.metis.io/tx/${hash}`,
  1284: (hash) => `https://moonscan.io/tx/${hash}`,
};

const networkFriendlyName = {
  1: "ETH",
  56: "BSC",
  128: "HECO",
  43114: "AVAX",
  137: "Polygon",
  250: "Fantom",
  1666600000: "Harmony",
  42161: "Arbitrum",
  42220: "Celo",
  1285: "Moonriver",
  25: "Cronos",
  1313161554: "Aurora",
  122: "Fuse",
  1088: "Metis",
  1284: "Moonbeam",
};

export const getNetworkConnectors = () => {
  switch (process.env.REACT_APP_NETWORK_ID) {
    case "56":
      return {
        network: "binance",
        cacheProvider: true,
        providerOptions: {
          injected: {
            display: {
              name: "MetaMask",
            },
          },
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              rpc: {
                1: "https://mainnet.infura.io/v3/950953cc461246c18fe8a9ec49c9eb50",
                56: "https://bsc-dataseed.binance.org/",
              },
            },
          },
          "custom-safepal": {
            display: {
              name: "SafePal",
              description: ("SafePal App"),
              logo: require(`images/wallets/safepal-wallet.svg`),
            },
            package: "safepal",
            connector: connectors.injected,
          },
          
          /*  "custom-clover-bsc": {
            display: {
              logo: require(`images/wallets/clover.png`),
              name: "Clover Wallet",
              description: ("Connect with your Clover wallet and earn CLV"),
            },
            options: {
              supportedChainIds: [56],
            },
            package: CloverConnector,
            connector: async (ProviderPackage, options) => {
              const provider = new ProviderPackage(options);
              await provider.activate();
              return provider.getProvider();
            },
          },
          "custom-binance": {
            display: {
              name: "Binance",
              description: ("Binance Chain Wallet"),
              logo: require(`images/wallets/binance-wallet.png`),
            },
            package: "binance",
            connector: async (ProviderPackage, options) => {
              const provider = window.BinanceChain;
              await provider.enable();
              return provider;
            },
          },
          "custom-math": {
            display: {
              name: "Math",
              description: ("Math Wallet"),
              logo: require(`images/wallets/math-wallet.svg`),
            },
            package: "math",
            connector: connectors.injected,
          },

          "custom-cb-bsc": {
            display: {
              logo: require(`images/wallets/coinbase.png`),
              name: "Coinbase Wallet",
              description: ("Connect to your Coinbase Wallet"),
            },
            options: {
              appName: "Pi Protocol",
              appLogoUrl:
                "https://www.pi-protocol.io/gfx/Pi_bCircle_200x200.png",
              darkMode: false,
            },
            package: WalletLink,
            connector: async (ProviderPackage, options) => {
              const walletLink = new ProviderPackage(options);

              const provider = walletLink.makeWeb3Provider(
                "https://bsc-dataseed.binance.org/",
                56
              );

              await provider.enable();

              return provider;
            },
          },
          "custom-bitkeep": {
            display: {
              name: "BitKeep Wallet",
              description: "Connect your BitKeep Wallet",
              logo: require(`images/wallets/bitkeep-wallet.png`),
            },
            package: "bitkeep",
            connector: connectors.injected,
          }, */
        },
      };
    case 43114:
      return {
        network: "avalanche",
        cacheProvider: true,
        providerOptions: {
          injected: {
            display: {
              name: "MetaMask",
            },
          },
          "custom-wc-avax": {
            display: {
              logo: require(`images/wallets/wallet-connect.svg`),
              name: "Wallet Connect",
              description: "Scan your WalletConnect to Connect",
            },
            package: WalletConnectProvider,
            options: {
              rpc: {
                1: "https://api.avax.network/ext/bc/C/rpc",
                43114: "https://api.avax.network/ext/bc/C/rpc",
              },
            },
            connector: async (ProviderPackage, options) => {
              const provider = new ProviderPackage(options);

              await provider.enable();

              return provider;
            },
          },
          "custom-cb-avalanche": {
            display: {
              logo: require(`images/wallets/coinbase.png`),
              name: "Coinbase Wallet",
              description: "Connect to your Coinbase Wallet",
            },
            options: {
              appName: "Pi Protocol",
              appLogoUrl:
                "https://www.pi-protocol.io/gfx/Pi_bCircle_200x200.png",
              darkMode: false,
            },
            package: WalletLink,
            connector: async (ProviderPackage, options) => {
              const walletLink = new ProviderPackage(options);

              const provider = walletLink.makeWeb3Provider(
                "https://api.avax.network/ext/bc/C/rpc",
                43114
              );

              await provider.enable();

              return provider;
            },
          },
          "custom-bitkeep": {
            display: {
              name: "BitKeep Wallet",
              description: "Connect your BitKeep Wallet",
              logo: require(`images/wallets/bitkeep-wallet.png`),
            },
            package: "bitkeep",
            connector: connectors.injected,
          },
        },
      };
    case "1":
      return {
        network: "",
        cacheProvider: true,
        providerOptions: {
          injected: {
            display: {
              name: "Metamask",
            },
          },
          "custom-wc-eth": {
            display: {
              logo: require(`images/wallets/wallet-connect.svg`),
              name: "Wallet Connect",
              description: "Scan your WalletConnect to Connect",
            },
            package: WalletConnectProvider,
            options: {
              rpc: {
                1: "https://mainnet.infura.io/v3/950953cc461246c18fe8a9ec49c9eb50",
              },
            },
            connector: async (ProviderPackage, options) => {
              const provider = new ProviderPackage(options);

              await provider.enable();

              return provider;
            },
          },
          "custom-safepal": {
            display: {
              name: "SafePal",
              description: ("SafePal App"),
              logo: require(`images/wallets/safepal-wallet.svg`),
            },
            package: "safepal",
            connector: connectors.injected,
          },

        },
      };
    default:
      return {};
  }
};

export const getNetworkFriendlyName = (
  networkId = window.REACT_APP_NETWORK_ID
) => networkFriendlyName[networkId];

export const getNetworkAppUrl = (networkId = window.REACT_APP_NETWORK_ID) =>
  window.location.protocol +
  "//" +
  window.location.host +
  window.location.pathname +
  "#" +
  allNetworks.find((n) => n.id === networkId)?.hash;
export const getNetworkTxUrl = networkTxUrls[window.REACT_APP_NETWORK_ID];
