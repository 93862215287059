
export const badgestake = [

  { 
    chainId: 56,
    id: '1',
    name: 'class1',
    item: "Phoenix",
    token: 'PIP',
    tokenDescription: 'Blue Tails, Class 1 (2-7)',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenAddress1: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',
    tokenDecimals: 18,
    earnedToken: 'C1 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0xDb946E57DC75B726C6bFa02369792E9747b51B0D',  // c
    pricePerFullShare: 1,
    timelock: "3 Days",
    status: 'aktive',
    ended: "no",
    payment: 150,
    NFTTYPE: 49,
    type1: "2",
    type2: "3",
    type3: "4",
    type4: "5",
    type5: "6",
    type6: "7"
  },
  { 
    chainId: 56,
    id: '2',
    item: "Phoenix",
    name: 'class2',
    token: 'PIP',
    tokenDescription: 'Purple Tails, Class 2 (8-13)',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenAddress1: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',
    tokenDecimals: 18,
    earnedToken: 'C2 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0x11525E368F4DBEC11378c6e361AEDBf6564a6e97',  // c
    pricePerFullShare: 1,
    timelock: "10 Days",
    status: 'aktive',
    ended: "no",
    payment: 200,
    NFTTYPE: 50,
    type1: "8",
    type2: "9",
    type3: "10",
    type4: "11",
    type5: "12",
    type6: "13"
  },
  { 
    chainId: 56,
    id: '3',
    item: "Phoenix",
    name: 'class3',
    token: 'PIP',
    tokenDescription: 'Yellow Tails, Class 3 (14-19)',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenAddress1: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',
    tokenDecimals: 18,
    earnedToken: 'C3 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0xfCC56A3e50Ad3cd447B1ccF1505F8Ee1c72266Bc',  // c
    pricePerFullShare: 1,
    timelock: "15 Days",
    status: 'aktive',
    ended: "no",
    payment: 250,
    NFTTYPE: 51,
    type1: "14",
    type2: "15",
    type3: "16",
    type4: "17",
    type5: "18",
    type6: "19"
  },
  { 
    chainId: 56,
    id: '4',
    item: "Phoenix",
    name: 'class4',
    token: 'PIP',
    tokenDescription: 'Green Tails, Class 4 (20-25)',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenAddress1: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',
    tokenDecimals: 18,
    earnedToken: 'C4 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0xAc84406498Be589a8F7eF2120de771a19026c297',  // c
    pricePerFullShare: 1,
    timelock: "20 Days",
    status: 'aktive',
    ended: "no",
    payment: 300,
    NFTTYPE: 52,
    type1: "20",
    type2: "21",
    type3: "22",
    type4: "23",
    type5: "24",
    type6: "25"
  },
  { 
    chainId: 56,
    id: '5',
    item: "Phoenix",
    name: 'class5',
    token: 'PIP',
    tokenDescription: 'Grey Tails, Class 5 (26-31)',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenAddress1: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',
    tokenDecimals: 18,
    earnedToken: 'C5 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0xAf77Ad389678108206835A5Eb92b2CE6D7dcB853',  // c
    pricePerFullShare: 1,
    timelock: "25 Days",
    status: 'aktive',
    ended: "no",
    payment: 350,
    NFTTYPE: 53,
    type1: "26",
    type2: "27",
    type3: "28",
    type4: "29",
    type5: "30",
    type6: "31"
  },
  { 
    chainId: 56,
    id: '6',
    item: "Phoenix",
    name: 'class6',
    token: 'PIP',
    tokenDescription: 'UniColor I, Class 6 (32-36)',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenAddress1: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',
    tokenDecimals: 18,
    earnedToken: 'C6 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0xf081d1494970A4AD10D5139acF0a18e511D81a90',  // c
    pricePerFullShare: 1,
    timelock: "30 Days",
    status: 'aktive',
    ended: "no",
    payment: 400,
    NFTTYPE: 54,
    type1: "32",
    type2: "33",
    type3: "34",
    type4: "35",
    type5: "36",
    type6: ""
  },
  { 
    chainId: 56,
    id: '7',
    item: "Phoenix",
    name: 'class7',
    token: 'PIP',
    tokenDescription: 'UniColor II, Class 7 (37-40)',
    tokenDescription2: '',
    tokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece',  // c
    tokenAddress1: '0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1',
    tokenDecimals: 18,
    earnedToken: 'C7 Badge',
    claimedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ', // c
    earnedTokenAddress: '0x25c30340e6f9f6e521827cf03282943da00c0ece ',  // c
    earnContractAddress: '0xc21d0eC2d67DB104C079929e89794806E5BcA43d',  // c
    pricePerFullShare: 1,
    timelock: "35 Days",
    status: 'aktive',
    ended: "no",
    payment: 450,
    NFTTYPE: 55,
    type1: "37",
    type2: "38",
    type3: "39",
    type4: "40",
    type5: "",
    type6: ""
  },


  ///////////////// ETH

  { 
    chainId: 1,
    id: '1',
    item: "Panther",
    name: 'class1a',
    token: 'PIP',
    tokenDescription: 'Blue Background, Class 1 (2-7)',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenAddress1: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',
    tokenDecimals: 18,
    earnedToken: 'C1 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0xB859B90174227Cc36FA26E2fEEee21A3f5bc1A64',  // c
    pricePerFullShare: 1,
    timelock: "3 Days",
    status: 'aktive',
    ended: "no",
    payment: 150,
    NFTTYPE: 49,
    type1: "2",
    type2: "3",
    type3: "4",
    type4: "5",
    type5: "6",
    type6: "7"
  },
  { 
    chainId: 1,
    id: '2',
    item: "Panther",
    name: 'class2a',
    token: 'PIP',
    tokenDescription: 'Purple Background, Class 2 (8-13)',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenAddress1: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',
    tokenDecimals: 18,
    earnedToken: 'C2 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x3f320820f0C244cfE014430aFbc2c123d584Fc0B',  // c
    pricePerFullShare: 1,
    timelock: "10 Days",
    status: 'aktive',
    ended: "no",
    payment: 200,
    NFTTYPE: 50,
    type1: "8",
    type2: "9",
    type3: "10",
    type4: "11",
    type5: "12",
    type6: "13"
  },
  { 
    chainId: 1,
    id: '3',
    item: "Panther",
    name: 'class3a',
    token: 'PIP',
    tokenDescription: 'Yellow Background, Class 3 (14-19)',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenAddress1: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',
    tokenDecimals: 18,
    earnedToken: 'C3 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x152Ec68908F44cf1b3b000b451cF092648eFD1bc',  // c
    pricePerFullShare: 1,
    timelock: "15 Days",
    status: 'aktive',
    ended: "no",
    payment: 250,
    NFTTYPE: 51,
    type1: "14",
    type2: "15",
    type3: "16",
    type4: "17",
    type5: "18",
    type6: "19"
  },
  { 
    chainId: 1,
    id: '4',
    item: "Panther",
    name: 'class4a',
    token: 'PIP',
    tokenDescription: 'Green Background, Class 4 (20-25)',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenAddress1: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',
    tokenDecimals: 18,
    earnedToken: 'C4 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x71E0775e4F6B20d97ec0b326833753667185813B',  // c
    pricePerFullShare: 1,
    timelock: "20 Days",
    status: 'aktive',
    ended: "no",
    payment: 300,
    NFTTYPE: 52,
    type1: "20",
    type2: "21",
    type3: "22",
    type4: "23",
    type5: "24",
    type6: "25"
  },
  { 
    chainId: 1,
    id: '5',
    item: "Panther",
    name: 'class5a',
    token: 'PIP',
    tokenDescription: 'Grey Background, Class 5 (26-31)',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenAddress1: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',
    tokenDecimals: 18,
    earnedToken: 'C5 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x22aa7A6701b8c8Fc570eb5aeE436067e6C385d77',  // c
    pricePerFullShare: 1,
    timelock: "25 Days",
    status: 'aktive',
    ended: "no",
    payment: 350,
    NFTTYPE: 53,
    type1: "26",
    type2: "27",
    type3: "28",
    type4: "29",
    type5: "30",
    type6: "31"
  },
  { 
    chainId: 1,
    id: '6',
    item: "Panther",
    name: 'class6a',
    token: 'PIP',
    tokenDescription: 'UniColor I, Class 6 (32-36)',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenAddress1: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',
    tokenDecimals: 18,
    earnedToken: 'C6 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x731f6D034B8274b46A4e2613b4d286a81212b683',  // c
    pricePerFullShare: 1,
    timelock: "30 Days",
    status: 'aktive',
    ended: "no",
    payment: 400,
    NFTTYPE: 54,
    type1: "32",
    type2: "33",
    type3: "34",
    type4: "35",
    type5: "36",
    type6: ""
  },
  { 
    chainId: 1,
    id: '7',
    item: "Panther",
    name: 'class7a',
    token: 'PIP',
    tokenDescription: 'UniColor II, Class 7 (37-40)',
    tokenDescription2: '',
    tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
    tokenAddress1: '0xE651657fa2355261Bbf4037cF6af43825Af5Da22',
    tokenDecimals: 18,
    earnedToken: 'C7 Badge',
    claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ', // c
    earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707 ',  // c
    earnContractAddress: '0x91Eba4EbA9b57bB090DeFf7AE1B1954030B95fa8',  // c
    pricePerFullShare: 1,
    timelock: "35 Days",
    status: 'aktive',
    ended: "no",
    payment: 450,
    NFTTYPE: 55,
    type1: "37",
    type2: "38",
    type3: "39",
    type4: "40",
    type5: "",
    type6: ""
  },

 ]
 