import { AxMatic, axMaticDirectABI, axMaticLiq, dragonDirect } from "../../configure";

const AxMaticAddress =       '0xDf8f094c9DD31EA437b0d08B04C61474538058d2'  // pBNBAdd       
const axMaticDirectAddress = '0xE86627b0bB04428b7155B75c358f34faF123056E'  // pBNBDirectPancakeSwappingAdd    
const dragonDirectAddress =  '0x679F2f1C88CC0c2424AE3088Cb71b35CEAeb02F5'  // circleDirect  
const DRAXliqContract =      '0x72f74039FdA7848A1b651c989BFaDb1951C92Cb4'  // pBNBLiquidityLPAdd                     

export const allowance = [
  {   // Confirmed Working 
    name: 'BNB',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', //OK
    pools: [{
      name: 'AxMaticAddress' ,
      address: AxMaticAddress, 
      allowance: 1
    },
    {
      name: 'axMaticDirectAddress' ,
      address: axMaticDirectAddress, 
      allowance: 1
    },
    {
      name: 'dragonDirectAddress' ,
      address: dragonDirectAddress, 
      allowance: 1
    }] 
  },
  {  
    name: 'PIP',
    address: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  //  OK
    pools: [{
      name: 'axMaticDirectAddress',
      address: axMaticDirectAddress, 
      allowance: 0,
    },
    {
      name: 'dragonDirectAddress',
       address: dragonDirectAddress, 
       allowance: 0,
    }
  ]
  },
  {   
    name: 'PBNB',
    address: '0xDf8f094c9DD31EA437b0d08B04C61474538058d2',  //   OK
    pools: [
      {
        name: 'dragonDirectAddress',
        address: dragonDirectAddress, 
        allowance: 0,
      },
      {
      name: 'AxMaticAddress',
      address: AxMaticAddress, 
      allowance: 0,
    },
      {
      name: 'axMaticDirectAddress',
      address: axMaticDirectAddress, 
      allowance: 0,
    }
  ]
  },

  {   
    name: 'PBNB-PIP-LP',
    address: '0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f',   //  OK
    pools: [{
      name: 'DRAXliqContract' ,
      address: DRAXliqContract, 
      allowance: 0,
    }
    ]
  },

  {   
    name: 'SCIRCLE',
    address: '0x796263Bb5706fE09bCb44514E1525602FcEbEA31',   // OK
    pools: [{
      name: 'dragonDirectAddress' ,
      address: dragonDirectAddress, 
      allowance: 0,
    }]
  },



]

export const tokens = [

  {  // Working !! 
    name: 'BNB',
    description: 'BNB',
    address: '', //  22
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'PIP',
        type: 'swap',
        contract: {
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easyBuy',
          call2: 'easyBuy2'
       }
      },
        {
          name: 'PBNB',
          type: 'swap',
          contract: {
           name: 'AxMaticAddress',
           address: AxMaticAddress,
           abi: AxMatic,
          call: 'deposit',
          call2: 'deposit'
          }
      },
      {
        name: 'SCIRCLE',
        type: 'swap',
        contract: {
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easyBuySmallCircle',
          call2: 'easyBuy2'
        }
    }
    ]
  },

  {  
    name: 'PIP',
    description: 'PIP',
    address: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  //  OK
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'BNB',
        type: 'swap',
        contract: {
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easySell',
          call2: 'easySell3'
        }
      },
      {
        name: 'SCIRCLE ',
        type: 'swap',
        contract: {
          address2: '',  // 
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easyBuyFromPi',
          call2: 'easySell3'
        }
      },
      {
        name: 'PBNB ',
        type: 'swap',
        contract: {
          address2: '',  // 
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easySellToPBNB',
          call2: 'easySell3'
        }
      }
    ]
  },
  {   
    name: 'PBNB',
    description: 'PBNB',
    address: '0xDf8f094c9DD31EA437b0d08B04C61474538058d2', //   OK
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'SCIRCLE  ',
        type: 'swap',
        contract: {
          address2: '',  // 
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easyBuyFromPBNB',
          call2: 'easySell3'
        }
      },
      {
        name: 'BNB ',
        type: 'swap',
        contract: {
          name: 'AxMaticAddress',
          address: AxMaticAddress,
          abi: AxMatic,
          call: 'withdraw',
          call2: 'easySell3'
        }
      },
      {
        name: 'PIP ',
        type: 'swap',
        contract: {
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easyBuyFromPBNB',
          call2: 'easySell3'
        }
      },
    ]
  },
  {   
    name: 'PBNB-PIP-LP',
    description: 'PBNB-PIP-LP',
    address: '0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f',  //   OK
    address2: '0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f',  //  OK
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'SCIRCLE   ',
        type: 'swap',
        contract: {
          address2: '0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f',  // OK
          name: 'DRAXliqContract',
          address: DRAXliqContract,
          abi: axMaticLiq,
          call: 'depositTokens',
          call2: 'depositTokens'
        }
      }
    ]
  },

  {   
    name: 'SCIRCLE',
    description: 'SCIRCLE',
    address: '0x796263Bb5706fE09bCb44514E1525602FcEbEA31', //  OK
    decimals: 0,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'BNB   ',
        type: 'zap',
        contract: {
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easySellSmallCircleToBNB',
          call2: 'easySell4'
        }
      },
      {
        name: 'PBNB  ',
        type: 'zap',
        contract: {
         name: 'dragonDirectAddress',
         address: dragonDirectAddress,
         abi: dragonDirect,
        call: 'easySellSmallCircleToPBNB',
        call2: 'easySell4'
        }
    },
    {
      name: 'PIP  ',
      type: 'zap',
      contract: {
       name: 'dragonDirectAddress',
       address: dragonDirectAddress,
       abi: dragonDirect,
      call: 'easySellSmallCircleToPi',
      call2: 'easySell4'
      }
  },
  {
    name: 'PBNB-PIP-LP',
    type: 'zap',
    contract: {
     name: 'dragonDirectAddress',
     address: dragonDirectAddress,
     abi: dragonDirect,
    call: 'sellSmallCircleToLP',
    call2: 'easySell4'
    },
},
{
  name: 'BCIRCLE',
  type: 'zap',
  contract: {
   name: 'dragonDirectAddress',
   address: dragonDirectAddress,
   abi: dragonDirect,
  call: 'buyBigCircle',
  call2: 'easySell3'
  },
},


    ]
  },


]

const initialState = {
  tokens,
  allowance,
  fetchBalancesPending: false,
  checkApprovalPending: false,
  fetchApprovalPending: false,
  fetchZapOrSwapPending: false
};

export default initialState;