import React from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";
//import { Link } from "react-router-dom";

function PoolsTitleETH() {
  return (
    <>
      <Box className="nft_ttl_prnt">
        <Box className=""></Box>
        <Container max-width="lg">
          <Grid container spacing={0} className="justify-content-center">
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography component="h2" className="def_ttl_v3 mb_100">
                    <span>
                      P<b>!</b>
                    </span>{" "}
                    Badge Staking
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Grid item xs={12} className="mb-4">
        <Box className="nvr_crcl_box">
          <img src="/images/nwcrcl_top.png" alt="" className="nvr_tp_img" />
          <Box className="nvr_mddl_img" />
          <img src="/images/nwcrcl_bttm.png" alt="" className="nvr_bttm_img" />
          <Box className="nvr_bx_cntn">
            <Typography component="h5">
              <span>Panther Trainer</span>
            </Typography>
            <Typography component="h6">
              Use your PI token, Panther Training Badge to take your Panther to
              the next level!
            </Typography>
            <Typography component="h6">
              How:
              <br></br>
              Stake your PI token, Panther Training Badge, and Panther in the
              class you want to upgrade.
              <br></br>
              <br></br>
              Class1: Blue Background, Class2: Purple Background, Class3: Yellow Background
              Class4: Green Background, Class5: Grey Background, Class6: UniColor I Class7:
              UniColor II
              <br></br>
              <br></br>
              You can find your NFT IDs by visiting your Profile on PI
              Marketplace.
              <br></br>
              <br></br>
              After the staking period, you will get back: Your Pi tokens and
              upgraded Panther! The Panther type will be increased by one. The
              training badge will not be returned. Please allow up to 10 minutes
              after claiming for the marketplace UI to be updated.
              <br></br>
                  Be aware: If you claim, the lock period will be extended.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default PoolsTitleETH;
