import React from "react";
import { Container, Grid, Box, Typography } from "@material-ui/core";
//import { Link } from "react-router-dom";

function PoolsTitleETH() {
  return (
    <>
      <Box className="nft_ttl_prnt">
        <Box className=""></Box>
        <Container max-width="lg">
          <Grid container spacing={0} className="justify-content-center">
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography component="h2" className="def_ttl_v3 mb_100">
                    <span>
                      P<b>!</b>
                    </span>{" "}
                    Badge Staking
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Grid item xs={12} className="mb-4">
        <Box className="nvr_crcl_box">
          <img src="/images/nwcrcl_top.png" alt="" className="nvr_tp_img" />
          <Box className="nvr_mddl_img" />
          <img src="/images/nwcrcl_bttm.png" alt="" className="nvr_bttm_img" />
          <Box className="nvr_bx_cntn">
                <Typography component="h5">
                  <span>Badge Staking</span>
                </Typography>
                <Typography component="h6">
                  Use your PI token to take your Panther to the next level! Earn
                  a Panther Training Badge and take it to the trainer to upgrade
                  your Panther!
                  <br></br>
                  <br></br>
                  How: <br></br>Claim your badge NFT for the appropriate Class,
                  choose your Panther NFT and just wait a few days!
                  <br></br>
                  <br></br>
                  Class1: Blue Background, Class2: Purple Background, Class3: Yellow Background
                  Class4: Green Background, Class5: Grey Background, Class6: UniColor I
                  Class7: UniColor II
                  <br></br>
                  <br></br>
                  After the staking period, you will get back: Your Pi tokens
                  and a Panther Training Badge NFT! The badge NFT can be used by
                  the Panther Trainer to upgrade your Panther NFT.
                  <br></br><br></br>
                  Be aware: If you claim, the lock period will be extended.
                </Typography>
              </Box>
        </Box>
      </Grid>

      {/*       <Box className="pd-20">
        <Link to="/launchpad" className="vttl_v3">
          <img src="/img/ttl_top.png" className="vttl_v3_tp_mi" alt="" />
          <Box className="invlt_ttl_v3">
            Click to claim your <span>free community NFT!</span>
          </Box>
          <Box className="vttl_v3_mddl_mi" />
          <img src="/img/ttl_bottom.png" className="vttl_v3_btm_mi" alt="" />
        </Link>
      </Box> */}
    </>
  );
}

export default PoolsTitleETH;
