import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Box, Button, Typography, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import { renderIcon } from "@download/blockies";
import { useLocation } from "react-router-dom";
import logo from "assets/img/logo.png";

export default function Header(props) {
  const { connected, address, connectWallet, disconnectWallet, networkId } =
    props;

  const canvasRef = useRef(null);
  const [shortAddress, setShortAddress] = useState("");
  const [dataUrl, setDataUrl] = useState(null);

  useEffect(() => {
    if (!connected) return;
    const canvas = canvasRef.current;
    renderIcon({ seed: address.toLowerCase() }, canvas);
    const updatedDataUrl = canvas.toDataURL();
    if (updatedDataUrl !== dataUrl) {
      setDataUrl(updatedDataUrl);
    }
    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 3)}..${address.slice(-3)}`);
    }
  }, [dataUrl, address, connected]);

  //for active class
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [isCollapsed, setCollapsed] = useState(true);

  return (
    <>
      {splitLocation[1] === "" ? (
        <Button
          onClick={() => setCollapsed(!isCollapsed)}
          className="hm_mn_btn"
        >
          <img src="/img/shwmn.svg" alt="" />
        </Button>
      ) : (
        <Hidden smDown>
          <Button
            onClick={() => setCollapsed(!isCollapsed)}
            className="hm_mn_btn"
          >
            <img src="/img/shwmn.svg" alt="" />
          </Button>
        </Hidden>
      )}

      <header className={!isCollapsed ? "headerv3 show" : "headerv3 "}>
        <Box
          component="img"
          src="/images/right_side.png"
          className="right_side"
        />
        <Navbar expand="xxl">
          <Navbar.Brand>
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="navbarScroll"
            onClick={() => setCollapsed(!isCollapsed)}
          />

          <Navbar.Collapse
            id="navbarScroll"
            isopen={+!isCollapsed}
            navbar="true"
          >
            <Nav className="" navbarScroll>
              <Box className="trade_link_box">
                {/*  <Typography>Trade</Typography> */}
                {/*                 <Link
                  to="/circle"
                  className={splitLocation[1] === "circle" ? "active" : ""}
                >
                  <Box className="active_dot" /> Buy Circles
                </Link> */}
                <Link
                  to="/launchpad"
                  className={splitLocation[1] === "launchpad" ? "active" : ""}
                >
                  <Box className="active_dot" /> NFT Launchpad{" "}
                </Link>
                {/*                 <Link
                  to="/dex"
                  className={splitLocation[1] === "dex" ? "active" : ""}
                >
                  <Box className="active_dot" /> PI DEX
                </Link> */}
                {/*                 <Link
                  to="/presale"
                  className={splitLocation[1] === "presale" ? "active" : ""}
                >
                  <Box className="active_dot" /> ETH Presale
                </Link> */}
                {/*                 <a
                  className="dropdown-item"
                  href="https://www.pi-marketplace.io"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <Box className="active_dot" /> Marketplace
                </a>{" "} */}
              </Box>
              <Box className="trade_link_box">
                <Typography>Earn</Typography>
                <Link
                  to="/pipx"
                  className={splitLocation[1] === "pipx" ? "active" : ""}
                >
                  <Box className="active_dot" /> PIPX LP Vaults{" "}
                </Link>
                <Link
                  to="/pips"
                  className={splitLocation[1] === "pips" ? "active" : ""}
                >
                  <Box className="active_dot" /> PIPX INV Vaults{" "}
                </Link>
                <Link
                  to="/staking"
                  className={splitLocation[1] === "staking" ? "active" : ""}
                >
                  <Box className="active_dot" /> Circle Staking{" "}
                </Link>
                <Link
                  to="/staking_erc"
                  className={splitLocation[1] === "staking_erc" ? "active" : ""}
                >
                  <Box className="active_dot" /> PIP Staking
                </Link>

                {/*                 <Link
                  to="/nftvaults"
                  className={splitLocation[1] === "nftvaults" ? "active" : ""}
                >
                  <Box className="active_dot" /> NFT Vaults
                </Link> */}
                <Link
                  to="/staking_721"
                  className={splitLocation[1] === "staking_721" ? "active" : ""}
                >
                  <Box className="active_dot" /> NFT Staking
                </Link>
                <Link
                  to="/stakingP"
                  className={splitLocation[1] === "stakingP" ? "active" : ""}
                >
                  <Box className="active_dot" />
                  {networkId === 56 ? (
                    "Phoenix Staking"
                  ) : networkId === 1 ? (
                    "Panther Staking"
                  ) : (
                    <></>
                  )}
                </Link>
                <Link
                  to="/breed"
                  className={splitLocation[1] === "breed" ? "active" : ""}
                >
                  <Box className="active_dot" /> Badge Staking
                </Link>

                <Link
                  to="/badge_stake"
                  className={splitLocation[1] === "badge_stake" ? "active" : ""}
                >
                  <Box className="active_dot" />
                  {networkId === 56 ? (
                    "Phoenix Trainer"
                  ) : networkId === 1 ? (
                    "Panther Trainer"
                  ) : (
                    <></>
                  )}
                </Link>
              </Box>
              <Box className="trade_link_box">
                <Typography>Game</Typography>
                {networkId === 56 ? (
                  <Link
                    to="/demigods"
                    className={splitLocation[1] === "demigods" ? "active" : ""}
                  >

                    <Box className="active_dot" />
                    Demigods
                  </Link>
                ) : (
                  <></>
                )}
                <Link
                  to="/lootbox"
                  className={splitLocation[1] === "lootbox" ? "active" : ""}
                >
                  <Box className="active_dot" />
                  {networkId === 56 ? (
                    "Phoenix Box"
                  ) : networkId === 1 ? (
                    "Panther Box"
                  ) : (
                    <></>
                  )}
                </Link>
                <Link
                  to="/phoenix"
                  className={splitLocation[1] === "phoenix" ? "active" : ""}
                >
                  <Box className="active_dot" />
                  {networkId === 56 ? (
                    "Phoenix Rank"
                  ) : networkId === 1 ? (
                    "Panther Rank"
                  ) : (
                    <></>
                  )}
                </Link>
                <Link
                  to="/metaverse"
                  className={splitLocation[1] === "metaverse" ? "active" : ""}
                >
                  <Box className="active_dot" /> Metaverse
                </Link>
                {/*                 <Link
                  to="/myth"
                  className={splitLocation[1] === "myth" ? "active" : ""}
                >
                  <Box className="active_dot" /> Pi Myth
                </Link> */}
                <a
                  className="dropdown-item"
                  href="https://spatial.io/s/PI-Protocol-3D-Area-624eb855f16e690001fc790f?share=3488146817370355005"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <Box className="active_dot" /> Gallery
                </a>{" "}
              </Box>
              {/*               <Box className="trade_link_box">
                <Typography>Bridge</Typography>
                <Link
                  to="/bridge"
                  className={splitLocation[1] === "bridge" ? "active" : ""}
                >
                  <Box className="active_dot" /> Bridge
                </Link>
                <Link
                  to="/bridgetransactionhistory"
                  className={
                    splitLocation[1] === "bridgetransactionhistory"
                      ? "active"
                      : ""
                  }
                >
                  <Box className="active_dot" /> Bridge History
                </Link>
              </Box> */}
              <Box className="trade_link_box socils_link_box">
                <Typography>Community</Typography>
                <a
                  href="https://discord.gg/jSP329kDaj"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box component="img" src="/images/social_ic_01.svg" /> Discord
                </a>
                <a
                  href="https://t.me/PIProtocol"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box component="img" src="/images/social_ic_02.svg" />{" "}
                  Telegram
                </a>
                <a
                  href="https://twitter.com/PiProtocol"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box component="img" src="/images/social_ic_03.svg" /> Twitter
                </a>
                <a
                  href="https://pi-protocol.medium.com/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box component="img" src="/images/social_ic_04.svg" /> Medium
                </a>
                <a
                  href="https://www.facebook.com/PiProtocolNFTs/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box /> Facebook
                </a>
                <a
                  href="https://youtube.com/channel/UCmKpA4Jn48TL5pJMbs3nd0g"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box /> YouTube
                </a>
                <a
                  href="https://instagram.com/pi.protocol.io"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box /> Instagram
                </a>
                <a
                  href="https://www.tiktok.com/@piprotocol"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box /> TikTok
                </a>
                <a
                  href="https://github.com/pi-protocol"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box /> GitHub
                </a>
                <a
                  href="https://eth.pinft.market/collections/0xcf5dbc399f3112bac87f57018a3389911241237b"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box /> Circles on ETH
                </a>
                <a
                  href="https://bsc.pinft.market/collections/0x796263bb5706fe09bcb44514e1525602fcebea31"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {" "}
                  <Box /> Circles on BSC
                </a>
              </Box>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
      <Box className="wlt_btn_prnt">
        <Box
          component="img"
          src="/images/cntnt_wllt_bg.png"
          className="cntnt_wllt_bg"
        />
        <Button
          className="wlt_btn"
          type="button"
          color="primary"
          onClick={connected ? disconnectWallet : connectWallet}
        >
          {connected ? (
            <>
              <canvas ref={canvasRef} style={{ display: "none" }} />
              {shortAddress}
            </>
          ) : (
            <>{"Wallet"}</>
          )}
        </Button>
        <Box className="bluir_bgbox_sadow" />
      </Box>
    </>
  );
}
