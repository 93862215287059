import React from 'react'
import { Container, Box, Typography } from "@material-ui/core";
//import { Link } from "react-router-dom";

export default function LaunchPadSec() {
  return (
    <>
         <Box className="launchpad_sec_main">
            <Container className="container">
                <Typography component="h2" className="def_ttl mb-3">
                    <span>P<b className="i_small">!</b></span> marketplace
                </Typography>
                <Box className='launchpad_sec_inn'>
                  <Box>
                    <Box component="img" src="/images/launchpad_tool_img.png" className='img-fluid'/>
                  </Box>
                  <Box className='launch_right'>
                    <Box className='power_tool_text_box'>
                      <Typography component="h5">Powerful Tool</Typography>
                      <Typography>Are you an artist? Do you want to launch your NFT collection? You can do it now on Pi Protocol Launchpad.</Typography>
                    </Box>
                    <Box className='launchpad_btn_prnt_box'>
                      <Box className='buy_pi_arrow_btn_prnt'>
                        <a href="https://bsc.pinft.market/lp" target="_blank" rel="noopener noreferrer" className='buy_pi_arrow_btn'><span>launchpad</span></a>
                      </Box>
                      <Box className='pi_value_text_prnt'>
                        <Box component="img" src="/images/pi_value_text.png" className='pi_value_text'/>
                      </Box>
                    </Box>
                  </Box>
                </Box>
            </Container>
        </Box>
    </>
  )
}
