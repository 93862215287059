import React from "react";
import { Container, Grid, Box, Typography, Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const CoverImgStyle = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  // objectFit: "cover",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: "-1",
}));

function SecondSection() {
  return (
    <>
      <Box className="sc02_prnt sc02_prnt_main_as_v2">
        <Container className="container">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box className="max-680">
                <Typography component="h2" className="def_ttl mb-3">
                  <span>
                    P<b className="i_small">!</b>
                  </span>{" "}
                  Contracts
                </Typography>
                <Button type="button" className="btn_v3">
                  <a
                    href="https://etherscan.io/token/0x37613d64258c0fe09d5e53eecb091da5b8fa8707"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="text_decoration_none"
                  >
                    {" "}
                    <span>ETH: 0x37613d....a8707</span>
                  </a>
                </Button>{" "}
                <Typography className="def_p">

                </Typography>
                <Button type="button" className="btn_v3">
                  <a
                    href="https://bscscan.com/token/0x25C30340e6f9f6e521827cF03282943dA00c0ECE"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="text_decoration_none"
                  >
                    {" "}
                    <span>BSC: 0x25C3034....c0ECE</span>
                  </a>
                </Button>
              </Box>
              <br></br>
              <br></br>
              <Box className="max-680">
                <Typography component="h2" className="def_ttl mb-3">
                  <span>
                    P<b className="i_small">!</b>
                  </span>{" "}
                  marketplace
                </Typography>
                <Typography className="def_p">
                  Explore, Collect, and Trade Amazing Looking NFTs on Pi
                  Protocol Multichain Marketplace.
                </Typography>
                <Typography className="def_p">
                  Create your own Vault to earn lifetime fees and trade NFTs against Liquidity with PIPX! 
                </Typography>
                <Button type="button" className="btn_v3">
                  <a
                    href="https://www.pi-marketplace.io"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="text_decoration_none"
                  >
                    {" "}
                    <span>marketplace</span>
                  </a>
                </Button>
              </Box>
              <Box
                component="img"
                src="/img/getbest_img.svg"
                className="getbest_img"
              />
            </Grid>
            <Grid item xs={4} className="fr_grdpddng">
              <Box className="fram_01">
                <Box component="img" src="/img/frame_01.svg" />
                <video muted autoPlay loop playsinline className="vidio_prnt_as_left">
                  <source
                    src="vidio_prnt_as_left_vortex2.mp4"
                    type="video/mp4"
                    
                  />
                </video>
              </Box>
            </Grid>
            <Grid item xs={4} className="fr_grdpddng">
              <Box className="fram_02">
                <Box component="img" src="/img/frame_02.svg" />
                <video muted autoPlay loop playsinline className="vidio_prnt_as_left">
                  <source src="vidio_prnt_as_left_green.mp4" type="video/mp4" />
                </video>
              </Box>
            </Grid>
            <Grid item xs={4} className="fr_grdpddng">
              <Box className="fram_03">
                <Box component="img" src="/img/frame_03.svg" />
                <video muted autoPlay loop playsinline className="vidio_prnt_as_left">
                  <source
                    src="vidio_prnt_as_left_vortex.mp4"
                    type="video/mp4"
                  />
                </video>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <CoverImgStyle alt="cover" src={"/static/img/sc02_bg.jpg"} />
        <Box
          component="img"
          src="/img/sc02_right.svg"
          className="sc02right_img"
        />
      </Box>
    </>
  );
}

export default SecondSection;
