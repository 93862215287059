import {
  erc20ABI,
  PIPXIStakingAbi,
  PIPXUStakingAbi
} from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { ethers } from "ethers";
import { useConnectWallet } from "../../home/redux/hooks";
import axios from "axios";

export { useFetchPoolBalances } from "./fetchPoolBalances";

const apiUrl = "https://api.pi-marketplace.io";

export const getVaults = async () => {
  const res = await axios.get(`${apiUrl}/info/getVaults`);
  return res.data;
};

const IStaking = "0x23620179Cf01e40478E8576210c72aF8b639d5D3";
const UStaking = "0xaD71679486f2Ec9F012B7B2F131FE5300302b3AA";

///////////////////////////OK
export function useBalanceOfVault(vaultId) {
  const { web3, address } = useConnectWallet();
  const [balanceNFT, setBalanceNFT] = useState("0");

  const fetchBalanceNFT = useCallback(async () => {
    let balanceNFT;
    const contract = new web3.eth.Contract(PIPXIStakingAbi, IStaking);

    balanceNFT = await contract.methods.balanceOf(vaultId, address).call();
    setBalanceNFT(balanceNFT);
  }, [address, vaultId, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalanceNFT();

      let refreshInterval = setInterval(fetchBalanceNFT, 30000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalanceNFT]);

  return balanceNFT;
}
export function useTimelock(vaultId) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(PIPXIStakingAbi, IStaking);

    const earned = await contract.methods
      .timelockUntil(vaultId, address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, web3, vaultId]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 30000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}
/* export function useShareValue(vaultId) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(PIPXIStakingAbi, IStaking);

    const earned = await contract.methods
      .xTokenShareValue(vaultId)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, web3, vaultId]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
} */
export function useBalanceOf(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
      balance = await web3.eth.getBalance(address);
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);
      balance = await contract.methods.balanceOf(address).call();
    
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 30000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}
export function useDeposit(vaultId) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(PIPXIStakingAbi, IStaking);
          contract.methods
            .deposit(vaultId, amount)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, vaultId]
  );

  return { isPending, onDeposit: handleDeposit };
}
export function useApprove(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

        contract.methods
          .approve(IStaking, ethers.constants.MaxUint256.toString(10))
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, tokenAddress]);

  return { isPending, onApprove: handleApprove };
}
export function useWithdraw(vaultId) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleWithdraw = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(PIPXIStakingAbi, IStaking);

          contract.methods
            .withdraw(vaultId, amount)
            .send({ from: address })

            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, vaultId]
  );

  return { isPending, onWithdraw: handleWithdraw };
}
export function useMaxNFT(vaultId, lp) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(PIPXUStakingAbi, UStaking);

    const earned = await contract.methods
      .maxNftsUsingXToken(vaultId, address, lp)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, web3, vaultId, lp]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 13000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}
export function useApprove2(tokenaddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove2 = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc20ABI, tokenaddress);
        contract.methods
          .approve(UStaking, ethers.constants.MaxUint256.toString(10))
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [web3, tokenaddress, address, dispatch]);

  return { isPending, onApprove2: handleApprove2 };
}
export function useFetchNFTs(vaultId) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleGetNFTs = useCallback(
    async (quant, finaldept) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(PIPXUStakingAbi, UStaking);
          contract.methods
            .unstakeInventory(vaultId, quant, finaldept.toString())
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [web3, vaultId, address, dispatch]
  );

  return { isPending, onGetNFTs: handleGetNFTs };
}
export function useAllowance(tokenAddress, spender) {
  const { web3, address } = useConnectWallet();
  const [allowance, setAllowance] = useState("0");

  const fetchAllowance = useCallback(async () => {

    const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

    const allowance = await contract.methods.allowance(address, spender).call();
    setAllowance(allowance);
  }, [address, spender, setAllowance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAllowance();

      let refreshInterval = setInterval(fetchAllowance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAllowance]);

  return allowance;
}

export function useClaimToken(vaultId) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(PIPXIStakingAbi, IStaking);

    const earned = await contract.methods
      .dividendOf(address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}
