import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {
  fetchDmagicPrice,
  // fetchDraxPrice,
  // fetchMagicPrice,
} from "features/circle/sections/Statistic";

import {
  useEarned,
  useBalanceOf,
  useDmagicAPY,
  useDmagicWPY,
  useDuration,
  useEarnedB,
} from "../redux/hooks";

const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100000))
    .toNumber();
};

const forMatApy = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100))
    .toNumber();
};

export default function PoolHeader({ pool }) {
  const tokenBalance = useBalanceOf(pool.tokenAddress);
  let balanceSingle = byDecimals(tokenBalance, pool.tokenDecimals);
  const depositedBalance = useBalanceOf(pool.earnContractAddress);
  let singleDepositedBalance = byDecimals(depositedBalance, pool.tokenDecimals);
  const earned = useEarned(pool.earnContractAddress);
  const formattedEarned = byDecimals(earned, pool.earnedTokenDec);
  const dmagicAPY = useDmagicAPY(pool.earnContractAddress);
  const dmagicWPY = useDmagicWPY(pool.earnContractAddress);
  const duration = useDuration(pool.earnContractAddress);


  const earnedB = useEarnedB(pool.earnContractAddress);
  const formattedEarnedB = byDecimals(earnedB, pool.earnedTokenDec);

  const [dmprice, setDmPrice] = useState(0);
  // const [drprice, setDrPrice] = useState(0);
  // const [mprice, setMPrice] = useState(0);

/*   useEffect(() => {
    const getMagicInfo = async () => {
      const {
        data: { price },
      } = await fetchMagicPrice();

      const data = {
        MagicPrice: price,
      };
      const magicMilPrice = price;
      data.magicMilPrice = magicMilPrice;
      setMPrice(data.magicMilPrice);
    };
    getMagicInfo();
  }, []); */

  useEffect(() => {
    const getDmagicInfo = async () => {
      const {
        data: { price },
      } = await fetchDmagicPrice();

      const data = {
        DmagicPrice: price,
      };
      const dmagicMilPrice = price;
      data.dmagicMilPrice = dmagicMilPrice;
      setDmPrice(data.dmagicMilPrice);
    };
    getDmagicInfo();
  }, []);

/*   useEffect(() => {
    const getDraxInfo = async () => {
      const {
        data: { price },
      } = await fetchDraxPrice();

      const data = {
        DraxPrice: price,
      };
      const draxMilPrice = price;
      data.draxMilPrice = draxMilPrice;
      setDrPrice(data.draxMilPrice);
    };
    getDraxInfo();
  }, []); */

  return (
    <>
      <Grid container direction="row" alignItems="center" className="head_prnt">
        <Grid container spacing={1} className="align-items-center">
          <Grid item xs={12} md={6} lg={5} className="">
            <Box className="avtr_text_ash_prnt">
              <AvatarGroup max={4} className="avrggrp">
                <Avatar
                  alt={pool.token}
                  src={require(`../../../images/${pool.token}-logo.png`)}
                />
              </AvatarGroup>
              <Box className="tvl_usd_text">
                <Typography component="label">
                  TVL:{" "}
                  {depositedBalance === "0" ? (
                    <>
                      <span className="clpsgreentxt">
                        {forMatApy(
                          byDecimals(duration * 2).toNumber()
                        )}{" "}
                      </span>
                      USD{" "}
                    </>
                  ) : (
                    <>
                      <span>
                        <b>
                          {forMatApy(
                            byDecimals(duration * 2).toNumber()
                          )}
                        </b>
                      </span>{" "}
                      USD{" "}
                    </>
                  )}
                </Typography>
                {pool.boost === "1" ? (
                  <>
                    <Typography component="h6" className="grin_bustble">
                      boostable
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
                {pool.ended === "yes" ? (
                  <>
                    <Typography component="h6" className="grin_bustble">
                      ENDED
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3} className="bx_cnt_scnd">
            <Box className="apr_weekly_prnt_ash">
              <Typography component="h4">
                Stake <span className="grntxtgrdnt">{pool.token}</span> for{" "}
                <span className="yllwtxtgrdnt">{pool.earnedToken}</span>
              </Typography>
              <Typography component="label">
                APR{" "}
                <span className="clpsyllwtxt">
                  {pool.id === "3" || pool.id === "4" || pool.id === "2"
                    ? forMatApy(
                        byDecimals(
                          dmagicAPY / (dmprice / 1),
                          6 // {/* drprice */}
                        ).toNumber()
                      )
                    : pool.id === "5"
                    ? forMatApy(
                        byDecimals(dmagicAPY / (dmprice / 1 ), 6).toNumber() // mprice
                      )
                    : forMatApy(
                        byDecimals(dmagicAPY / pool.APYC, 6).toNumber()
                      )}{" "}
                  %{" "}
                </span>
              </Typography>
              <Typography component="label">
                Weekly{" "}
                <span className="clpsyllwtxt">
                  {pool.id === "3" || pool.id === "4" || pool.id === "2"
                    ? forMatApy(
                        byDecimals(
                          dmagicWPY / (dmprice / 1),
                          6 // {/* drprice */}
                        ).toNumber()
                      )
                    : pool.id === "5"
                    ? byDecimals(dmagicWPY / (dmprice / 1 ), 6).toNumber() // mprice
                    : forMatApy(
                        byDecimals(dmagicWPY / pool.APYC, 6).toNumber()
                      )}{" "}
                  %{" "}
                </span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className="bx_cnt_trd ">
            <Box className="cnt_trd_box cntnt_box_ash">
              <Typography component="p">
                Earned
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(formattedEarned)}
                      </span>{" "}
                      {pool.earnedToken}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(formattedEarned)}
                      </span>{" "}
                      {pool.earnedToken}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Total Earned
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(formattedEarnedB)}
                      </span>{" "}
                      {pool.earnedToken}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(formattedEarnedB)}
                      </span>{" "}
                      {pool.earnedToken}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Unstaked
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(balanceSingle)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(balanceSingle)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Staked
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(singleDepositedBalance)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(singleDepositedBalance)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box className="expndmr_btn"></Box>
    </>
  );
}
