import React from "react";
import { Container, Box, Typography } from "@material-ui/core";
import logo from "assets/img/logo.png";
import { GridItem, SimpleGrid } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function FooterLanding() {
  return (
    <Box
      component="footer"
      className="footer footer_v3 d_none_dashbrd footerlanding"
    >
      <Box
        component="img"
        src="/images/foooter_dvider.png"
        className="img-fluid foooter_dvider"
      />
      <Container className="container_v3_footer">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            minHeight: "152px",
          }}
          className="ftrprnt"
        >
          <Box className="ftrlg">
            <img src={logo} alt="" />
          </Box>
          <Box className="pi_prtcl_text_img_prnt">
            <Box
              component="img"
              src="/images/pi_prtcl_text_img.png"
              className="pi_prtcl_text_img"
            />
            <Box className="gradiant_line" />
          </Box>
        </Box>
        <Box className="footer_list_links">
          <SimpleGrid
            columns={30}
            columnGap={3}
            rowGap={2}
            className="reapeat_27"
          >
            <GridItem colSpan={[15, 15, 10, 5]} className="list_links_grid">
              <Box className="list_links_prnt">
                <Typography>Trade</Typography>
                <Link to="/circle">Buy Circles</Link>
                <Link to="/dex">Pi DEX</Link>
                <a
                  href="https://www.pi-marketplace.io"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  Marketplace
                </a>
              </Box>
            </GridItem>
            <GridItem colSpan={[15, 15, 10, 5]} className="list_links_grid">
              <Box className="list_links_prnt">
                <Typography>Earn</Typography>
                <Link to="/staking">Circle NFT Staking</Link>
                <Link to="/staking_erc">PIP Staking</Link>
        {/*         <Link to="/nftvaults">NFT Vaults</Link> */}
              </Box>
            </GridItem>
            <GridItem colSpan={[15, 15, 10, 5]} className="list_links_grid">
              <Box className="list_links_prnt">
                <Typography>Game</Typography>
                <Link to="/metaverse">Metaverse</Link>
              {/*   <Link to="/game">Treasure Hunt</Link> */}
              </Box>
            </GridItem>
{/*             <GridItem colSpan={[15, 15, 10, 5]} className="list_links_grid">
              <Box className="list_links_prnt">
                <Typography>Bridge</Typography>
                <Link to="/bridge">Bridge</Link>
                <Link to="/bridge_transaction_history">Bridge History</Link>
              </Box>
            </GridItem> */}
            <GridItem colSpan={[15, 15, 10, 5]} className="list_links_grid">
              <Box className="list_links_prnt">
                <Typography>Learn</Typography>
                <Link to="/faq">FAQ</Link>
                <a
                  href="https://pi-protocol.gitbook.io/pi-protocol-official-gitbook/introduction-to-pi-protocol/what-is-pi-protocol"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  GitBook
                </a>
              </Box>
            </GridItem>
            <GridItem colSpan={[15, 15, 10, 5]} className="list_links_grid">
              <Box className="list_links_prnt">
                <Typography>Info</Typography>

                <a
                  href="https://www.pi-protocol.io/gfx/Pi_Deck.pdf"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  Deck
                </a>
                <a
                  href="https://www.pi-protocol.io/gfx/PI_AUDIT.pdf"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className="center_link"
                >
                  Audit
                </a>
                <a
                  href="https://bscscan.com/token/0x25c30340e6f9f6e521827cf03282943da00c0ece"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  Contract
                </a>
              </Box>
            </GridItem>
          </SimpleGrid>
          <Typography className="ftr_copyright_text_landg">
            Pi Protocol. All Rights Reserved 2023 (c)
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default FooterLanding;
