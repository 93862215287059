import React, { useEffect } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ZapCommand from "./sections/ZapCommand";
import ZapperTitle from "./sections/ZapperTitle";
//import Statistics from "../circle/sections/Statistic";
//import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { useConnectWallet } from "../home/redux/hooks";
import ZapCommandEth from "features/eth_dex/sections/ZapCommandEth";
import ZapperTitleETH from "features/eth_dex/sections/ZapperTitleETH";

export default function ZapPage() {
  const { networkId } = useConnectWallet();

  const CoverImgStyle = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "-1",
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
{/*       <Box className="nft_prnt footer_spaceing ">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {networkId === 56 ? <ZapperTitle /> : <></>}
          </Grid>
          <Grid item xs={12}>
            {networkId === 1 ? <ZapperTitleETH /> : <></>}
          </Grid>
                    <Grid item xs={12} className="mb-4">
            {networkId === 56 ? <ZapCommand /> : <></>}
          </Grid>
          {networkId === 56 ? (
            <Grid item xs={12} className="text-center nft_btm_prnt mb-5">
              <Typography component="p" className="def_p">
                Under Maintainance!{" "}
              </Typography>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12} className="mb-4">
            {networkId === 1 ? <ZapCommandEth /> : <></>}
          </Grid>
          <Grid item xs={12} className="text-center nft_btm_prnt mb-5">
            <Typography component="p" className="def_p"></Typography>
          </Grid>
        </Grid>
      </Box> */}
      <CoverImgStyle alt="cover" src={"/static/img/zapper_bg.jpg"} />
    </>
  );
}
