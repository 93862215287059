import React, { useState, useRef, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Box, Grid } from "@material-ui/core";
import { useConnectWallet } from "../../home/redux/hooks";
import { useFetchPoolBalances } from "../redux/hooks";
import sectionPoolsStyle from "../jss/sections/sectionPoolsStyle";
import PoolHeader from "./PoolHeader";
import PoolContent from "./PoolContent";
import axios from "axios";
import { lootAbi } from "features/configure/abi";

import { fetchTokens } from "../redux/hooks";

const useStyles = makeStyles(sectionPoolsStyle);

export default function SectionPoolsV2() {
  const { address, web3 } = useConnectWallet();
  const [fetchedIDs, setFetchedIDs] = useState(true);

  const { networkId } = useConnectWallet();
  let { badgestake } = useFetchPoolBalances();
  const [open, setOpen] = useState("");
  const [openedCardList, setOpenCardList] = useState([]);
  const classes = useStyles();
  const [fetchingNFT, setFetchingNFT] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [fetching, setFetching] = useState(false);
  const [owner, setOwner] = useState([]);
  let tokenAddress1;
  if (networkId === 56) {
    tokenAddress1 = "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1";
  } else if (networkId === 1) {
    tokenAddress1 = "0xe651657fa2355261bbf4037cf6af43825af5da22";
  }

  const openCard = (id) => {
    return setOpenCardList((openedCardList) => {
      if (openedCardList.includes(id)) {
        setOpen("");
        return openedCardList.filter((item) => item !== id);
      } else {
        setOpen(id);
        return [...openedCardList, id];
      }
    });
  };
  const fetchNFTs = async (address) => {
    const cancelTokenSource = axios.CancelToken.source();
    setFetching(true);
    try {
      const { data } = await fetchTokens(
        0,
        5000,
        "single",
        [tokenAddress1],
        null,
        "id",
        [],
        address,
        cancelTokenSource.token
      );

      tokens.current = [...tokens.current, ...data.tokens];
      setFetching(false);
    } catch {
      setFetching(false);
    }
  };

  const tokens = useRef([]);

  const filtered = tokens.current.filter(
    (t) => t.owner === address.toLowerCase()
  );

  const filtered3 = [...filtered.map(({ tokenID }) => tokenID)];

  const fetchOwner = useCallback(async () => {
    let owner;
    if (networkId === 56) {
      const contract = new web3.eth.Contract(
        lootAbi,
        "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1"
      );
      owner = await contract.methods
        .getNFTTypesForTokenIDs(filtered3)
        .call({ from: address });
    } else if (networkId === 1) {
      const contract = new web3.eth.Contract(
        lootAbi,
        "0xe651657fa2355261bbf4037cf6af43825af5da22"
      );
      owner = await contract.methods
        .getNFTTypesForTokenIDs(filtered3)
        .call({ from: address });
    }
    console.log(owner);
    setOwner(owner);
  }, [networkId, web3, filtered3, address]);

  useEffect(() => {
    if (web3 && address && fetchingNFT === true) {
      fetchNFTs(address);
      setFetchingNFT(false);
    }
    if (web3 && address && filtered3 > [] && fetchedIDs === true) {
      fetchOwner();
      setFetchedIDs(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, address, fetchingNFT, filtered3, fetchedIDs]);

  const tostaked = owner.reduce((accumulator, element, index) => {
    return { ...accumulator, [element]: filtered3[index] };
  }, {});

  return (
    <Grid item xs={12} className="mt-3 col-12">
      {badgestake
        .filter(
          (pool) => pool.status === "aktive" && pool.chainId === networkId
        )
        .map((pool, index) => (
          <>
            <Box className="mn_a_box mn_a_box_v2" key={1}>
              <img src="/images/tab_top.png" className="tab_tp_mi" alt="" />
              <Accordion
                className="cstmaccrdn cstmaccrdn_02"
                expanded={Boolean(openedCardList.includes(index))}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={(event) => {
                    event.stopPropagation();
                    openCard(index);
                  }}
                >
                  <PoolHeader
                    index={index}
                    pool={pool}
                    classes={classes}
                    openedCardList={openedCardList}
                    openCard={openCard}
                    open={open}
                  />
                </AccordionSummary>

                <AccordionDetails>
                  <PoolContent
                    index={index}
                    pool={pool}
                    classes={classes}
                    tostaked={tostaked}
                  />
                </AccordionDetails>
              </Accordion>
              <Box className="tbmddl_sc" />
              <img src="/images/tab_bottom.png" className="tab_btm_mi" alt="" />
            </Box>
          </>
        ))}
    </Grid>
  );
}
