import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import BigNumber from "bignumber.js";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { useBalanceOf, useDuration, useInfo } from "../redux/hooks";
import { byDecimals } from "features/helpers/bignumber";

import Countdown from "react-countdown";
import Moment from "react-moment";
import "moment-timezone";
Moment.globalLocal = true;

const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100000))
    .toNumber();
};

export default function PoolHeader({ pool }) {
  const tokenBalance = useBalanceOf(pool.tokenAddress);
  let balanceSingle = byDecimals(tokenBalance, pool.tokenDecimals);

  const userInfo = useInfo(pool.earnContractAddress);
  let UserBalance = byDecimals(userInfo[0], pool.tokenDecimals);

  let UserTime = userInfo[1];

  const total = useDuration(pool.earnContractAddress);
  let totalStaked = byDecimals(+total, pool.tokenDecimals);

  return (
    <>
      <Grid container direction="row" alignItems="center" className="head_prnt">
        <Grid container spacing={1} className="align-items-center">
          <Grid item xs={12} md={6} lg={5} className="">
            <Box className="avtr_text_ash_prnt">
              <AvatarGroup max={4} className="avrggrp">
                <Avatar
                  alt={pool.token}
                  src={require(`../../../images/${pool.name}.jpg`)}
                />
              </AvatarGroup>
              <Box className="tvl_usd_text_v3">
                <Typography component="label">
                  <>
                    TVL:{" "}
                    <span>
                      <b>{forMat(totalStaked)}</b>
                    </span>{" "}
                    PIP{" "}
                  </>
                </Typography>
                {pool.ended === "yes" ? (
                  <>
                    <Typography component="h6" className="grin_bustble">
                      ENDED
                    </Typography>
                  </>
                ) : (
                  <></>
                )}

                <Typography component="label">
                  {pool.tokenDescription}
                </Typography>
                <Typography component="label">
                  for {pool.payment} PIP
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3} className="bx_cnt_scnd">
            <Box className="apr_weekly_prnt_ash">
              <Typography component="h4">
                Stake <span className="grntxtgrdnt">{pool.token}</span> &{" "}
                <span className="yllwtxtgrdnt">{pool.earnedToken}</span> 
                <br></br>&{" "}
                <span className="yllwtxtgrdnt">{pool.item}</span>
              </Typography>
              <Typography component="h4">
            Timelock: <span className="yllwtxtgrdnt">{pool.timelock}</span>
          </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className="bx_cnt_trd">
            <Box className="cnt_trd_box cntnt_box_ash mx_275">
              <Typography component="p">
                Unstaked
                {UserBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(balanceSingle)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(balanceSingle)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Staked
                {UserBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(UserBalance)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(UserBalance)}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Timelock
                {UserBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {UserTime && <Countdown date={UserTime * 1000} />}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {UserTime && <Countdown date={UserTime * 1000} />}
                      </span>{" "}
                      {pool.token}{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box className="expndmr_btn"></Box>
    </>
  );
}
