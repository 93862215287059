import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { useConnectWallet } from "../../home/redux/hooks";
import { useFetchPoolBalances } from "../redux/hooks";
import PoolContent from "./PoolContent";

function CircleBoxes() {
  const { networkId } = useConnectWallet();
  let { nftpools } = useFetchPoolBalances();

  return (
    <>
      <Grid container spacing={0} className="justify-content-center">
        <Grid item xs={12} lg={7} className="cstm_lg_12">
          <Grid container spacing={3}> 
            {nftpools
              .filter((npool) => npool.chainId === networkId)
              .map((npool, index) => (
                <PoolContent index={index} npool={npool} />
              ))}
            <Grid item xs={12}>
              <Box className="nvr_crcl_box">
                <img src="/images/nwcrcl_top.png" alt="" className="nvr_tp_img" />
                <Box className="nvr_mddl_img" />
                <img src="/images/nwcrcl_bttm.png" alt="" className="nvr_bttm_img" />
                <Box className="nvr_bx_cntn">
                  <Typography component="h5"><span>Circles</span></Typography>
                  <Typography component="h6">sCircle: (small circle)</Typography>
                  <Typography component="h4">
                    LP Wrapped Token - LP = 1 sCircle ( NFT )
                  </Typography>
                  <Typography component="h6">bCircle / eCircle:</Typography>
                  <Typography component="h4">
                    5 sCircle = 1 bCircle / eCircle - Staking & Utility NFT for PI
                  </Typography>
                  <Typography component="h6">
                    bitCircle:
                  </Typography>
                  <Typography component="h4">Staking Utility NFT for PI</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CircleBoxes;
