import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import network from "network";
import * as serviceWorker from "./serviceWorker";

import "./styles/index.scss";
import "assets/css/App.scss";
import "assets/css/App.css";

if (network) {
  ReactDOM.render(<Root />, document.getElementById("root"));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
