import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

import { useBalanceOf, useBalanceOfVault, useEarned } from "../redux/hooks";

const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100000))
    .toNumber();
};

export default function PoolHeader({ pool }) {
  const tokenBalance = useBalanceOf(pool.stakingToken);
  const depositedBalance = useBalanceOfVault(pool.vaultId);

  const earned = useEarned(pool.vaultId);
  const formattedEarned = byDecimals(earned);

  return (
    <>
      <Grid container direction="row" alignItems="center" className="head_prnt">
        <Grid container spacing={1} className="align-items-center">
          <Grid item xs={12} md={6} lg={5} className="">
            <Box className="avtr_text_ash_prnt">
              <AvatarGroup max={4} className="avrggrp">
                <Avatar
                  alt={pool.token}
                  src={`https://pi-protocol.mypinata.cloud/ipfs/${pool.logoImageHash}`}
                />
              </AvatarGroup>
              <Box className="tvl_usd_text_v3">
                <Typography component="label">
                  Vault ID: {pool.vaultId}
                </Typography>
                <Typography component="label">
                  Name: {pool.collectionName}
                </Typography>
                <Typography component="label">Symbol: {pool.symbol}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3} className="bx_cnt_scnd">
            <Box className="apr_weekly_prnt_ash">
              <Typography component="h4">
                Stake <span className="grntxtgrdnt">x{pool.symbol} SLP</span>{" "}
                for <span className="yllwtxtgrdnt">x{pool.symbol} </span>
              </Typography>

              <Typography component="label">
                <span className="clpsyllwtxt">
                  <a
                    href={`https://bsc.pinft.market/vault/${pool.rewardToken}`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    Check Vault Data
                  </a>
                </span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className="bx_cnt_trd">
            <Box className="cnt_trd_box cntnt_box_ash mx_275">
              <Typography component="p">
                Earned
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(formattedEarned)}
                      </span>{" "}
                      x{pool.symbol} {" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(formattedEarned)}
                      </span>{" "}
                      x{pool.symbol} {" "}
                    </span>{" "}
                  </>
                )}{" "}
              </Typography>
              <Typography component="p">
                Unstaked
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(byDecimals(tokenBalance))}
                      </span>{" "}
                      x{pool.symbol} SLP{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(byDecimals(tokenBalance))}
                      </span>{" "}
                      x{pool.symbol} SLP{" "}
                    </span>{" "}
                  </>
                )}
              </Typography>
              <Typography component="p">
                Staked
                {depositedBalance === "0" ? (
                  <>
                    <span>
                      {" "}
                      <span className="clpsgreentxt">
                        {forMat(byDecimals(depositedBalance))}
                      </span>{" "}
                      x{pool.symbol} SLP{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <span className="clpsgreentxt">
                        {forMat(byDecimals(depositedBalance))}
                      </span>{" "}
                      x{pool.symbol} SLP{" "}
                    </span>{" "}
                  </>
                )}{" "}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box className="expndmr_btn"></Box>
    </>
  );
}
