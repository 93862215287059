import React, { useEffect } from "react";
import CircleBoxes from "./sections/CircleBoxes";
import { Grid } from "@material-ui/core";

// import { useConnectWallet } from "../home/redux/hooks";
//import Cs from "./sections/Cs";

export default function VaultPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  // const { networkId } = useConnectWallet();

  return (
    <>
      <CircleBoxes key={1}/>
      <Grid item xs={12} className="mb-4">
      </Grid>
    </>
  );
}

// {/* <CoverImgStyle alt="cover" src={"/static/img/nftcrcl_bg.jpg"} /> */}
