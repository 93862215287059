import { erc20ABI, PINFT } from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { useConnectWallet } from "../../home/redux/hooks";
import { ethers } from "ethers";

export { useFetchPoolBalances } from "./fetchPoolBalances";

export function useApprove2(tokenAddress2, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress2);

        contract.methods
          .approve(poolAddress, ethers.constants.MaxUint256.toString(10))
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress2]);

  return { isPending, onApprove2: handleApprove };
}
export function useAllowance(tokenAddress2, spender) {
  const { web3, address } = useConnectWallet();
  const [allowance, setAllowance] = useState("0");

  const fetchAllowance = useCallback(async () => {
    if (tokenAddress2 === "") {
      setAllowance(ethers.constants.MaxUint256.toString(10));
      return;
    }
    const contract = new web3.eth.Contract(erc20ABI, tokenAddress2);

    const allowance = await contract.methods.allowance(address, spender).call();
    setAllowance(allowance);
  }, [address, spender, setAllowance, tokenAddress2, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAllowance();

      let refreshInterval = setInterval(fetchAllowance, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAllowance]);

  return allowance;
}
/* export function useBalanceOf(tokenAddress) {
  const { address, web3 } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();
      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
} */

export function useDeposit(poolAddress, tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (amount, MP2) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(PINFT, poolAddress);
          console.log(amount, MP2)
          contract.methods
          .buy(amount)
          .send({ from: address, value: MP2 })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit: handleDeposit };
}
export function useDeposit2(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending2, setIsPending2] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit2 = useCallback(
    async (MP2) => {
      setIsPending2(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(PINFT, poolAddress);

          contract.methods
            .buyWithToken(MP2)
            .send({ from: address, value: 0 })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending2(false);
      }
    },
    [dispatch, setIsPending2, web3, address, poolAddress]
  );

  return { isPending2, onDeposit2: handleDeposit2 };
}
export function useBNBPrice1(poolAddress) {
  const { address, web3 } = useConnectWallet();
  const [BNBPrice, setBNBPrice] = useState("");

  const fetchBNBPrice = useCallback(async () => {
    const contract = new web3.eth.Contract(PINFT, poolAddress);

    const BNBPrice = await contract.methods.price().call({ from: address });
    setBNBPrice(BNBPrice);
  }, [address, setBNBPrice, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBNBPrice();

      let refreshInterval = setInterval(fetchBNBPrice, 10000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBNBPrice]);
  return BNBPrice;
}
export function useNatPrice1(poolAddress) {
  const { address, web3 } = useConnectWallet();
  const [NatPrice, setNatPrice] = useState("");

  const fetchNatPrice = useCallback(async () => {
    const contract = new web3.eth.Contract(PINFT, poolAddress);

    const NatPrice = await contract.methods
      .priceWithToken()
      .call({ from: address });
    setNatPrice(NatPrice);
  }, [address, setNatPrice, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchNatPrice();

      let refreshInterval = setInterval(fetchNatPrice, 1000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchNatPrice]);
  return NatPrice;
}
export function useOwner(poolAddress) {
  const { address, web3 } = useConnectWallet();
  const [Owner, setOwner] = useState("");

  const fetchOwner = useCallback(async () => {
    const contract = new web3.eth.Contract(PINFT, poolAddress);

    const Owner = await contract.methods
      .getOwnerCount()
      .call({ from: address });
    setOwner(Owner);
  }, [address, setOwner, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchOwner();

      let refreshInterval = setInterval(fetchOwner, 10000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchOwner]);
  return Owner;
}
export function useMax(poolAddress) {
  const { address, web3 } = useConnectWallet();
  const [Max, setMax] = useState("");

  const fetchMax = useCallback(async () => {
    const contract = new web3.eth.Contract(PINFT, poolAddress);

    const Max = await contract.methods
      .maxPerAddress()
      .call({ from: address });
    setMax(Max);
  }, [address, setMax, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchMax();

      let refreshInterval = setInterval(fetchMax, 10000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchMax]);
  return Max;
}

