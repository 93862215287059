import { VaultPage } from '.';


export default {
  path: "circle",
  childRoutes: [
    { path: "circle", component: VaultPage, isIndex: true },

  ],
};
