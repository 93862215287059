
export const stakingpools_erc = [

  { 
     chainId: 56,
     id: '1',
     name: 'PIP',
     token: 'PIP',
     tokenDescription: 'for PIP',
     tokenDescription2: 'Stake PIP',
     tokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
     tokenAddress2: '0xe5F25dbD74c5F945b183A3E573410a9860CE4099',
     tokenDecimals: 18,
     earnedTokenDec: 18,
     earnedToken: 'PIP',
     claimedTokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE', // c
     earnedTokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
     earnContractAddress: '0x09B4137bADFA9B65Da339eC54594645E2b285343',  // c
     pricePerFullShare: 1,
     timelock: "30",
     penalty: "10%",
     classV: "1",
     duration: "2023-06-01",
     startdate: "2023-01-02",
     status: 'aktive',
     typ: 'nft',
     multi: 5,
     boost: "1",
     APYC: 1,
     ended: "no"
   },

   { 
    chainId: 56,
    id: '6',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'for PIP',
    tokenDescription2: 'Stake PIP',
    tokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
    tokenAddress2: '0xe5F25dbD74c5F945b183A3E573410a9860CE4099',
    tokenDecimals: 18,
    earnedTokenDec: 18,
    earnedToken: 'PIP - Pool2',
    claimedTokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE', // c
    earnedTokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
    earnContractAddress: '0x00AeeDf8C104f2C5E7d4602DDeaC1B3bB570aed2',  // c
    pricePerFullShare: 1,
    timelock: "30",
    penalty: "10%",
    classV: "1",
    duration: "2022-07-12",
    startdate: "2022-04-12",
    status: 'aktive',
    typ: 'nft',
    boost: "1",
    multi: 5,
    APYC: 1,
    ended: "yes"
  },

/*   { 
    chainId: 56,
    id: '5',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'for PIP',
    tokenDescription2: 'Stake PIP',
    tokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
    tokenAddress2: '0xe5F25dbD74c5F945b183A3E573410a9860CE4099',
    tokenDecimals: 18,
    earnedTokenDec: 18,
    earnedToken: 'MAGIC',
    claimedTokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE', // c
    earnedTokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
    earnContractAddress: '0x3060212b8cD9ed67819a6e90f07d7a850159a013',  // c
    pricePerFullShare: 1,
    timelock: "30",
    penalty: "10%",
    classV: "1",
    duration: "2022-04-08",
    startdate: "2022-02-08",
    status: 'aktive',
    typ: 'nft',
    multi: 5,
    boost: "0",
    APYC: 1,
    ended: "yes"
  }, */


/*   { 
    chainId: 56,
    id: '2',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'for Greed',
    tokenDescription2: 'Stake PIP',
    tokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
    tokenAddress2: '0xe5F25dbD74c5F945b183A3E573410a9860CE4099',
    tokenDecimals: 18,
    earnedToken: 'GREED I',
    earnedTokenDec: 9,
    claimedTokenAddress: '0x5905df3D03E29a22e22462D3257E6AC731E22C15', // c
    earnedTokenAddress: '0x5905df3D03E29a22e22462D3257E6AC731E22C15',  // c
    earnContractAddress: '0x72D1538c7EE58f8804aeF6850F04D3614fF0c1Ba',  // c
    pricePerFullShare: 1,
    timelock: "30",
    penalty: "10%",
    classV: "1",
    duration: "2022-01-26",
    startdate: "2021-11-26",
    status: 'aktive',
    typ: 'nft',
    multi: 5,
    boost: "0",
    APYC: 0,
    ended: "yes"
  }, */
/*   { 
    chainId: 56,
    id: '3',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'for Greed',
    tokenDescription2: 'Stake PIP',
    tokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
    tokenAddress2: '0xe5F25dbD74c5F945b183A3E573410a9860CE4099',
    tokenDecimals: 18,
    earnedToken: 'GREED II',
    earnedTokenDec: 9,
    claimedTokenAddress: '0x5905df3D03E29a22e22462D3257E6AC731E22C15', // c
    earnedTokenAddress: '0x5905df3D03E29a22e22462D3257E6AC731E22C15',  // c
    earnContractAddress: '0xE9b4c5A8Bba383e9AB7dfC918a06c805C5e20125',  // c
    pricePerFullShare: 1,
    timelock: "60",
    penalty: "10%",
    classV: "1",
    duration: "2022-04-26",
    startdate: "2022-1-26",
    status: 'aktive',
    typ: 'nft',
    multi: 5,
    boost: "0",
    APYC: 1,
    ended: "yes"
  }, */
/*   { 
    chainId: 56,
    id: '4',
    name: 'PIP',
    token: 'PIP',
    tokenDescription: 'for Greed',
    tokenDescription2: 'Stake PIP',
    tokenAddress: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // c
    tokenAddress2: '0xe5F25dbD74c5F945b183A3E573410a9860CE4099',
    tokenDecimals: 18,
    earnedToken: 'GREED III',
    earnedTokenDec: 9,
    claimedTokenAddress: '0x5905df3D03E29a22e22462D3257E6AC731E22C15', // c
    earnedTokenAddress: '0x5905df3D03E29a22e22462D3257E6AC731E22C15',  // c
    earnContractAddress: '0xfeB6735CF638ff750B741f57331237916Aa77502',  // c
    pricePerFullShare: 1,
    timelock: "30",
    penalty: "10%",
    classV: "1",
    duration: "2022-04-08",
    startdate: "2021-02-08",
    status: 'aktive',
    typ: 'nft',
    multi: 5,
    boost: "0",
    APYC: 1,
    ended: "yes"
  }, */


  //////////////////////////  ETH //////////////////////////////////////

    { 
     chainId: 1,
     id: '1',
     name: 'PIP',
     token: 'PIP',
     tokenDescription: 'for PIP',
     tokenDescription2: 'Stake PIP',
     tokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
     tokenAddress2: '0x2b5537F902Ad180d02A2c256b09BA45Ee22C3579', // booster
     tokenDecimals: 18,
     earnedTokenDec: 18,
     earnedToken: 'PIP',
     claimedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707', // c
     earnedTokenAddress: '0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707',  // c
     earnContractAddress: '0xEd8E31c180c1772CfE43ceaD8845244Ec02Bfa46',  // c
     pricePerFullShare: 1,
     timelock: "30",
     penalty: "10%",
     classV: "1",
     duration: "2023-06-06",
     startdate: "2022-01-06",
     status: 'aktive',
     typ: 'nft',
     multi: 1,
     boost: "1",
     APYC: 1,
     ended: "no"
   },

 ]
 