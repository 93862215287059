import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import classNames from "classnames";
import { useConnectWallet } from "../../home/redux/hooks";
import {
  useFetchBalances,
  useCheckApproval,
  useFetchApproval,
  useFetchZapOrSwap,
  useFetchPricePerFullShare,
} from "../redux/hooks";

import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import CustomButtons from "components/CustomButtons/Button.js";
import Avatar from "@material-ui/core/Avatar";
import { byDecimals, calculateReallyNum } from "features/helpers/bignumber";
import { inputLimitPass, inputFinalVal } from "features/helpers/utils";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Slider from "@material-ui/core/Slider";
import { Grid, Box, Typography, Button } from "@material-ui/core";
import zapCommandStyle from "../jss/sections/zapCommandStyle";
import emailjs from "emailjs-com";

const useStyles2 = makeStyles(zapCommandStyle);

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];

function valuetext(value) {
  return `${value}%`;
}

export default function ZapCommandEth() {
  const classes2 = useStyles2();
  const { web3, address } = useConnectWallet();
  const { tokens, fetchBalances } = useFetchBalances();
  const { allowance, checkApproval } = useCheckApproval();
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchZapOrSwap } = useFetchZapOrSwap();
  const [isApproval, setIsApproval] = useState(1);
  const [showIndex, setShowIndex] = useState(0);
  const [subInfo, setSubInfo] = useState({});
  const { fetchPricePerFullShare, pricePerFullShare } =
    useFetchPricePerFullShare();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [quant, setQuant] = useState(0);

  const serviceId = "service_tbtmwor";
  const templateId = "template_qebch9o";
  const userId = "JyHWQz5XvZsSswxW5";

  const sendEmail = async () => {
    const numtosend = sendJson.num;
    if (quant >= 1) {
      try {
        const response = await emailjs.send(
          serviceId,
          templateId,
          { quant, address, numtosend },
          userId
        );

        if (response.status === 200) {
          console.log("Successfully sent message.");
        }
      } catch (error) {
        console.error("Failed to send email. Error: ", error);
      }
    }
    console.log("no ref given.");
  };
  // eslint-disable-next-line
  useEffect(() => {
    if (address && web3) {
      fetchBalances();
      checkApproval();
      if (subInfo.contract) {
        fetchApproval();
      }
      const id = setInterval(() => {
        if (subInfo.contract) {
          fetchApproval();
        }
        fetchBalances();
        
      }, 5000);
      return () => clearInterval(id);
    }
    // eslint-disable-next-line
  }, [address, web3, fetchBalances, fetchApproval]);

  useEffect(() => {
    if (!Boolean(showIndex && subInfo.contract)) return;
    const item = allowance.filter((item) => {
      return item.name === tokens[showIndex].name;
    })[0];
    const pool = item.pools.filter((item) => {
      return item.name === subInfo.contract.name;
    })[0];
    setIsApproval(!Boolean(pool.allowance === 0));
  }, [tokens, showIndex, address, subInfo.contract, allowance]);

  const onFetchApproval = () => {
    fetchApproval(tokens[showIndex].name, subInfo.contract.name);
  };

  const onFetchPricePerFullShare = () => {
    fetchPricePerFullShare(
      tokens[showIndex].name,
      subInfo.name,
      new BigNumber(sendJson.num)
        .multipliedBy(
          new BigNumber(10).exponentiatedBy(tokens[showIndex].decimals)
        )
        .toString(10)
    );
  };

  const onFetchZapOrSwap = async () => {
    setIsLoading(true);
    setSuccess(false);
    try {
      await fetchZapOrSwap(
        tokens[showIndex].name,
        subInfo.name,
        new BigNumber(sendJson.num)
          .multipliedBy(
            new BigNumber(10).exponentiatedBy(tokens[showIndex].decimals)
          )
          .toString(10)
      );
      setSuccess(true);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const handleMainDropdownClick = (event) => {
    setShowIndex(event.key);
    setSendJson({ num: 0, slider: 0 });

    if (
      !tokens[event.key].receivableList.find((item) => {
        return item.name === subInfo.name;
      })
    ) {
      setSubInfo({});
    }
  };
  let mainDropdownList = [];
  tokens.map((item, index) => {
    mainDropdownList.push(
      <div className={classes2.memuStyle} key={index}>
        <Avatar
          alt={item.name}
          src={require(`../../../images/${item.name}-logo.png`)}
          className={classNames({
            [classes2.marginRight]: true,
            [classes2.avatar]: true,
          })}
        />
        <span className={classes2.avatarFont}>{item.name}</span>
      </div>
    );
    return true;
  });

  const handleSubDropdownClick = (event) => {
    setSuccess(false);
    let targetInfo = tokens[showIndex].receivableList.find((item) => {
      return item.name === event.key;
    });
    if (targetInfo) {
      setSubInfo(targetInfo);
      //checkApproval();
    } else {
      //checkApproval();
      setSubInfo({});
    }
  };

  const singleSubDropDownNode = (item) => {
    return (
      <div className={classes2.subMemuStyle} key={item.name}>
        <Avatar
          alt={item.name}
          src={require(`../../../images/${item.name}-logo.png`)}
          className={classNames({
            [classes2.marginRight]: true,
            [classes2.avatar]: true,
          })}
        />
        <span className={classes2.avatarFont}>{item.name}</span>
      </div>
    );
  };
  let subDropdownList = [];
  // eslint-disable-next-line
  tokens[showIndex].receivableList.map((item) => {
    subDropdownList.push(singleSubDropDownNode(item));
  });

  const [sendJson, setSendJson] = useState({ num: 0, slider: 0 });
  const changeMainInput = (total, tokenDecimals, event) => {
    event.stopPropagation();
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    let sliderNum = 0;
    let inputVal = Number(value.replace(",", ""));
    if (value) {
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }

    setSendJson({
      num: inputFinalVal(value, total, tokenDecimals),
      slider: sliderNum,
    });
  };

  const changeSliderVal = (total, event, sliderNum) => {
    event.stopPropagation();
    setSendJson({
      num: sliderNum === 0 ? 0 : calculateReallyNum(total, sliderNum),
      slider: sliderNum,
    });
  };

  let buttonTxt = "choose a pair!";
  if (subInfo.type) {
    buttonTxt = subInfo.type;
  }
  let balanceTotal = byDecimals(
    tokens[showIndex].balance,
    tokens[showIndex].decimals
  );

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <>
      {" "}
      <Button onClick={toggleClass} className="guid_button_prnt">
        <img src="/img/guid_button.svg" alt="" className="guid_button" />
      </Button>
      <Box className="brdg_main_bx brdg_main_bx_ash footer_spaceing">
        <Box className="cstmcntnr">
          <Box className="sssss">
            <Box className="brdg_innr_main_bx">
              <Box
                component="img"
                src="/images/bridge_bx_top.png"
                className="bridge_bx_top"
              />
              <Box className="center_bridge_bg_box" />
              <Box
                component="img"
                src="/images/bridge_bottom.png"
                className="bridge_bottom"
              />
              <Box className="z_index_01">
                <Typography className="bridge_txt">PI DEX</Typography>
                <Box className="blnce_bx">
                  <Box
                    component="img"
                    src="/static/icons/pocket_ic.svg"
                    alt=""
                  />
                  <Typography component="h6">
                    Balance:
                    <span>
                      {" "}
                      {balanceTotal.toFormat(4)} {tokens[showIndex].name}
                    </span>
                  </Typography>
                </Box>
                <Box className="yllw_brdr_bx">
                  <FormControl fullWidth>
                    <Box className="">
                      <Box className="top_btn_bx slsctbx_v2 slsctbx_nv">
                        <CustomDropdown
                          navDropdown
                          hoverColor="primary"
                          darkModal
                          buttonText={
                            <div className={classes2.memuStyle}>
                              <Box
                                component="img"
                                className="arrw_img"
                                src={require(`../../../images/${tokens[showIndex].name}-logo.png`)}
                                alt={tokens[showIndex].name}
                              />
                              {tokens[showIndex].name}

                              <span className={classes2.avatarFont}></span>
                            </div>
                          }
                          buttonProps={{
                            className: classes2.navLink,
                            color: "transparent",
                          }}
                          onClick={handleMainDropdownClick}
                          dropdownList={mainDropdownList}
                        />
                      </Box>
                    </Box>
                    <CustomOutlinedInput
                      className="swap_input_prnt"
                      value={sendJson.num}
                      onChange={changeMainInput.bind(
                        this,
                        balanceTotal.toNumber(),
                        tokens[showIndex].decimals
                      )}
                      endAdornment={
                        <div className={classes2.endAdornment}>
                          <div className={classes2.maxButtonBox}>
                            <CustomButtons
                              onClick={() => {
                                setSendJson({
                                  num: balanceTotal.toFixed(4),
                                  slider: 100,
                                });
                              }}
                              className={classes2.maxButton}
                            >
                              {"Max"}
                            </CustomButtons>
                          </div>
                        </div>
                      }
                    />
                    {subInfo.name === "SCIRCLE" ||
                    subInfo.name === "SCIRCLE " ? (
                      <></>
                    ) : (
                      <Box className="slider_box">
                        <Box className="sldrspc">
                          <Slider
                            value={sendJson.slider}
                            onChange={changeSliderVal.bind(
                              this,
                              balanceTotal.toNumber()
                            )}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider-always"
                            step={1}
                            valueLabelDisplay="on"
                            marks={marks}
                          />
                        </Box>
                      </Box>
                    )}
                  </FormControl>

                  <Box className="sldr_btm">
                    <Grid
                      container
                      spacing={1}
                      className="justify-content-center"
                    >
                      <Grid item xs={12} lg={12}>
                        <Typography
                          component="h3"
                          className="def_ttl_sm text-center mb-4"
                        >
                          <span className="c_primary">Receive</span>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} lg={12} className="text-center">
                        <Box className="top_btn_bx">
                          <FormControl fullWidth>
                            <CustomDropdown
                              popperClassName={classes2.papperNav}
                              navDropdown
                              hoverColor="primary"
                              darkModal
                              buttonText={
                                subInfo.name &&
                                tokens[showIndex].receivableList.find(
                                  (item) => {
                                    return item.name === subInfo.name;
                                  }
                                ) &&
                                singleSubDropDownNode(subInfo)
                              }
                              buttonProps={{
                                className: classes2.receiveStyle,
                              }}
                              onClick={handleSubDropdownClick}
                              dropdownList={subDropdownList}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <div className={classes2.boxContainer}>
                        <div className={classes2.boxHeader}>
                          <div className={classes2.boxHeaderSub}>
                            Auto Slippage (max 10%) | get estimate*
                          </div>
                        </div>

                        <Box className="btn_outrborder_sdw">
                          <button
                            type="button"
                            className="btn btn_def height_auto"
                            onClick={() => {
                              onFetchPricePerFullShare();
                            }}
                            disabled={
                              !Boolean(
                                tokens[showIndex].name && subInfo.contract
                              ) ||
                              subInfo.contract === undefined ||
                              sendJson.num === 0
                            }
                          >
                            {" "}
                            <div className={classes2.boxHeaderMain}>
                              Estimate {/*  ETH <-> PETH */}
                              {(subInfo.name === "ETH  " &&
                                tokens[showIndex].name === "PETH") ||
                              (subInfo.name === "PETH" &&
                                tokens[showIndex].name === "ETH")
                                ? sendJson.num
                                : ""}
                              {/* // ETH <-> PIP */}
                              {(subInfo.name === "PIP" &&
                                tokens[showIndex].name === "ETH") ||
                              (subInfo.name === "ETH" &&
                                tokens[showIndex].name === "PIP")
                                ? (sendJson.num / sendJson.num) *
                                  pricePerFullShare
                                : ""}
                              {/* // AXMATC <-> DMGAIC */}
                              {(subInfo.name === "PIP " &&
                                tokens[showIndex].name === "PETH") ||
                              (subInfo.name === "PETH " &&
                                tokens[showIndex].name === "PIP")
                                ? (sendJson.num / sendJson.num) *
                                  pricePerFullShare
                                : ""}
                              {/* // LP <-> SCIRCLE */}
                              {subInfo.name === "SCIRCLE   " &&
                              tokens[showIndex].name === "PETH-PIP-LP"
                                ? sendJson.num / 2
                                : ""}
                              {/* // PIP <-> SCIRCLE */}
                              {subInfo.name === "SCIRCLE " &&
                              tokens[showIndex].name === "PIP"
                                ? (pricePerFullShare / 100) * 95 / 2
                                : ""}
                              {/* // ETH <-> SCIRCLE */}
                              {(subInfo.name === "SCIRCLE" &&
                                tokens[showIndex].name === "ETH") ||
                              (subInfo.name === "SCIRCLE  " &&
                                tokens[showIndex].name === "PETH")
                                ? (pricePerFullShare / 100) * 100 /2
                                : " "}
                              {subInfo.name === "ECIRCLE" &&
                              tokens[showIndex].name === "SCIRCLE"
                                ? "5 sCircle = 1 eCircle !!! "
                                : " "}
                            </div>
                          </button>
                        </Box>
                      </div>
                      <Box className="ammnt_bx2">
                        <Box className="">
                          <form className="saleForm ">
                            <Typography className="text-center">
                              Referal ID:
                            </Typography>
                            <input
                              className="text-white"
                              type="number"
                              value={quant}
                              onChange={(e) => setQuant(e.target.value)}
                              required
                            />
                          </form>
                        </Box>
                      </Box>
                      <Box className="btn_and_dsgn_bx">
                        <Box
                          component="img"
                          src="/static/img/btn_lft_img_l.svg"
                          className="ar_lft"
                        />
                        <Box className="btn_bx">
                          {tokens[showIndex].name === "SCIRCLE" ? (
                            <>
                              <Button
                                type="button"
                                className="btn btn_def"
                                onClick={() => {
                                  onFetchApproval();
                                }}
                                disabled={
                                  (!Boolean(
                                    tokens[showIndex].name && subInfo.contract
                                  ) &&
                                    fetchApprovalPending) ||
                                  subInfo.contract === undefined
                                }
                              >
                                {" "}
                                1.{" "}
                                {isApproval && success
                                  ? "Success"
                                  : "approve"}{" "}
                                {isLoading && <div className="asloader"></div>}
                              </Button>{" "}
                              <Button
                                type="button"
                                className="btn btn_def"
                                onClick={() => {
                                  onFetchZapOrSwap();
                                }}
                                disabled={
                                  (!Boolean(
                                    tokens[showIndex].name && subInfo.contract
                                  ) &&
                                    fetchApprovalPending) ||
                                  subInfo.contract === undefined
                                }
                              >
                                2. {buttonTxt}
                              </Button>
                            </>
                          ) : (
                            <>
                              {isApproval ? (
                                <Button
                                  type="button"
                                  className="btn btn_def"
                                  onClick={() => {
                                    onFetchZapOrSwap() && sendEmail();
                                  }}
                                  disabled={
                                    (!Boolean(
                                      tokens[showIndex].name && subInfo.contract
                                    ) &&
                                      fetchApprovalPending) ||
                                    subInfo.contract === undefined
                                  }
                                >
                                  {isApproval && success
                                    ? "Success"
                                    : buttonTxt}{" "}
                                  {isLoading && (
                                    <div className="asloader"> ping...</div>
                                  )}
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btn btn_def"
                                  onClick={() => {
                                    onFetchApproval();
                                  }}
                                  disabled={
                                    (!Boolean(
                                      tokens[showIndex].name && subInfo.contract
                                    ) &&
                                      fetchApprovalPending) ||
                                    subInfo.contract === undefined
                                  }
                                >
                                  {isApproval && success
                                    ? "Success"
                                    : "approve"}{" "}
                                  {isLoading && (
                                    <div className="asloader"> ...</div>
                                  )}
                                </Button>
                              )}
                            </>
                          )}
                        </Box>
                        <Box
                          component="img"
                          src="/static/img/btn_rgt_img_l.svg"
                          className="ar_rgt"
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          className={
            isActive
              ? "user_modal user_modal200 show"
              : "user_modal user_modal200"
          }
        >
          <Box
            component="img"
            src="/img/top_guid.png"
            className="guid_top_img"
          />
          <DialogContent>
            <Box className="ur_mdl_ttl">
              <Box className="ur_mdl_ttl_inn">
                <Typography component="h4">Pi DEX user guide</Typography>
              </Box>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={toggleClass}
              >
                <Box component="img" src="/img/back_icon.svg" />
              </IconButton>
            </Box>
            <Box className="user_m_cntent">
              <Typography>
                1) Connect your wallet (make sure{" "}
                <span className="clr_orange">BSC, ETH or AVAX Network</span> is selected).
              </Typography>
              <Typography>
                2) Use the drop down menu to select a token or Pi Circle NFT you
                would like to spend.{" "}
              </Typography>
              <Typography>
                3) Use the second drop down menu under the word{" "}
                <span className="clr_yellow">RECEIVE</span> to select the token
                or Pi Circle NFT you would like to receive.
              </Typography>
              <Typography>
                4) Drag amount slider or type in amount you want to spend.
              </Typography>
              <Typography>
                5) Press <span className="clr_yellow">ESTIMATE</span> button to
                get estimate of what you will receive when making the swap.
              </Typography>
              <Typography>
                6a) If the large button under{" "}
                <span className="clr_yellow">ESTIMATE</span> says{" "}
                <span className="clr_yellow">APPROVE</span>, press button to
                allow contract wallet interaction.
              </Typography>
              <Typography>
                6b) Confirm approval in wallet (wait for confirmation).{" "}
              </Typography>
              <Typography>
                7) If the large button under{" "}
                <span className="clr_yellow">ESTIMATE</span> says{" "}
                <span className="clr_yellow">ZAP</span> or{" "}
                <span className="clr_yellow">SWAP</span>, press button to make
                the trade.{" "}
              </Typography>
              <Typography>
                8) Confirm <span className="clr_yellow">SWAP</span> or{" "}
                <span className="clr_yellow">ZAP</span> in wallet (wait for
                confirmation).
              </Typography>
              <Typography>
                9) Congratulations you successfully used the Pi Swap.
              </Typography>
            </Box>
          </DialogContent>
          <Box className="pop_mddl_bg" />
          <Box
            component="img"
            src="/img/popup_botm_img.svg"
            className="w-100 pb-1 ppbtm_img"
          />
          <Box
            component="img"
            src="/img/bottom_guid.png"
            className="guid_bottom_img"
          />
        </Box>
      </Box>
    </>
  );
}
