export const stakingpools = [
  {
    chainId: 56,
    id: "1",
    name: "BCIRCLE",
    token: "BCIRCLE",
    tokenDescription: "2% TX fee - infinite Pool!",
    tokenDescription2: "Refill, 1st of Month!",
    tokenAddress: "0x796263Bb5706fE09bCb44514E1525602FcEbEA31", // c
    tokenId: 2,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnContractAddress: "0xEdD27916145956fA18781CAb7126cE44110691D3", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "infinite",
    startdate: "2021-11-26",
    status: "aktive",
    typ: "nft",
    multi: 190,
    APYC: 5,
    ended: "no",
    pcs2: "0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f",
  },

  {
    chainId: 56,
    id: "2",
    name: "SCIRCLE",
    token: "SCIRCLE",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0x796263Bb5706fE09bCb44514E1525602FcEbEA31", // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnContractAddress: "0x3B90360D0d56ADD2ff303b23eCE395CC18EA9aB3", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 days",
    startdate: "2022-11-16",
    status: "aktive",
    typ: "nft",
    multi: 38,
    APYC: 1,
    ended: "no",
    pcs2: "0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f",
  },

  {
    chainId: 56,
    id: "3",
    name: "BCIRCLE",
    token: "BCIRCLE",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0x796263Bb5706fE09bCb44514E1525602FcEbEA31", // c
    tokenId: 2,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnContractAddress: "0x12fcba9B09A69b40aB87d67780B7aDd4DE41Eb8E", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "60 Days",
    startdate: "2022-02-22 10",
    status: "aktive",
    typ: "nft",
    multi: 190,
    APYC: 5,
    ended: "yes",
    pcs2: "0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f",
  },

/*   {
    chainId: 56,
    id: "4",
    name: "BCIRCLE",
    token: "PIP",
    tokenDescription: "Team Token",
    tokenDescription2: "Vesting Contract",
    tokenAddress: "0xa129984E32B4d23d8443419D1Ac40f1901F3D725", // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnedTokenAddress: "0x25C30340e6f9f6e521827cF03282943dA00c0ECE", // c
    earnContractAddress: "0x40664660C695F867DeC4f18A2cc6DBAE2745a790", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 Days",
    startdate: "2022-01-26",
    status: "aktive",
    typ: "nft",
    multi: 0,
    APYC: 0,
    ended: "no",
    pcs2: "0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f",
  }, */
  /////////////////////////////// ETH  //////////////////////////////

  {
    chainId: 1,
    id: "1",
    name: "ECIRCLE",
    token: "ECIRCLE",
    tokenDescription: "2% TX fee - infinite Pool!",
    tokenDescription2: "Refill, 1st of Month!",
    tokenAddress: "0xcF5DbC399F3112bac87f57018A3389911241237B", // c
    tokenId: 2,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707", // c
    earnedTokenAddress: "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707", // c
    earnContractAddress: "0x08b1E0662Df8bCa4D92303EF549020338E67B930", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "infinite",
    startdate: "2022-08-04",
    status: "aktive",
    typ: "nft",
    multi: 800,
    APYC: 10,
    ended: "no",
    pcs2: "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9",
    LPP: "80"
  },

  {
    chainId: 1,
    id: "2",
    name: "SCIRCLE",
    token: "SCIRCLE",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0xcF5DbC399F3112bac87f57018A3389911241237B", // c
    tokenId: 1,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707", // c
    earnedTokenAddress: "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707", // c
    earnContractAddress: "0xD6A0429867C5C34F7cB6E70A6cC0dB5DefCCF97C", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "180 days",
    startdate: "2023-01-06",
    status: "aktive",
    typ: "nft",
    multi: 160,
    APYC: 2,
    ended: "no",
    pcs2: "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9",
    LPP: "80"
  },

  /*   {
    chainId: 1,
    id: "3",
    name: "ECIRCLE",
    token: "ECIRCLE",
    tokenDescription: "",
    tokenDescription2: "",
    tokenAddress: "0xcF5DbC399F3112bac87f57018A3389911241237B", // c
    tokenId: 2,
    tokenDecimals: 0,
    earnedToken: "PIP",
    claimedTokenAddress: "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707", // c
    earnedTokenAddress: "0x37613D64258c0FE09d5E53EeCb091DA5b8fA8707", // c
    earnContractAddress: "0x283b6e2f313D5058d1eDB901f36fD192F7b58d60", // c
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "1",
    duration: "60 Days",
    startdate: "2022-02-22 10",
    status: "aktive",
    typ: "nft",
    multi: 190,
    APYC: 5,
    ended: "yes",
    pcs2: "0xD849b2Af570ffa3033973EA11be6E01b7ba661D9"
  }, */
];
