import React from "react";
import { Box } from "@material-ui/core";
import Lottie from 'react-lottie'
import banner from '../../../assets/img/PiProtocol1.json'

function FirstSection() {
  const top = {
    loop: true,
    autoPlay: true,
    animationData: banner,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
};
  return (
    <>
      <Box className="defpddng defpddng_v2_as for_anmtn">
        <Lottie options={top} width={450} height={450} className="aaaaa"></Lottie>
       
      </Box>
    </>
  );
}

export default FirstSection;
