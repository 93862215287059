import { phoenixpools } from "../../configure";

const tokens = {
  56: {},
  1: {}
};

phoenixpools.map(
  ({
    chainId,
    token,
    tokenAddress,
    earnedToken,
    claimedTokenAddress,
    tokenId,
  }) => {
    tokens[chainId][token] = {
      tokenAddress: tokenAddress,
      tokenBalance: 0,
    };
    tokens[chainId][earnedToken] = {
      tokenAddress: claimedTokenAddress,
      tokenBalance: 0,
    };
    tokens[chainId][tokenId] = {
      tokenId: tokenId,
    };
    return "";
  }
);

const initialState = {
  phoenixpools,
  tokens,
  fetchPoolBalancesPending: false,
};

export default initialState;
