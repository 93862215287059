import { erc20ABI, dmagicStakingVaults, nft1 } from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { ethers } from "ethers";
import { useConnectWallet } from "../../home/redux/hooks";
export { useFetchPoolBalances } from "./fetchPoolBalances";

export function useApprove(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise((resolve, reject) => {
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

        contract.methods
          .approve(poolAddress, ethers.constants.MaxUint256.toString(10))
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

  return { isPending, onApprove: handleApprove };
}

export function useAllowance(tokenAddress, spender) {
  const { web3, address } = useConnectWallet();
  const [allowance, setAllowance] = useState("0");

  const fetchAllowance = useCallback(async () => {
    if (tokenAddress === "") {
      setAllowance(ethers.constants.MaxUint256.toString(10));
      return;
    }
    const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

    const allowance = await contract.methods.allowance(address, spender).call();
    setAllowance(allowance);
  }, [address, spender, setAllowance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAllowance();

      let refreshInterval = setInterval(fetchAllowance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAllowance]);

  return allowance;
}

export function useApprove1(tokenAddress2, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove1 = useCallback(
    async (owner11) => {
      setIsPending(true);
      try {
        await new Promise((resolve, reject) => {
          const contract = new web3.eth.Contract(nft1, tokenAddress2);
          contract.methods
            .approve(poolAddress, owner11)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress, tokenAddress2]
  );

  return { isPending, onApprove1: handleApprove1 };
}

export function useBalanceOf(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

// dmagicStakingVaults
export function useEarned(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(dmagicStakingVaults, poolAddress);

    const earned = await contract.methods
      .earnedtokenRewardToken1(address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 15000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}

export function useEarnedB(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [earnedB, setEarnedB] = useState("0");

  const fetchEarnedB = useCallback(async () => {
    const contract = new web3.eth.Contract(dmagicStakingVaults, poolAddress);

    const earnedB = await contract.methods
      .totalEarnedRewardToken1(address)
      .call({ from: address });
    setEarnedB(earnedB);
  }, [address, setEarnedB, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarnedB();

      let refreshInterval = setInterval(fetchEarnedB, 15000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarnedB]);

  return earnedB;
}

export function useTime1(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [time1, setTime1] = useState("");

  const fetchTime1 = useCallback(async () => {
    const contract = new web3.eth.Contract(dmagicStakingVaults, poolAddress);

    const time1 = await contract.methods
      .lockingPeriodStaking(address)
      .call({ from: address });
    setTime1(time1);
  }, [address, setTime1, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchTime1();
    }
  }, [web3, address, fetchTime1]);

  console.log();

  return time1;
}

export function useDmagicAPY(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [APY2, setAPY2] = useState("0");

  const fetchAPY2 = useCallback(async () => {
    const contract = new web3.eth.Contract(dmagicStakingVaults, poolAddress);

    const APY2 = await contract.methods
      .getRewardToken1APY()
      .call({ from: address });
    setAPY2(APY2);
  }, [address, setAPY2, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAPY2();
    }
  }, [web3, address, fetchAPY2]);

  return APY2;
}

export function useDmagicWPY(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [Apy, setApy] = useState("0");

  const fetchApy = useCallback(async () => {
    const contract = new web3.eth.Contract(dmagicStakingVaults, poolAddress);

    const Apy = await contract.methods
      .getRewardToken1WPY()
      .call({ from: address });
    setApy(Apy);
  }, [address, setApy, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchApy();
    }
  }, [web3, address, fetchApy]);

  return Apy;
}

// dmagicStakingVaults
export function useDeposit(poolAddress, tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(
            dmagicStakingVaults,
            poolAddress
          );
          contract.methods
            .stake(amount)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit: handleDeposit };
}

export function useBoost1(poolAddress, tokenAddress2) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleBoost1 = useCallback(
    async (owner11) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(
            dmagicStakingVaults,
            poolAddress
          );
          contract.methods
            .boostByNFT(tokenAddress2, owner11)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress, tokenAddress2]
  );

  return { isPending, onBoost1: handleBoost1 };
}

export function useWithdraw(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleWithdraw = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(
            dmagicStakingVaults,
            poolAddress
          );
          contract.methods
            .withdraw(amount)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onWithdraw: handleWithdraw };
}

export function useDuration(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [Duration, setDuration] = useState("0");

  const fetchDuration = useCallback(async () => {
    const contract = new web3.eth.Contract(dmagicStakingVaults, poolAddress);

    const Duration = await contract.methods
      .totalSupply()
      .call({ from: address });
    setDuration(Duration);
  }, [address, setDuration, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchDuration();
    }
  }, [web3, address, fetchDuration]);

  return Duration;
}

export function useFetchGetReward(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleGetReward = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise(async (resolve, reject) => {
        const contract = new web3.eth.Contract(
          dmagicStakingVaults,
          poolAddress
        );
        contract.methods
          .getReward()
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress]);

  return { isPending, onGetReward: handleGetReward };
}