import { App } from "../features/home";
import { PageNotFound } from "../features/common";
import homeRoute from "../features/home/route";
import commonRoute from "../features/common/route";
//import gameRoute from "../features/game/route";
import dexRoute from "../features/dex/route";
import stakingRoute from "../features/staking/route";
//import nftvaultsRoute from "../features/nftvaults/route";
//import nftvaults2Route from "../features/nftvaults2/route";
//import nftvaults3Route from "../features/nftvaults3/route";
import circleRoute from "../features/circle/route";
import staking_ercRoute from "../features/staking_erc/route";
import staking_721Route from "../features/staking_721/route";
import stakingPRoute from "../features/stakingP/route"
import faqRoute from "../features/faq/route";
//import bridgeRoute from "../features/bridge/route";
//import bridgetransactionhistoryRoute from "../features/bridge_transaction_history/route";
import metaverseRoute from "../features/metaverse/route";
import phoenixRoute from "../features/phoenix/route";
import breed from "../features/breed/route";
import countdownRoute from "../features/countdown/route";
import badge_stake from "../features/badge_stake/route"
import launchpadRoute from "../features/launchpad/route";
//import mythRoute from "../features/myth/route";
//import presaleRoute from "../features/presale/route";
import lootboxRoute from "../features/lootbox/route";
import pipxRoute from "../features/pipx/route";
import pipsRoute from "../features/pips/route";
import demigodsRoute from "../features/demigods/route";

import _ from "lodash";

const childRoutes = [
  homeRoute,
  commonRoute,
  //gameRoute,
  dexRoute,
  stakingRoute,
  //nftvaultsRoute,
  //nftvaults2Route,
  //nftvaults3Route,
  circleRoute,
  staking_ercRoute,
  staking_721Route,
  stakingPRoute,
  breed,
  faqRoute,
  badge_stake,
  //bridgeRoute,
  //bridgetransactionhistoryRoute,
  metaverseRoute,
  launchpadRoute,
  //mythRoute,
  //presaleRoute,
  countdownRoute,
  lootboxRoute,
  phoenixRoute,
  pipxRoute,
  pipsRoute,
  demigodsRoute
];

const routes = [
  {
    path: "/",
    component: App,
    childRoutes: [
      ...childRoutes,
      { path: "*", name: "Page not found", component: PageNotFound },
    ].filter((r) => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child) => child.isIndex);
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = "";
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
