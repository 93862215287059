
export const demigodpools = [
  { 
    chainId: 56,
    name: 'sCircle',
    id: 'scircle',
    tokenAddress: '0x796263Bb5706fE09bCb44514E1525602FcEbEA31',   // scircle   
    tokenAddress2: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',  // pi
    earnContractAddress: '0x679F2f1C88CC0c2424AE3088Cb71b35CEAeb02F5', // wrapper
    earnContractAddress2: '0x98B07cD5Ca128E5b8a9D3a24f95dA8d551E25A46', // own contract
    tokenId: "1",
    price: "0.15 BNB",
    price2: "36 PI",
    pcs2: "0xf18Ea05686fb7F601E35775f0a14B6D0FcA8303f"  // lp
  },
]