
export const zapOrSwap = async ({web3, address, address2, contract, call, call2, amount, dispatch}) => {
  // console.log(`=====================================deposit begin=====================================`)

  const data = await _zapOrSwap({web3, address,address2, contract, call,call2, amount, dispatch});
  // console.log(`=====================================deposit success=====================================`)
  return data;
}

const _zapOrSwap = ({contract, amount, call,call2,address,address2, dispatch}) => {
  return new Promise((resolve, reject) => {

    const lptoken = '0xD849b2Af570ffa3033973EA11be6E01b7ba661D9'
    // console.log(isAll)
    if (call==='deposit') {
    contract.methods[call]().send({ from: address,  value:amount }).on('transactionHash', function(hash){
      console.log(hash)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='easySell3') {
    contract.methods[call](amount).send({ from: address }).on('transactionHash', function(hash){
      console.log(hash)
      console.log(contract)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='easySell4') {
    contract.methods[call](lptoken, amount).send({ from: address }).on('transactionHash', function(hash){
      console.log(hash)
      console.log(contract)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })


  } else if (call2==='depositTokens') {
    console.log('i a here', address2, amount, address, contract)

    contract.methods[call2](address2, amount).send({ from: address }).on('transactionHash', function(hash){
      console.log(hash)
      console.log(address2)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='easyBuy2') {
    contract.methods[call]().send({ from: address, value:amount}).on('transactionHash', function(hash){
      console.log(hash)
      console.log(contract)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })
  } else {

  contract.methods[call](amount).send({ from: address, value:amount }).on('transactionHash', function(hash){
    console.log(hash)

  })
  .on('receipt', function(receipt){
    resolve()
  })
  .on('error', function(error) {
    console.log(error)
    reject(error)
  })
  .catch((error) => {
    console.log(error)
    reject(error)
  })
   } })
}