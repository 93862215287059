import { erc721, staking, erc20ABI, lootAbi,erc721P, bll, stakingNew, claimABI } from "features/configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../common/redux/actions";
import { useConnectWallet } from "../../home/redux/hooks";
import axios from "axios";
export { useFetchPoolBalances } from "./fetchPoolBalances";



// OK
export function useBalanceOf(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

// OK
export function useBalanceNFTOf(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [balanceNFT, setBalanceNFT] = useState("0");

  const fetchBalanceNFT = useCallback(async () => {
    let balanceNFT;
    if (tokenAddress === "") {
      balanceNFT = await web3.eth.balanceOfNFT(address);
    } else {
      const contract = new web3.eth.Contract(erc721, tokenAddress);

      balanceNFT = await contract.methods.balanceOfNFT(address).call();
    }
    setBalanceNFT(balanceNFT);
  }, [address, setBalanceNFT, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalanceNFT();

      let refreshInterval = setInterval(fetchBalanceNFT, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalanceNFT]);

  return balanceNFT;
}

// ok
export function useEarned(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(erc721, poolAddress);

    const earned = await contract.methods
      .earned(address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 10000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}

//ok
export function useDmagicAPY(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [APY2, setAPY2] = useState("0");

  const fetchAPY2 = useCallback(async () => {
    const contract = new web3.eth.Contract(erc721, poolAddress);

    const APY2 = await contract.methods
      .getRewardToken1APY()
      .call({ from: address });
    setAPY2(APY2);
  }, [address, setAPY2, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchAPY2();
      let refreshInterval = setInterval(fetchAPY2, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchAPY2]);

  return APY2;
}

// ok
export function useDuration(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [Duration, setDuration] = useState("0");

  const fetchDuration = useCallback(async () => {
    const contract = new web3.eth.Contract(staking, poolAddress);

    const Duration = await contract.methods
      .totalSupply()
      .call({ from: address });
    setDuration(Duration);
  }, [address, setDuration, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchDuration();
      let refreshInterval = setInterval(fetchDuration, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchDuration]);

  return Duration;
}

// ok
export function useDeposit(poolAddress, stakingclass) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (tokenId) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(stakingNew, poolAddress);
          contract.methods
            .stake(stakingclass, tokenId)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [web3, poolAddress, stakingclass, address, dispatch]
  );

  return { isPending, onDeposit: handleDeposit };
}

//ok
export function useDepositP(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async (tokenId) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(erc721P, poolAddress);
          contract.methods
            .stake(tokenId)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDepositP: handleDeposit };
}

// OK
export function useWithdraw(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleWithdraw = useCallback(
    async (amount) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(erc721, poolAddress);

          contract.methods
            .exit()
            .send({ from: address })

            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onWithdraw: handleWithdraw };
}

// ok
export function useFetchGetReward(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleGetReward = useCallback(async () => {
    setIsPending(true);
    try {
      await new Promise(async (resolve, reject) => {
        const contract = new web3.eth.Contract(erc721, poolAddress);
        contract.methods
          .getReward()
          .send({ from: address })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve();
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress]);

  return { isPending, onGetReward: handleGetReward };
}

// ok
export function useApproveForAll(tokenAddress, poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleApprove2 = useCallback(
    async (owner) => {
      setIsPending(true);
      try {
        await new Promise((resolve, reject) => {
          const contract = new web3.eth.Contract(lootAbi, tokenAddress);

          contract.methods
            .setApprovalForAll(poolAddress, true)
            .send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]
  );

  return { isPending, onApprove2: handleApprove2 };
}

export const fetchTokens = async (
  from,
  count,
  type = "single",
  collections,
  category = null,
  sortBy = "id",
  filterBy,
  address,
  cancelToken
) => {
  const data = { from, count, type };
  if (collections.length > 0) {
    data.collectionAddresses = collections;
  }
  if (category !== null) {
    data.category = category;
  }
  if (address) {
    data.address = address;
  }
  if (filterBy.length) {
    data.filterby = filterBy;
  }
  data.sortby = sortBy;

  let apiUrl
  if ( collections[0] === "0x868eb23d0a07d3b8fa5fefd548c9cb340fd29ee1") {
  apiUrl = "https://api.pi-marketplace.io";
  } else 
  if ( collections[0] === "0xe651657fa2355261bbf4037cf6af43825af5da22") { 
  apiUrl = "https://api.pinftmarket.io";
  }

  const res = await axios({
    method: "post",
    url: `${apiUrl}/nftitems/fetchTokens`,
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken,
  });
  return res.data;
};

export function usePoints(poolAddress, tokenIds) {
  const { web3, address } = useConnectWallet();
  const [Duration, setDuration] = useState("0");

  const fetchDuration = useCallback(async () => {
    if (web3) {
    const contract = new web3.eth.Contract(bll, poolAddress);

    const Duration = await contract.methods
      .getPointsForTokenIDs(tokenIds)
      .call({ from: address });
    setDuration(Duration);
    }
  }, [web3, poolAddress, tokenIds, address]);

  useEffect(() => {
    if (web3 && address) {
      fetchDuration();
      let refreshInterval = setInterval(fetchDuration, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchDuration]);

  return Duration;
}

export function useBalanceOfS(tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(staking, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 3000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

// OK
export function useWithdrawSingle(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleWithdrawSinqle = useCallback(
    async (quant) => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(erc721P, poolAddress);

          contract.methods
            .withdraw([quant])
            .send({ from: address })

            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onWithdrawSingle: handleWithdrawSinqle };
}


// CLAIM
export function useDepositC(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(
    async () => {
      setIsPending(true);
      try {
        await new Promise(async (resolve, reject) => {
          const contract = new web3.eth.Contract(claimABI, poolAddress);
          contract.methods.claimRewards().send({ from: address })
            .on("transactionHash", function (hash) {
              dispatch(
                enqueueSnackbar({
                  message: hash,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success",
                  },
                  hash,
                })
              );
            })
            .on("receipt", function (receipt) {
              resolve();
            })
            .on("error", function (error) {
              console.log(error);
              reject(error);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } finally {
        setIsPending(false);
      }
    },
    [dispatch, setIsPending, web3, address, poolAddress]
  );

  return { isPending, onDeposit: handleDeposit };
}

export function useRewards(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(claimABI, poolAddress);

    const earned = await contract.methods
      .getRewardAmount(address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 10000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}

export function useTotalClaim(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(claimABI, poolAddress);

    const earned = await contract.methods
      .totalClaims()
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 10000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}
export function useTotalPending(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(claimABI, poolAddress);

    const earned = await contract.methods
      .totalPending()
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 10000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}
export function useUsers(poolAddress) {
  const { web3, address } = useConnectWallet();
  const [earned, setEarned] = useState("0");

  const fetchEarned = useCallback(async () => {
    const contract = new web3.eth.Contract(claimABI, poolAddress);

    const earned = await contract.methods
      .users(address)
      .call({ from: address });
    setEarned(earned);
  }, [address, setEarned, poolAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchEarned();

      let refreshInterval = setInterval(fetchEarned, 10000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchEarned]);

  return earned;
}


export const fetchPanthers = async () => {
  const apiUrl = 'https://api.pinftmarket.io'
  const res = await axios.get(`${apiUrl}/info/getnewPanther`);
  return res.data;
};