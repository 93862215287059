import NftTitle from "./sections/NftTitle";
import React, { useEffect } from "react";
import CircleBoxes from "./sections/CircleBoxes";
import { Grid, Box } from "@material-ui/core";
import { useConnectWallet } from "../home/redux/hooks";

export default function VaultPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const { networkId } = useConnectWallet();

  return (
    <>
      <Box className="nft_prnt footer_spaceing circle_page_bg">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <NftTitle />
          </Grid>

          <Grid item xs={12} className="mb-4">
            {networkId === 56 ? <CircleBoxes /> : <></>}
          </Grid>
          <Grid item xs={12} className="mb-4">
            {networkId === 1 ? <></> : <></>}
          </Grid>
{/*           <Grid item xs={12} className="mb-4">
            {networkId === 1 ? <></> : <></>}
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
}
